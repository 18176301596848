import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import style from './styles';
import logo from './assets/circle.png';
import {Box, Card, CardContent, Grid, InputLabel, } from '@mui/material';
import group from './assets/group.png';
import define from './strings';
import { useEffect, useRef, useState } from 'react';
import {Link } from 'react-router-dom';
import SCbuttons from './components/SocialMedia';
import {FormControl} from '@mui/material';
import {Input} from '@mui/material';
import {Select} from '@mui/material';
import {MenuItem} from '@mui/material';
import { motion } from "framer-motion"
import Loading from './components/Loading';
import SnackBar from './components/SnackBar';
import ModalC from './components/ModalComp';
import { useDispatch, useSelector } from 'react-redux';
import { createfUser } from './data/FirstReg';
import { setdone } from './data/FirstReg';
const Reg = () => {
  const [lang,setlang] = useState(define.Am)
  const [reg,setreg] = useState(false);
  const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
  const [load,setload] = useState(false);
  const [modal,setmodal] = useState({title:"Title",body:"Body",show:false,btn:"Button",loc:""});
  const dispatch = useDispatch();
  const server = useSelector((state)=>state.creatfuser)
  const pwd = useRef()
  const [firstName,setfn]  = useState("")
  const [middleName,setmn] = useState("")
  const [email,seteml] = useState("")
  const [password,setpwd] = useState("") 
  const [sex,setsx] = useState("")
  const [pwd2,setpwd2] = useState("")

  useEffect(()=>{

      if(server.status == "loading")
      setload(true)
      else if(server.status == "success")
      {
        setmodal({title:"ምዝገባው በትክክል ተካሂድዋል!",body:"እንኳን ደስ አለወ ምዝገባውን በትክክል አካሂደዋል! <br /><b><strong>የእርስዎ ንቁቁጥር፡ "+server.neku+" </strong></b> <div><strong>ንቁ ቁጥር</strong> ኢጵላሣጵ ጋር ለሚቀጥለው ግንኙነት የእርስዎ መለያ ቁጥር ሆኖ ያገለግላል።</div>",show:true,btn:"ቀጣይ",loc:"/login"})
        dispatch(setdone())
      }
      else if(server.status == "failed")
      {
        setsnack({Type:"error",msg:"የሆነ ችግር ተፈጥርዋል!",show:true}); 
        dispatch(setdone())

      }
      else 
      setload(false)


  },[server])


  const register = () => {

    if(firstName ==""|| middleName ==""|| email ==""|| password =="" || sex == "")
    {
      setsnack({Type:"error",msg:"ሁሉንም አስፈላጊ መረጃ በትክክል ይሙሉ!",show:true});
      
    }
    else if(password != pwd2)
    {
      setsnack({Type:"warning",msg:"ያስገቡት የይለፍ ቃል አይመሳሰልም!",show:true});
      
    }
  else 
  {
    // setload(true);
    // setTimeout(()=>{

    //   setmodal({title:"ምዝገባው በትክክል ተካሂድዋል!",body:"እንኳን ደስ አለወ ምዝገባውን በትክክል አካሂደዋል! <br /> የእርሶ ንቁቁጥር <b>8888</b> <br /> <div><strong>ንቁ ቁጥር</strong> ኢጵላሣጵ ጋር ለሚቀጥለው ግንኙነት የእርስዎ መለያ ቁጥር ሆኖ ያገለግላል።</div>",show:true,btn:"ቀጣይ",loc:"/welcome"})

    // },2000)
  
  
    dispatch(createfUser({
      firstName:firstName,
      middleName:middleName,
      email:email,
      password:password,
      sex,sex
    }));



  }
}


const handleChange = e => {
  setfn(e.target.value)
}



  return (<div style={style.main}>
{/* Loading component */}
<Loading load={load} />   
{/* Snack component */}
<SnackBar snack={snack} />
{/* Modal Component */}
<ModalC modal={modal} />
  {/* Application Bar */}
    <AppBar color='white' position="static">
      <Container maxWidth="xl">
        <Toolbar color='white' disableGutters>
            <img src={logo}  style={{width:"58px"}}/>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={style.logoText}          >
            {lang.logoText}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
<Container>

<Typography initial={{left:"-100%"}} transition={{ ease: "easeOut", duration: 3 }} animate={{left:""}} component={motion.div} variant='bodytext1' color='secondary' sx={style.title1}>
<div><strong>ኢጵላሣጵ</strong> ምርቶችን ለመጠቀም አድራሻዎን በትክክል መሙላት ይጠበቅበዎታል።</div>
   </Typography>


{/* Register card         */}
<Card sx={style.regCard} 
  initial={{top:"-100%"}} transition={{ ease: "easeOut", duration: 1 }} animate={{top:"20%"}} exit={{top:"15%"}}  component={motion.div}
>
     <CardContent>
        <Typography variant="h5"  component="div" color=''>
      {lang.suggest2}
      </Typography>
      <br />
      <div style={{color: '#666666'}}><strong>አስፈልጊ መረጃዎች</strong> የእርስዎ መረጃ አስፈላጊ ስለሆነ እባክዎ አድራሻዎን በትክክል ሞልተው ይመዝገቡ።</div>
      <br /><br />


<Grid container spacing={2}>
<Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
      <FormControl style={{width:"100%"}} variant="standard">
        <InputLabel htmlFor="component-simple">{lang.fname}</InputLabel>
        <Input onChange={e=>setfn(e.target.value)} id="firstName" value={firstName}   />
      </FormControl> 
   </Grid>
   <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
      <FormControl style={{width:"100%"}} variant="standard">
        <InputLabel htmlFor="component-simple">{lang.mname}</InputLabel>
        <Input onChange={e=>setmn(e.target.value)}  id="middleName" value={middleName}   />
      </FormControl> 
   </Grid>
   <Grid item lg={4} xl={4} md={12} sm={12} xs={12}>
   <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
   <InputLabel id="demo-select-small-label">ፆታ:</InputLabel>
        <Select
          inputProps={{ 'aria-label': 'Without label' }}
          label='ፆታ'
          defaultValue=""
          value={sex}
          id='sex'
          onChange={e=>setsx(e.target.value)} 
        >
          <MenuItem value="ሴት">ሴት</MenuItem>
          <MenuItem value="ወንድ">ወንድ</MenuItem>
       </Select>
      </FormControl>
   </Grid>
   <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
      <FormControl style={{width:"100%"}} variant="standard">
        <InputLabel htmlFor="component-simple">ኢሜል</InputLabel>
        <Input onChange={e=>seteml(e.target.value)}  value={email}   />
      </FormControl> 
   </Grid>
   <Grid item lg={4} xl={4} md={6} sm={12} xs={12}>
      <FormControl style={{width:"100%"}} variant="standard">
        <InputLabel htmlFor="component-simple">የይለፍ ቃል:</InputLabel>
        <Input type='password' onChange={e=>setpwd(e.target.value)}  id="password" value={password}   />
      </FormControl> 
   </Grid>
   <Grid item lg={4} xl={4} md={6} sm={12} xs={12}>
      <FormControl variant="standard"  style={{width:"100%"}}>
        <InputLabel htmlFor="component-simple">የይለፍ ቃል ማረጋገጫ:</InputLabel>
        <Input  onChange={e=>setpwd2(e.target.value)} value={pwd2} type='password' id="component-simple"  />
      </FormControl> 
   </Grid>
</Grid>








<br />
<Button onClick={register} sx={{fontSize: '17px',fontWeight: '700',}} size='large' variant='contained'>{lang.regBtn}</Button>
<br /><br />
<strong>ከዚህ በፊት ተመዝግበዋል?</strong>
<Typography sx={{ mb: 1.5,textAlign:"left", }} color="text.secondary">
       {lang.haveAcc}
       <Link to="/">
       <Button size="small" color='secondary' variant='contained' sx={{color:"#ffffff",fontSize: '17px',fontWeight: 'bold',height: '26px',marginLeft: '9px'}}>{lang.loginBtn}</Button>
       </Link>
        </Typography> 

      </CardContent>
      <br />
    </Card>















<Box   initial={{bottom:"-100%"}} transition={{ ease: "easeOut", duration: 0.5 }} animate={{bottom:"0%"}}  component={motion.img}    sx={{width: {xs:'13rem',xl:'25rem',lg:'25rem'},position: 'fixed',bottom: '0',left: '0',zIndex:{ xl:'-14',lg:'-14',md:'-14',sm:'-1',xs:'-1'}}} src={group}/>
    </Container>
    

    </div>)
}

export default Reg