import { createSlice } from "@reduxjs/toolkit";

var initialState = {
    isLoged: false,
    user:"",
    id:"",
    email:'',
    time:{}
}

if(localStorage.getItem("logedUser") != null)
{
    const data = JSON.parse(localStorage.getItem("logedUser"));
    initialState = data;
}

export const logeduserSlice = createSlice({
    name:"logedUser",
    initialState,
    reducers:{
        setLogin:(state,action)=>{
            let now = new Date();
            state.isLoged =true;
            state.user = action.payload.user
            state.id = action.payload.id
            state.email = action.payload.email                     
            state.time = {h:now.getHours(),m:now.getMinutes()}
            localStorage.setItem("logedUser",JSON.stringify(state))

        },
        logOut:(state,action)=>{
            state.isLoged = "";
            state.user = ""
        }
    }

})



export const {setLogin,logOut} = logeduserSlice.actions
export default logeduserSlice.reducer
