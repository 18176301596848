import React from 'react'
import { Snackbar,Card } from '@mui/material'
import {InfinitySpin} from  'react-loader-spinner'
import style from "../styles";
const Loading = (props) => {
  return (

    <Snackbar style={{bottom:0,
        top:0,
        left:0,
        right:0
        }} open={props.load} sx={{position: 'fixed',
        width: '100%',
        backgroundAttachment:"fixed",
        height: '100vh',
        background: '#ffffff8a',
        zIndex: '22',
        bottom:0,
        top:0,
        left:0,
        right:0,
        zIndex:10000
        }}>
         <Card sx={{opacity: '1',height: '92px',boxShadow: '2px 1px 3px -1px #0099ff',width: {xs:'50%',sm:'30%',md:'20%',lg:'15%',xl:'10%'},position: 'absolute',top: '45%',left: {xs:'25%',sm:'30%',md:'50%',lg:'50%',xl:'50%'}}}>
            <InfinitySpin 
          width='100%'
          height='100%'
          
          color={style.colors.primary}
        />
          </Card>
        </Snackbar>
        
    )
}

export default Loading