import React, { useState } from 'react'
import {InfinitySpin} from  'react-loader-spinner'
import style from '../styles'
import { Box, Typography } from '@mui/material'
import rocket from '../assets/rocket_launch.gif'
const HomeLoading = (props) => {

    const [show,setshow] = props.show;
  return (<div>
          <Box sx={{position:"fixed",
          display:show?"block":"none",
          top:"40%",
          left:{
            xs:"35%",
            sm:"",
            md:"45%",
            lg:"45%",
            xl:"45%"
          }
        }} component="div">
              <InfinitySpin width='100%' color={style.colors.primary}/>
                  <Typography sx={{position: 'relative',bottom: '4rem',left: '3rem'}} variant='body1' color="primary">
            ትንሽ ይጠብቁ...
            </Typography>
  
          </Box>

</div>
    )
}

export default HomeLoading