import React, { useState } from 'react'
import {Button, ButtonGroup, TextField} from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded, Done, UploadFile } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useRef } from 'react'
import SnackBar from '../../components/SnackBar';
import axios from 'axios'
import api from '../../data/api'
import Loading from '../../components/Loading'
import { useSelector } from 'react-redux';

const Org4 = () => {



    const {state} = useLocation();
    const orgId = state.id;
    const baseURL = api.address;
    const ruleRegulation = useRef()
    const businessName = useRef();
    const agreement = useRef();
    const registrationLicense = useRef();
    const businessLicense = useRef();
    const taxPayerID = useRef();
    const legalReceipts = useRef();
    const accountCheck = useRef();    
    const professionalLicense = useRef();
    const acceptedProject = useRef();
    const legality = {orgId:orgId};
    const formdata = new FormData();
    const [snack,setsnack]= React.useState({Type:"info",msg:"everything is fine!",show:false});
    const link = useNavigate();
    const [load,setload] = useState(false);
    const user = useSelector((state)=>state.logedUser)



    const Update = e => {
     //   console.log(legality)
   //     legality[e.target.name] = e.target.files[0];
        formdata.append(e.target.name,e.target.files[0])
       


    }
    
    const submit = () => {

            formdata.append('orgId',orgId);
            console.log(formdata)

            let token = user.user;
            console.log(token)
            const upload = async ()=> {
                setload(true)
            const post = await axios.post(`${baseURL}/addressapi/legality/create`,formdata,{
                headers: {
                    'Authorization': `Bearer ${token}`
                  }   
            }).then((resp)=>{
                console.log("resp",resp) 
                setsnack({Type:"info",msg:"ገብትዋል!",show:true})
                setload(false);
                if(resp.data != null)
                    {
                link('../view',{state:{id:orgId}});
                    setload(false);

                    }
                return resp;
            }).catch((err)=>{
                console.log("err",err)
                setsnack({Type:"error",msg:err?.response?.data.message,show:true})
                setload(false);

                setTimeout(() => {




                }, 1000);
                return err});
                return post;
        }
    
        console.log(upload());

    }

  

  return (

    <Card>
        <SnackBar snack={snack} />
        <Loading load={load} />
    <CardContent sx={{width:"100%"}}>
       
    <Grid container >
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h5' color="primary">ህጋዊ ማዕቀፍና አደረጃጀት!</Typography>

        <InputLabel htmlFor="component-simple">ቃለ ጉባኤ መመስረቻ ጹሁፍና መተዳደርያ ደንብ</InputLabel>
            <Button onClick={e=>{ ruleRegulation.current.click() }} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input onChange={e=>Update(e)} style={{display:"none"}} type='file' ref={ruleRegulation} name="ruleRegulation"  id="component-simple"  />


            <InputLabel htmlFor="component-simple">የንግድ ስም እና የንግድ ስያሜ ማስረጃ</InputLabel>
            <Button onClick={e=>{ businessName.current.click() }} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input onChange={e=>Update(e)} type='file' style={{display:"none"}}  ref={businessName} name='businessName'  />


            <InputLabel htmlFor="component-simple">የጵሮጀክት ጽ/ቤትና አከራይ ተከራይ ውልና ማስረጃ</InputLabel>
            <Button onClick={e=>{ agreement.current.click() }}  startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input onChange={e=>Update(e)} ref={agreement} type='file' style={{display:"none"}} name="agreement"  />


            <InputLabel htmlFor="component-simple">የንግድ ምዝገባ ፈቃድ ማስረጃ</InputLabel>
            <Button onClick={e=>{businessLicense.current.click()}} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input type='file' onChange={e=>Update(e)} ref={businessLicense} style={{display:"none"}} name="businessLicense"  />

       </Grid>
       <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        

            <InputLabel htmlFor="component-simple">የንግድ ስራ ፈቃድ ማስረጃ</InputLabel>
            <Button onClick={e=>{ businessLicense.current.click() }} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input type='file' onChange={e=>Update(e)} ref={businessLicense} style={{display:"none"}} name="businessLicense"  />


            <InputLabel htmlFor="component-simple">የግብር ከፋይነት መለያ ቁጥርና ማስረጃ</InputLabel>
            <Button onClick={e=>{ taxPayerID.current.click() }} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input type='file' onChange={e=>Update(e)} ref={taxPayerID} style={{display:"none"}} name="taxPayerID"  />



    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
    <InputLabel htmlFor="component-simple">ህጋዊ ደረሰኞች</InputLabel>
    <Button onClick={e=>{ legalReceipts.current.click() }} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input type='file' onChange={e=>Update(e)} ref={legalReceipts} style={{display:"none"}} name="legalReceipts"  />



            <InputLabel htmlFor="component-simple">የቁጠባና ተንቀሳቃሽ ሂሳብ/ቼክ</InputLabel>
            <Button onClick={e=>{ accountCheck.current.click() }} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input type='file' onChange={e=>Update(e)} ref={accountCheck} style={{display:"none"}} name="accountCheck"  />


            <InputLabel htmlFor="component-simple">የሙያ ፈቃድ ማስረጃዎች</InputLabel>
            <Button onClick={e=>{ professionalLicense.current.click() }} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input type='file' onChange={e=>Update(e)} ref={professionalLicense} style={{display:"none"}} id="professionalLicense"  />


            <InputLabel htmlFor="component-simple">ቅቡል ጵሮጀክትና ድርጂት</InputLabel>
            <Button onClick={e=>{ acceptedProject.current.click() }} startIcon={<UploadFile/>}>ለማስገባት</Button>
            <input type='file' onChange={e=>Update(e)} ref={acceptedProject} style={{display:"none"}} id="acceptedProject"  />



            
    </Grid>




    </Grid>

    </CardContent>
    <CardActions sx={{paddingLeft:20}}>        
    <Link to="../view" state={{id:orgId}}><Button size='large' startIcon={<ArrowBackIosNewRounded /> } variant="contained">መመለስ</Button></Link> 
             &nbsp;
             
        <Button size='large' style={{color:"white"}}   color='secondary' endIcon={<ArrowForwardIosRounded/> } variant="contained" onClick={submit}>መዝግብ</Button> 

    </CardActions>
    <br /><br />
</Card>
    )
}

export default Org4