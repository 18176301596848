// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-CryptoCard-styles-module__crypto-card--JFjLG{display:flex;background:rgba(63,76,107,.35);min-width:auto;min-height:auto;width:24rem;height:14rem;font-family:Roboto;padding:1rem}", "",{"version":3,"sources":["webpack://./src/CryptoCard/styles.module.scss"],"names":[],"mappings":"AAAA,kDACE,YAAA,CAEA,8BAAA,CAEA,cAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CAEA,YAAA","sourcesContent":[".crypto-card {\n  display: flex;\n\n  background: rgba(#3f4c6b, 0.35);\n\n  min-width: auto;\n  min-height: auto;\n  width: 24rem;\n  height: 14rem;\n  font-family: Roboto;\n\n  padding: 1rem;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crypto-card": "src-CryptoCard-styles-module__crypto-card--JFjLG"
};
export default ___CSS_LOADER_EXPORT___;
