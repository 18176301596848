import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const Org = (props) => {
    const [active,setactive] = props.states
    const navigate = useNavigate();


  return (
    <Box sx={{display:active ==2?"block":"none",padding:3}}>
<Typography color="secondary" variant='h4'>
    የድርጅትዎ መረጃ <hr style={{width: '70%',position: 'relative',display: 'inline-block',top: '8px',color: '#fe9900',backgroundColor: '#fe9900',height: '5px',outline: 'none',border: 'none'}} />
</Typography>

<Outlet/>



    </Box>
  )
}

export default Org