import React, { useEffect } from 'react'
import { Button, AppBar, List, Box, Toolbar, Grid, Container, Typography, ListItem, ListItemText, ListItemIcon, ListItemButton, Divider } from '@mui/material'
import {setDetail} from '../../data/school/schoolDetail'
import lang from './lang.json'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { BookOnlineRounded, Dashboard, LibraryAddCheckRounded, ListAlt, Person2, SchoolRounded } from '@mui/icons-material'
const SchoolMs = (props) => {
  const detail = props.detail
  const text = lang[0]
  const dispatch = useDispatch();



  useEffect(()=>{
    dispatch(setDetail(detail))

  },[])

  return (
    <Box sx={{height:'100vh'}} id="schoolsys">
        <Toolbar>
        <Typography variant='h6'>{`${detail?.name} ${text.title}`}</Typography>
        </Toolbar>
      <Grid container>
      <Grid item md={2}>
        <Container>
        <List>
        <Link to='/main/org/view/' style={{ color: "cadetblue"}}>
        <ListItem>
          <ListItemIcon>
            <Dashboard/>
          </ListItemIcon>
            <ListItemButton>
            <Typography variant='body1'>{text.navDash}</Typography>
            </ListItemButton>
          </ListItem>
          <Divider/>
          </Link>
          <Link to='/main/org/view/employers' style={{ color: "cadetblue"}}>
        <ListItem>
        <ListItemIcon>
            <Person2/>
          </ListItemIcon>
            <ListItemButton>
            <Typography variant='body1'>{text.assUser}</Typography>
            </ListItemButton>
          </ListItem>
          <Divider/>
          </Link>

          <Link to='/main/org/view/list' style={{ color: "cadetblue"}}>
          <ListItem>
              <ListItemIcon>
                <ListAlt/>
              </ListItemIcon>
            <Typography variant='body1'>{text.navEmp}</Typography>
          </ListItem>
          <Divider/>
          </Link>

          <Link to='/main/org/view/administrations' style={{ color: "cadetblue"}}>
          <ListItem>
            <ListItemIcon>
              <SchoolRounded/>
            </ListItemIcon>
            <ListItemButton>
            <Typography variant='body1'>{text.navSchool}</Typography>
            </ListItemButton>
          </ListItem>
          <Divider/>
          </Link>
          <Link to='/main/org/view/studentlist' style={{ color: "cadetblue"}}>
          <ListItem>
            <ListItemIcon>
              <BookOnlineRounded/>
            </ListItemIcon>
            <ListItemButton>
            <Typography variant='body1'>{text.stuList}</Typography>
            </ListItemButton>
          </ListItem>
          <Divider/>
          </Link>

          <Link to='main/org/view/teachers' style={{ color: "cadetblue"}}>
          <ListItem>
            <ListItemIcon>
              <LibraryAddCheckRounded/>
            </ListItemIcon>
            <ListItemButton>
            <Typography variant='body1'>{text.navLib}</Typography>
            </ListItemButton>
          </ListItem>
          <Divider/>
          </Link>
        </List>
        </Container>
      </Grid>
      <Grid item md={10} sx={{borderLeft:"solid 1px GrayText"}}>
          <Outlet/>
      </Grid>
      </Grid>
    </Box>
  )
}

export default SchoolMs