// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-CryptoCard-CryptoChart-styles-module__crypto-chart--OLY0n{position:absolute;bottom:0;right:0;left:0;top:40%;display:flex;width:100%}.src-CryptoCard-CryptoChart-styles-module__crypto-chart--OLY0n canvas{height:100% !important;width:100% !important}", "",{"version":3,"sources":["webpack://./src/CryptoCard/CryptoChart/styles.module.scss"],"names":[],"mappings":"AAAA,+DACE,iBAAA,CACA,QAAA,CACA,OAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CACA,UAAA,CAEA,sEACE,sBAAA,CACA,qBAAA","sourcesContent":[".crypto-chart {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  top: 40%;\n  display: flex;\n  width: 100%;\n\n  canvas {\n    height: 100% !important;\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crypto-chart": "src-CryptoCard-CryptoChart-styles-module__crypto-chart--OLY0n"
};
export default ___CSS_LOADER_EXPORT___;
