import React from 'react'
import {Button, ButtonGroup, TextField} from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded, Done } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link } from 'react-router-dom'
import { UploadFile } from '@mui/icons-material'
const Org10 = () => {
  return (

    <Card>
    <CardContent sx={{width:"100%"}}>
       
    <Grid container >

<Grid sx={{textAlign:"center"}} item xs={12} sm={12} md={12} lg={12} xl={12}>
<br /><br /><br />
    <Typography variant='h4'>መረጃዎን ሞልተዋለ ለማስገባት እርግጠ ከሆኑ አስገባ እሚለውን ይጫኑ!</Typography>
    <br />
</Grid>

    </Grid>
    </CardContent>
    <CardActions sx={{paddingLeft:20}}>        
    <Link to="/main/org/4"><Button size='large'  style={{color:"white"}} startIcon={<ArrowBackIosNewRounded /> } variant="contained">የበፊት</Button></Link> 
             &nbsp;
             <Button size='large' startIcon={<Done/>} style={{color:"white"}} color='secondary' variant='contained'>አስገባ</Button>

    </CardActions>
    <br /><br />
</Card>

    )
}

export default Org10