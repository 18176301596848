import {Grid, Container,List,Card, Button, TextField, Divider, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, CardHeader, CardContent, Box, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import lang from '../lang.json'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Update } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import axios from 'axios'
import api from '../../../data/api'




const Employers = () => {

    const text = lang[0]


    const schoolDetail = useSelector((state)=>state.schoolDetail)
    const [userType,setUserType] = useState("")
    const [createQuery,setCreateQuery] = useState({})
    const [userDetail,setUserDetail] = useState([])
    const [searchResult, setSearchResult] = useState([])
    const logedUser = useSelector((state)=>state.logedUser)
    const [isLoading,setLoading] = useState(false)
    const [studentDetail,setStudentDetail] = useState({})




    useEffect(()=>{
        (async()=>{
            if(userDetail?.id != undefined)
            await axios.get(`${api.address}/addressapi/students/${userDetail.id}`,{
        headers:{
            'Authorization': `Bearer ${logedUser.user}`
        }})
        .then((result) => {
            setStudentDetail(result.data)
            console.log(result)

        }).catch((err) => {
            console.error(err)   
        });

        })()
},[userDetail])



const senCreatQuery = async ()=>{


   userType =='student'?setCreateQuery({...createQuery,parentShortCode:parseInt(createQuery?.parentShortCode)}):console.log("___");
   setCreateQuery({...createQuery,schoolId:schoolDetail.id})
   await axios.post(`${api.address}/addressapi/schools/create`,{
    headers:{
        'Authorization': `Bearer ${logedUser.user}`
    }
        })
   .then((result) => {
    console.log(result)
   }).catch((err) => {
    console.log(err)
   });



}


  return (
    <Box sx={{padding:2}}>
        <Grid container>
        <Grid item md={3}>
        <br />
            <TextField
            fullWidth
            placeholder={text.textSearchUser}
            variant='outlined'
            
            onChange={async e=>{
                setLoading(true)
                let value = e.target.value;
                if(value == "")
                {setUserDetail({}); setSearchResult([]); setLoading(false)}
                else
                await axios.get(`${api.address}/addressapi/users/search?key=${value}`)
                .then((result) => {
                    setSearchResult(result.data)
                    setLoading(false)
                }).catch((err) => {

                });


            }}

            />
            <br />
            <Divider/>
            <List>
                                {isLoading?<>
                        <ListItem>
                  <ListItemAvatar>
                                        <Skeleton animation width={60} height={100} style={{borderRadius:"100%"}} />
                                    </ListItemAvatar>
                                    <ListItemText primary={<Skeleton animation />}
                                    secondary={<Skeleton animation />}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Skeleton animation width={60} height={100} style={{borderRadius:"100%"}} />
                                    </ListItemAvatar>
                                    <ListItemText primary={<Skeleton animation />}
                                    secondary={<Skeleton animation />}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Skeleton animation width={60} height={100} style={{borderRadius:"100%"}} />
                                    </ListItemAvatar>
                                    <ListItemText primary={<Skeleton animation />}
                                    secondary={<Skeleton animation />}
                                    />
                                </ListItem>
                                </>:<>
                {searchResult.map((user)=><ListItem sx={{cursor:"pointer"}} onClick={e=>{
                    setUserDetail(user);
                    setCreateQuery({...createQuery,shortCode:user.userCode})
                }}>
                    <ListItemAvatar>
                        <Avatar/>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant='body1'>{user.firstName+" "+user.lastName}</Typography>}
                    secondary={<Typography variant='body2' color="GrayText">{user.userCode}</Typography>}
                    />
                </ListItem>)}</>}
            </List>
                </Grid> 
        <Grid item md={6}>
        <br />
            <Divider/>
            {userDetail?.id == null?<Card>

                <CardContent sx={{padding:15, height:"20vh"}}>
                    <Typography variant='h5' sx={{textAlign:"center"}} color='GrayText'>{text.detailInfo}</Typography>
                </CardContent>

            </Card>:<Card>
                <CardHeader title={<Typography variant='h5' color="GrayText">{text.detailInfo}</Typography>} />
                <Divider/>
                <CardContent sx={{padding:5}}>
                    <Grid container>
                        <Grid item md={3}>
                        <Avatar size='100' sx={{width:"100px",height:"100px"}}/>
                        </Grid>
                        <Grid item md={6}>
                        <Typography variant='body1' color="GrayText">{text.firstName}:<b>{userDetail.firstName}</b></Typography>
                        <Typography variant='body1' color="GrayText">{text.middleName}:<b>{userDetail.middleName}</b></Typography>
                        <Typography variant='body1' color="GrayText">{text.lastName}:<b>{userDetail.lastName}</b></Typography>                       
                        <Typography variant='h6' color="secondary" onClick={async e=> {
                              const type = "text/plain";
                              const blob = new Blob([userDetail.userCode], { type });                            
                              const data = [new ClipboardItem({ [type]: blob })];
                              navigator.clipboard.write(data)
                              e.target.innerText = userDetail.userCode+" "+text.copied
                        }}><b>[{userDetail.userCode}]</b></Typography>                       
                        </Grid> 
                        <Grid item md={3}>
                       <Typography variant='body1' color="GrayText">{text.sex}:<b>{userDetail.sex}</b></Typography>
                        </Grid> 
                    </Grid>
                    <br />
                    <Divider/>
                    <Grid container sx={{padding:1}}>
                        <Grid item md={4}>
                        <Typography variant='body1' color="GrayText">{text.region}:<br/><b>{userDetail.region}</b></Typography>    
                        <Typography variant='body1' color="GrayText">{text.zone}:<br/><b>{userDetail.zone}</b></Typography>    
                        <Typography variant='body1' color="GrayText">{text.city}:<br/><b>{userDetail.city}</b></Typography>    
                        <Typography variant='body1' color="GrayText">{text.kebele}:<br/><b>{userDetail.kebele}</b></Typography>    
                        </Grid>
                        <Grid item md={8}>
                        <Typography variant='body1' color="GrayText">{text.email}:<br/><b>{userDetail.email}</b></Typography>    
                        <Typography variant='body1' color="GrayText">{text.phone}:<br/><b>{userDetail.phone}</b></Typography>    
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>}
        </Grid>
        <Grid item md={3} sx={{padding:2}}>
        <br />
            <Divider/>
            
        <Typography variant='body1' sx={{margin:1}} color='GrayText'>{text.textAssign}</Typography>
        <Divider/>
        <Card>
            <CardContent>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="demo-select-small-label">{text.setPostion}</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label={text.setPostion}
                    fullWidth
                    value={userType}
                    onChange={e=>{
                        setUserType(e.target.value);
                        setCreateQuery({...createQuery,position:e.target.value})
                    }}
                >
                    <MenuItem value="">
                     <Typography color="error" variant='body1'>Delete</Typography>
                    </MenuItem>
                    <MenuItem value="teacher">{text.teacher}</MenuItem>
                    <MenuItem value="admin">{text.admins}</MenuItem>
                    <MenuItem value="librarian">{text.librarian}</MenuItem>
                    <MenuItem value={"student"}>{text.students}</MenuItem>
                </Select>
    </FormControl>
    {userType=="student"?<FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="demo-select-small-label">{text.parents}</InputLabel>
                <TextField 
                placeholder={text.parentsNeku}
                onChange={e=>{
                    setCreateQuery({...createQuery,parentShortCode:e.target.value})
                }}
                />
    </FormControl>:<></>}
    <br /><br /><br />
    <Button variant='contained' onClick={senCreatQuery} disabled={userType=="student" && createQuery?.parentShortCode == null? true:false} startIcon={<Update/>} >{text.updateSet}</Button>
            </CardContent>
        </Card>

        </Grid> 
                
        </Grid>
    </Box>
  )
}

export default Employers