import React from 'react'
import { useState } from 'react';
import { AppBar,Box,Button,IconButton,Container,Toolbar, Typography} from '@mui/material'
import logo from "../assets/circle.png"
import style from '../styles';
import define from '../strings';
import {Avatar} from '@mui/material';
import { useSelector } from 'react-redux';
const UserAppbar = () => {
    const [lang,setlang] = useState(define.Am)
    const user = useSelector((state)=>state.userInfo)
    const navItems = ['Home', 'About', 'Contact'];
    const [showAv,setAv] = useState();
  return (
    <>
    <AppBar color='white' position="static" component="nav">
    <Container maxWidth="xl">
      <Toolbar color='white' disableGutters>
      <img src={logo}  style={{width:"58px"}}/>

        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={style.logoText}          >
          {lang.logoText}
        </Typography>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
        <Box sx={{ float:"right", display: { xs: 'none', sm: 'block' } }}>
        <IconButton sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={showAv} />
              </IconButton>
          <Button sx={{color: '#556080',fontSize: '18px',fontWeight: '500',    textTransform: 'capitalize'}}> <Typography> {user.user.firstName}</Typography></Button>
          </Box>      
          </Toolbar>
    </Container>
  </AppBar>
  </>)
}

export default UserAppbar