// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-NewsHeaderCard-styles-module__news-header-card--zsMEl{position:relative;display:flex;flex-flow:column;width:450px;height:100%}", "",{"version":3,"sources":["webpack://./src/NewsHeaderCard/styles.module.scss"],"names":[],"mappings":"AAAA,2DACE,iBAAA,CACA,YAAA,CACA,gBAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".news-header-card{\n  position: relative;\n  display: flex;\n  flex-flow: column;\n  width:450px;\n  height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"news-header-card": "src-NewsHeaderCard-styles-module__news-header-card--zsMEl"
};
export default ___CSS_LOADER_EXPORT___;
