import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import style from './styles';
import logo from './assets/circle.png';
import {Box, Card, CardContent,CardActions, CardHeader, CircularProgress, FormControlLabel } from '@mui/material';
import {TextField} from '@mui/material';
import group from './assets/group.png';
import {ButtonGroup,IconButton} from '@mui/material'
import { Close, Facebook, Telegram ,YouTube, YoutubeSearchedFor} from '@mui/icons-material';
import define from './strings';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { useDispatch, useSelector } from 'react-redux';
import SnackBar from './components/SnackBar';
import { userLogin } from './data/UserLogin';
import Loading from './components/Loading';
import { useNavigate } from 'react-router-dom';
import {setLogin} from './data/Loged';
import {fetchUserInfo} from './data/userInfo';
import { styled, alpha } from '@mui/material/styles';
import {InputBase} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useRef } from 'react';
import api from './data/api';
import Show from './Show';
import axios from 'axios';

const baseURL = api.address
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const App = () => {

  const nav = useNavigate();
  const [lang,setlang] = useState(define.Am);
  const [email,setemail] = useState("");
  const [pwd,setpwd] = useState("");
  const disp = useDispatch();
  const server = useSelector((state)=>state.loginUser)
  const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
  const [load,setload] = useState(false)
  const [nardos,setnordos] = useState(false);
  const [Nekukuter,setNekukuter] = useState("")
  const [prog,setprog] = useState(true)
  const [open,setopen] = useState(false);
  const [user,setuser] = useState({})
  const buttons = [
    <IconButton color="primary" onClick={()=>{
      window.location = "https://www.facebook.com/search/top/?q=eplusApp";
    }} aria-label="add an alarm">
      <Facebook />
    </IconButton>
      ,
      <IconButton color="primary" aria-label="add an alarm" onClick={()=>{
        window.location = "https://t.me/eplusapp88";
      }}>
      <Telegram />
    </IconButton>
    ,
    <IconButton color="primary" aria-label="add an alarm" onClick={()=>{
      window.location = "https://www.youtube.com/@eplusapp8051"
    }}>
      <YouTube />
    </IconButton>
    ];

useEffect(()=>{

  if(server.status == "loading")
  {
    setload(true)
  }
  else if(server.status == "failed")
  {
  setsnack({Type:"warning",msg:"የተሳሳተ ኢሜል ወይም የይለፍ ቃል አስገብተዋል!",show:true}); 
  setload(false)
  }
  else if(server.status == "success")
  {
    disp(setLogin({email:server.user.email,user:server.user.token,id:server.user.id}))
    const userdata = server.user
    if(userdata.kebele == null && userdata.subCity == null  && userdata.city == null && userdata.region == null )
    {
       nav("/welcome")
    }
    else 
    {
        nav("/main")
    }

  }
  else 
  setload(false)

},[server])




const login = ()=>{

  if(email == "" || pwd == "")
  setsnack({Type:"error",msg:"ሁሉንም መጠይቅ ያምሟሉ!",show:true}); 
  else 
  {
   console.log(disp(userLogin({email:email,password:pwd})))
  }
  


  
}


useEffect(()=>{



},[])



const hand = e =>{

setprog(false);
const fetch = axios.get(`${baseURL}/addressapi/users/search?key=${Nekukuter}`).then((resp)=>{

  console.log(resp.data)
  if(resp.data == [])
  {setprog(true)}
  else 
  {
    setprog(true)
      setuser(resp.data[0]);
      setopen(true)
  }

})
  

}


const buttonStyle = 
  {padding: '8px',
border: 'solid 1px #0000002b',
textAlign: 'center',
borderRadius: '50%',
width: '22px',
height: '22px',
boxShadow: '0px 0px 4px -1px #00000091',
fontWeight: 'bold',
fontSize: '22px',
marginBottom:10,
}

const wrapBtn = {
  marginLeft: '50px',
  cursor: 'pointer'
}


  return (<div style={style.main}>
    <AppBar color='white' position="static">
      <Container maxWidth="xl">
        <Toolbar color='white' style={{padding:15}} disableGutters>
          <img src="https://th.bing.com/th/id/OIP.PukQtQNUuJhU7O610zGQ-AAAAA?rs=1&pid=ImgDetMain"  style={{width:"78px"}}/>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={style.logoText}          >
          </Typography>

      
        </Toolbar>
      </Container>
    </AppBar>
    <SnackBar snack={snack} />
    <Loading load={load} />   
<Container>
<Typography initial={{left:"-100%"}} transition={{ ease: "easeOut", duration: 3 }} animate={{left:""}} component={motion.div} variant='h4' color='secondary' sx={style.title}>
Welcome to AplusApp platform!
    </Typography>
{nardos?<Box sx={{background: '#cfecff',
width: '24rem',
display: 'flex',
padding: '6px',
borderRadius: '5px',
position: 'fixed',
left: {xs:'1%',sm:"1%",md:"8%",lg:"8%",xl:"8%"},
bottom: {xs:'1%',sm:"1%",md:"68%",lg:"58%",xl:"68%"},
zIndex: '3'}}>
<Search>

            <SearchIconWrapper>
            <SearchIcon />  
            </SearchIconWrapper>
            <StyledInputBase
            type='text'
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              value={Nekukuter}
              onChange={e=>setNekukuter(e.target.value)}
            />
          </Search>
          {prog?<Button onClick={hand} variant='text'  startIcon={<SearchIcon/>}>ፈልግ</Button>:<CircularProgress />}
          <Button variant='text' onClick={e=>setnordos(false)} color='error'> <Close/> </Button>
</Box>:""
}
{/* Login card         */}


<Card  initial={{top:"-100%"}} transition={{ ease: "easeOut", duration: 1 }} animate={{top:"20%"}}  component={motion.div} sx={style.loginCard}>
     <CardContent>
  

        <Typography variant="h6" style={{fontFamily:"Ny"}}  component="div" color=''>
        ይግቡ! 
      </Typography>
      <br />
      <Typography>ኢሜል ወይም ንቁ ቁጥር፡</Typography>
      <TextField sx={{'&:hover':{borderColor:'primary'},width:{xs:"18rem",sm:"18rem",md:"25rem",lg:"25rem",xl:"25rem"}}}
  id="outlined-controlled"
  autoFocus={true}
  value={email}
  onChange={e=>setemail(e.target.value)}

/> <br /><br />
<Typography>የይለፍ ቃል፡</Typography>
<TextField
sx={{width:{xs:"18rem",sm:"18rem",md:"25rem",lg:"25rem",xl:"25rem"}}}
  id="outlined-uncontrolled"
  type='password'
  value={pwd}
  onChange={e=>setpwd(e.target.value)}
/>
<br /><br />
<Button sx={{fontSize: '17px',fontWeight: '700',}} onClick={login} size='large' variant='contained'>{lang.loginBtn}</Button>
<br /><br />
<Link to="/reg">
<Button   size='large' id='createBtn' color='primary' sx={{fontSize: '17px',lineHeight: '0',fontWeight: '700'}} variant='text' >{lang.createNewBtn}</Button>
</Link>
<br /><br />
<br />
<Typography sx={{ mb: 1.5,textAlign:"center", }} color="text.secondary">
       {lang.faqdesc}<Button size="small">{lang.faqbtn}</Button>
        </Typography> 

      </CardContent>
      <CardActions sx={{display: 'flex',justifyContent: 'center'}}>
        <ButtonGroup variant='text' size="large" aria-label="large button group">
        {buttons}
      </ButtonGroup>
      </CardActions>
      <br />
    </Card>














<Box  initial={{bottom:"-100%"}} transition={{ ease: "easeOut", duration: 0.5 }} animate={{bottom:"0%"}}  component={motion.img}   sx={{width: {xs:'13rem',xl:'25rem',lg:'25rem'},position: 'fixed',bottom: '0',left: '0',zIndex:{ xl:'-14',lg:'-14',md:'-14',sm:'-1',xs:'-1'}}} src={group}/>
    </Container>
    </div>)
}

export default App