import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import lang from '../lang.json'

export default function CourseTable(props) {
    const data = props.data
    const text = lang[0]
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 350,maxHeight:400,overflow:"scroll" }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{text.courseName}</TableCell>
            <TableCell align="right">{text.gradeLevel}</TableCell>
            <TableCell align="right">{text.courseCode}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{overflow:"scroll",height:"100px"}}>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='left'>{row.courseName}</TableCell>
              <TableCell align="right">{row.gradeLevel}</TableCell>
              <TableCell align="right">{row.courseCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
