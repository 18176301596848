import { Grid, Container,Box, Typography, Toolbar, Button, AppBar, List, ListItem, ListItemIcon, ListItemText, Snackbar } from '@mui/material';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ass from './assets/bannerbg.jpg'
import api from './data/api';
import { useEffect } from 'react';
import axios from 'axios';
import { ArrowBack, CurrencyBitcoin, Email, EmailOutlined, Info, LocalActivity, LocationCity, LocationCityOutlined, LocationCityRounded } from '@mui/icons-material';
import SnackBar from './components/SnackBar';



const Detail = () => {

    const state = useLocation();
    const link = useNavigate();
    const data = state.state?.item;
    const query = state.state?.query;
useEffect(()=>{

  console.log(data)

},[])
    
  return (
    <>
      <Toolbar>
    <Button size='large' onClick={()=>{ link('/search/result',{state:{query:query}})  }} style={{color:'black',fontSize:180}} variant='text' startIcon={<ArrowBack/>} />
  </Toolbar>

<Container>
  <Container>
  <Grid container>
  <Grid item sm={2}>
      <Box style={{width:'156px'}} component='img' src={`${api.imgAddress}/${data.logo}`} />
     </Grid>
     <Grid item sm={6}>
     <Typography variant='h4'>{data.name}</Typography>
     <Typography variant='h6'>{data.businessSector}</Typography>
     <Typography variant='h6'>ከ {data.establishment}</Typography>
     </Grid>
     <Grid item sm={4}>
     <br /> 
     <Typography variant='h6'>{data.email}</Typography>
     <Typography variant='h6'>እንቁ ቁጥር {data.digitalID}</Typography>
     </Grid>
  </Grid>
  <hr />
  <Grid container>
    <Grid item sm={10}>
    <br /><br /><br />
<span style={{display:'flex',}}> <Typography variant='h5'> አድራሻ</Typography><LocationCityRounded style={{marginLeft:20,paddingTop:5}}/> </span>  
<hr />  
  <Grid container>
    <Grid item sm={4}>
    <Typography variant='h5'>ሃገር፡</Typography>
    <Typography variant='body1'>{data.country}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ክልል፡</Typography>
    <Typography variant='body1'>{data.region}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ዞን፡</Typography>
    <Typography variant='body1'>{data.zone}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ወረዳ፡</Typography>
    <Typography variant='body1'>{data.woreda}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ክፍለ ከተማ፡</Typography>
    <Typography variant='body1'>{data.subCity}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ቀበሌ፡</Typography>
    <Typography variant='body1'>{data.kebele}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የጎዳና ቁጥር፡</Typography>
    <Typography variant='body1'>{data.streetNumber}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የቤት ቁጥር፡</Typography>
    <Typography variant='body1'>{data.houseNumber}</Typography>
    </Grid>

  </Grid>
  <Box component='img' src={require('./assets/map.png')} sx={{marginTop:'65px'}} />
  <br /><br /><br />
<span style={{display:'flex',}}> <Typography variant='h5'> የአክሲዮን መረጃ</Typography><CurrencyBitcoin style={{marginLeft:20,paddingTop:5}}/> </span>  
<hr />  
  <Grid container>
    <Grid item sm={4}>
    <Typography variant='h5'>ካፒታል፡</Typography>
    <Typography variant='body1'>{data.capital} ብር</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>አሁን ያለው የአክሲዮን ብዛት፡</Typography>
    <Typography variant='body1'>{data.currentStock} አክሲዮን</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>አሁን ያለው ካፒታል፡</Typography>
    <Typography variant='body1'>{data.currentCapital} ብር</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የአክሲዮን መስራቾች ብዛት፡</Typography>
    <Typography variant='body1'>{data.stockFounder}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የግብር ቁጥር፡</Typography>
    <Typography variant='body1'>{data.tinNumber}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ቀበሌ፡</Typography>
    <Typography variant='body1'>{data.kebele}</Typography>
    </Grid>

  </Grid>
  <br /><br /><br />
<span style={{display:'flex',}}> <Typography variant='h5'> ሌሎች መረጃዎች</Typography><Info style={{marginLeft:20,paddingTop:5}}/> </span>  
<hr />  
  <Grid container>
  <Grid item sm={4}>
    <Typography variant='h5'>የስራው አይነት፡</Typography>
    <Typography variant='body1'>{data.type}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ባንክ፡</Typography>
    <Typography variant='body1'>{data.bank}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ብራንድ፡</Typography>
    <Typography variant='body1'>{data.brand}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የተቀጣሪዎች ብዛት፡</Typography>
    <Typography variant='body1'>{data.employees}</Typography>
    </Grid>

  </Grid>
  <br /><br /><br /><br /><br /><br />  <br /><br /><br /><br /><br /><br />

    </Grid>
    <Grid item sm={2}>
      <List>

      <ListItem>
          <ListItemIcon>
            <LocationCityOutlined/>
            <ListItemText primary={data.city} secondary={'ቀበሌ '+data.kebele}/>
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailOutlined/>
            <ListItemText primary={data.email} />
          </ListItemIcon>
        </ListItem>
      </List>
    </Grid>
  </Grid>
  </Container>
  
</Container>
<footer style={{textAlign:'center',margin:15}}>
 <p>2023 EplusApp S.C</p> 
</footer>
</>
)
}
export default Detail