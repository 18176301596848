import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "./api";

const baseURL = api.address;

const initialState = {
    detail:{},
    status:"",
    isloading:false,
    createStatus:"",
    hasabStatus:""
}



export const fetchDetail = createAsyncThunk('fetchDetail/org', async (data)=>{

    let id = data.id;
    let token = data.token;
    const detail = await axios.get(`${baseURL}/addressapi/organizations/${id}`,{
        headers: {
            'Authorization': `Bearer ${token}`
          }   

    }).then((resp)=>resp)
    .catch((err)=>{
        console.log(err)
    })


    return detail;

})






export const createTef = createAsyncThunk('teftef/Org', async (data)=>{

    let token = data.token;
    let teftef = data.teftef;

    const create = await axios.post(`${baseURL}/addressapi/tef_wof/create`,teftef,{
        headers: {
            'Authorization': `Bearer ${token}`
          }    
    })

    return create;

})


export const createHasab = createAsyncThunk('hasab/org', async (data)=>{

    let hasabd = data.hasab;
    let token = data.token;

    const hasab = await axios.post(`${baseURL}/addressapi/hasab/create`,hasabd,{
        headers: {
            'Authorization': `Bearer ${token}`
          }   
    }).then((resp)=>{
        console.log(resp)
        return resp
    }).catch((err)=>{
        console.log(err)
        return err
    })

    console.log(hasab)
    return hasab;

})





const detailSlice = createSlice({
    name:"detail",
    initialState,
    reducers:{
        ResetHasab:(state,action)=>{
            state.hasabStatus = "";
            console.log('Hasab_reseted')
        }
    },
    extraReducers:(builders)=>{
        builders.addCase(fetchDetail.pending, (state)=>{
            state.isloading = true;
            state.status = "pending";
        })

        builders.addCase(fetchDetail.fulfilled, (state,action)=>{
            state.isloading = false;
            state.status = "success";
            console.log(action.payload)
            state.detail = action.payload?.data

        })
        builders.addCase(fetchDetail.rejected, (state,action)=>{
            state.isloading = false;
            state.status = "failed";
            console.log(action.payload)
        })
        builders.addCase(createTef.pending, (state)=>{
            state.isloading = true;
            state.createStatus = "pending";
        })

        builders.addCase(createTef.fulfilled, (state,action)=>{
            state.isloading = false;
            state.createStatus = "success";
            console.log(action.payload)

        })
        builders.addCase(createTef.rejected, (state,action)=>{
            state.isloading = false;
            state.createStatus = "failed";
            console.log(action.payload)
        })

        builders.addCase(createHasab.pending, (state)=>{
            state.isloading = true;
            state.hasabStatus = "pending";
        })

        builders.addCase(createHasab.fulfilled, (state,action)=>{
            state.isloading = false;
            state.hasabStatus = "success";
            console.log(action.payload)

        })
        builders.addCase(createHasab.rejected, (state,action)=>{
            state.isloading = false;
            state.hasabStatus = "failed";
            console.log(action.payload)
        })

        



    }
})



export const {ResetHasab} = detailSlice.actions;
export default detailSlice.reducer