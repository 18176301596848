import React, { useEffect } from 'react'
import { Snackbar } from '@mui/material';
import {Alert} from '@mui/material';
import { useState } from 'react';
const SnackBar = (props) => {
  const [snack,setsnack]= useState(props.snack);
  useEffect(()=>{
    setsnack(props.snack)
  },[props.snack])
  const handleClose = ()=>{

    setsnack({...snack,show:false})
  }
  return (
<Snackbar style={{width:'50%',borderRadius:'5px',boxShadow:'3px 3px 4px 0px black'}} anchorOrigin={{ vertical: snack?.pos?snack?.pos:'center',
          horizontal: snack?.por?snack?.por:'center'}}  open={snack.show} onClose={handleClose} autoHideDuration={3000}>
  <Alert  severity={snack.Type} sx={{ width: '100%' }}>
    {snack.msg}
  </Alert>
</Snackbar>
  )
}

export default SnackBar








