import { Send } from '@mui/icons-material'
import {Button , Box, Container, Input, MenuItem, Select, ButtonGroup, TextField, Typography, Alert } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { Grid, ProgressBar } from 'react-loader-spinner'

const PostMan = () => {

    const [req,setReq] = useState({address:'http://',method:'get',body:`{\n\n\n\n }`})
    const [loading,setLoading] = useState(false)
    const [tresp,setTresp] = useState({})
    const [fresp,setFresp] = useState({show:false})
    const [respBody,setBody] = useState(`{\n\n\n\n }`)

    
const send = async ()=>{


    switch(req.method){
        case 'get':
            {
                setLoading(true)
                await axios.get(req.address)
                .then((result) => {
                    setLoading(false)
                    setFresp({show:false})
                    setBody(JSON.stringify(result.data,0,2))
                }).catch((err) => {
                    setLoading(false)
                    setFresp({show:true,status:err.response?.status,code:err.response?.code,data:err.response?.data,statusText:err.response?.statusText})
                    setBody(JSON.stringify(err.response?.data))
                });
            }
            break;
        case 'post':
            {
                setLoading(true)
                await axios.post(req.address,req.body)
                .then((result) => {
                    setLoading(false)
                    setFresp({show:false})
                    setBody(JSON.stringify(result.data,0,2))
                }).catch((err) => {
                    setLoading(false)
                    setFresp({show:true,status:err.response?.status,code:err.response?.code,data:err.response?.data,statusText:err.response?.statusText})
                    setBody(JSON.stringify(err.response?.data))
                });
            }
      
            break;
        default:

    }

    




}

  return (
    <Container  style={{backgroundColor:'#f0f0f0',height:'100%'}}>
    <br /><br /><br /><br /><br /><br />
        <ButtonGroup color='info' variant='text' style={{margin:10,marginInline:'15%',width:'100%'}}>
        {loading?
        <Grid height={50} color='#0099ff'/>
        :''}
            <TextField
            disabled={loading}
            variant='filled'
            style={{width:'60%'}}
            value={req.address}
            onChange={e=>setReq({...req,address:e.target.value})}
            label='Address'
            />
            <Select
                        disabled={loading}

                        variant='filled'

            value={req.method}
            onChange={e=>setReq({...req,method:e.target.value})}
            >
                <MenuItem value='get'>GET</MenuItem>
                <MenuItem value='post'>POST</MenuItem>
                <MenuItem value='put'>PUT</MenuItem>
                <MenuItem value='delete'>DELETE</MenuItem>
            </Select>
        <Button onClick={send} size='small' variant='' 
                    disabled={loading}

        startIcon={<Send/>}>
            Send
        </Button>
        </ButtonGroup>



        <hr />
        <br /><br /><br />
        <TextField
        multiline
        rows={5}
        label='Body'
        fullWidth
        style={{
            display:'block',
            margin:'auto',
        }}
        value={req.body}
        onChange={e=>setReq({...req,body:e.target.value})}
        />
        <br /><br />
        <hr />
        <Typography>Respnse</Typography>
        <hr style={{borderWidth: '3px'}} />
        <br /><br />
        {fresp.show?<Alert severity='error'>
            <Typography variant='subtitle2'>
                <code>{
                fresp.status+" "+fresp.statusText
                }</code>
            </Typography>
            </Alert>:''}
        <br />
        <TextField
        multiline
        label='Body'
        fullWidth
        style={{
            display:'block',
            margin:'auto',
        }}
        value={respBody}
        onChange={e=>setReq({...req,body:e.target.value})}
        />
        <br /><br /><br /><br /><br />
    </Container>
  )
}

export default PostMan