import {Button, Box, Container, Tab, Tabs, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid, ListItemButton, Alert, ButtonGroup, AlertTitle, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdUnits, ArrowBack, LocationOn, Money, RemoveRedEye, SearchSharp } from '@mui/icons-material'
import axios from 'axios'
import api from './data/api'

const Result = () => {

    const {state} = useLocation()
    const [query,setQuery] = useState(state?.query)
    const link = useNavigate()
    const [tab,settab] = useState(0)
    const [orgs,setOrgs] = useState([])
    const [users,setUsers] = useState([])
    const [products,SetProducts] = useState([])
    const [reSearch,setRe] = useState(true)
    const [images,setImages] = useState([])
    const [err,setErr] = useState(false)

    useEffect(()=>{

      (async ()=>{


        await axios.get(`${api.address}/addressapi/organizations/search?key=${query}`)
        .then((orgsResp)=>{
          setOrgs(orgsResp.data)
          setErr(false)

          orgsResp.data.map((org)=>{
            org.logo != null?setImages([...images,org.logo]):console.log()

          })
        })
        .catch((err)=>{
          console.log("Search Organization Err",err)
          setErr(true)
        })

        await axios.get(`${api.address}/addressapi/users/search?key=${query}`)
        .then((UsersResp)=>{
          setUsers(UsersResp.data)
          setErr(false)

          UsersResp.data.map((usr)=>{
            usr.avatar!= null?setImages([...images,usr.avatar]):console.log()
          })
        })
        .catch((err)=>{
          console.log("Search Users err",err)
          setErr(true)
        })
  
        await axios.get(`${api.address}/addressapi/products/search?key=${query}`)
        .then((prodResp)=>{
          SetProducts(prodResp.data)
          setErr(false)

          prodResp.data.map((pro)=>{
            pro.featureImage != null?setImages([...images,pro.featureImage]):console.log()
          })
        })
        .catch((err)=>{
          console.log("Product Users err",err)
          setErr(true)
        })
  
      })()



    },[reSearch])


console.log(images)


  return (
    <>
    {err?<Alert severity="error">የኢንተርኒት ግንኙነት ችግር አለ! እባክዎ በድጋሚ ይሞክሩ</Alert>:''}
          <div style={{display:'flex',margin: 'auto',marginTop:30,justifyContent:'center'}}>
              
<ButtonGroup variant='text' color='black1'>
  <IconButton onClick={()=>{link('/search')}}><ArrowBack/></IconButton>
<Button onClick={()=>{window.location = 'https://www.eplusapp.et'}} startIcon={<AdUnits/>} >ማስታዎቅያ</Button>
<Button onClick={()=>{window.location = 'https://www.eplusapp.et'}} startIcon={<LocationOn/>} >አድራሻ</Button>
<Button onClick={()=>{window.location = 'https://www.eplusapp.et'}} startIcon={<Money/>} >ገባያ</Button>
</ButtonGroup>

<Box onClick={()=>window.location = 'https://www.eplusapp.et'} style={{width:'58px',cursor:'pointer'}} component='img' src={require('./assets/circle.png')} />
<Typography variant='h5' style={{margin:'auto',marginInline:10}}>ኢጵላሣጵ</Typography>
<input
value={query}
onChange={e=>setQuery(e.target.value)}
style={{
display: 'block',
width: '58%',
padding: '15px',
borderRadius: '26px',
border: '1px solid darkgray',
boxShadow: 'darkgray 0px 2px 10px -4px',
fontSize: '15px',
borderTopRightRadius: '0',
borderBottomRightRadius: '0',
borderRight: 'none',
marginRight:0,
fontWeight:'bold'
}} 
placeholder='ፈልግ'
/>
<button

onClick={()=>{

if(query == '' || query === '')
{ 
console.log("empty_query")
}
else 
{

  setImages([])
    setRe(!reSearch)
}


}}


style={{
cursor:'pointer',
border: '1px solid darkgray',
borderRadius: '26px',
borderTopLeftRadius: '0',
borderBottomLeftRadius: '0',
width:'86px'
}}
><SearchSharp/></button>

</div>
<br /><br />
<hr />

    <Container>
<Tabs
        value={tab}
        aria-label="Tabs where each tab needs to be selected manually"
        sx={{display:{
          xs:"none",
          sm:"none",
          md:"block",
          lg:"block",
          xl:"block",
          
        }}}
        >
        <Tab onClick={()=>settab(0)} label={<Button variant='outline' sx={{fontSize: '14px',fontWeight: '900'}}>ሁሉም</Button>} />
        <Tab onClick={()=>settab(1)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>ምስል</Typography>}  />
        <Tab onClick={()=>settab(2)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>ምርት</Typography>}/>
        <Tab onClick={()=>settab(3)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>ድርጅት</Typography>}/>
        <Tab onClick={()=>settab(4)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>ግለሰብ</Typography>}/>

      </Tabs>
      {(users.length == 0 && orgs.length == 0 && products.length == 0)?<Typography variant='h5'> <img style={{width: '200px',display: 'block'}} src={require('./assets/R.gif')} />  {query} ምንም ውጤት ማግኘት አልተቻለም!</Typography>:'' }

<Container style={{display:tab == 0?'':'none'}}>
 <List>
 {users.length>0?<Typography variant='h6'>ግለ ሰብ</Typography>:''}



{
  users.map(((user)=>
<ListItem onClick={()=>{ 
  link('/userdetail',{state:{item:user,query:query}})
}} style={{cursor:'pointer'}}>
  <ListItemAvatar>
    <Avatar src={`${api.imgAddress}/${user.avatar}`} />
  </ListItemAvatar>
  <ListItemText primary={user.firstName+' '+user.middleName+' '+user.lastName} secondary={'ኢሜል ፡ '+user.email}/>
  <ListItemText secondary={'ንቁ ቁጥር: '+user.userCode}/>
  <ListItemText secondary={'ፆታ: '+user.sex}/>
  <ListItemText secondary={'ስልክ: '+user.phoneNumber}/>
  {user.postion == null?'':<ListItemText secondary={'የስራ ዘርፍ፡ '+user.postion}/>}
</ListItem>
 ))
}

{

  products.map((prod)=>(
<ListItem style={{cursor:'pointer'}}>
  <Box component='img' src={`${api.imgAddress}${prod.featureImage}`} style={{width:235}} />
   <ListItem>
   <ListItemText secondary={prod.description} primary={prod.name}/>
   </ListItem>
</ListItem>
))
}

{orgs.length>0?<Typography variant='h6'>ድርጅት</Typography>:''}

{
  
  orgs.map(((org)=>
  <ListItem onClick={()=>{
    link('/detail',{state:{item:org,query:query}})
  }} style={{cursor:'pointer'}}>
  <ListItemAvatar>
  <Avatar style={{width:100,height:100}} src={`${api.imgAddress}/${org.logo}`}/>
  </ListItemAvatar>
  <ListItemText primary={org.name} secondary={org.businessSector}/>
  <ListItemText  secondary={org.email}/>
</ListItem>
))
}

{images.length>0?<Typography variant='h6'>ምስሎች</Typography>:''}
<ListItem>
<Grid container>
{
images.map((img)=>(
  <Grid item sm={3}>
  <Box sx={{width:'100%'}} component='img' src={`${api.imgAddress}/${img}`}/>
</Grid>
))
}
</Grid>

</ListItem>
</List>
</Container>


<Container style={{display:tab == 1?'':'none'}}>
<Grid container>

<ListItem>
<Grid container>
{
images.map((img)=>(
  <Grid item sm={3}>
  <Box sx={{width:'100%'}} component='img' src={`${api.imgAddress}/${img}`}/>
</Grid>
))
}
</Grid>

</ListItem>
</Grid>
</Container>



<Container style={{display:tab == 2?'':'none'}}>
  <List>


{
products.map((prod)=>(
<ListItem style={{cursor:'pointer'}}>
<Box component='img' src={`${api.imgAddress}${prod.featureImage}`} style={{width:235}} />
 <ListItem>
 <ListItemText secondary={prod.description} primary={prod.name}/>
 <ListItemButton><Button variant='contained' startIcon={<Money/>}>ክፍል</Button></ListItemButton>
 </ListItem>
</ListItem>
))
}


  </List>
</Container>

<Container style={{display:tab == 3?'':'none'}}>
  <List>

{
  
  orgs.map(((org)=>
  <ListItem onClick={()=>{
    link('/detail',{state:{item:org,query:query}})
  }} style={{cursor:'pointer'}}>
  <ListItemAvatar>
  <Avatar style={{width:100,height:100}} src={`${api.imgAddress}/${org.logo}`}/>
  </ListItemAvatar>
  <ListItemText primary= {<Typography variant='h4'>{org.name}</Typography>} secondary={org.businessSector}/>
  <ListItemText  secondary={org.email}/>
  <ListItemButton>
  <Button startIcon={<RemoveRedEye/>} onClick={()=>{
    link('detail',{state:{item:org,query:query}})
  }} variant='contained'>እይት</Button>
</ListItemButton>

</ListItem>
))
}


  </List>
</Container>



<Container style={{display:tab == 4?'':'none'}}>
{
  users.map(((user)=>
<ListItem onClick={()=>{ 
  link('/userdetail',{state:{item:user,query:query}})
}} style={{cursor:'pointer'}}>
  <ListItemAvatar>
    <Avatar src={`${api.imgAddress}/${user.avatar}`} />
  </ListItemAvatar>
  <ListItemText primary={user.firstName+' '+user.middleName+' '+user.lastName} secondary={'ኢሜል ፡ '+user.email}/>
  <ListItemText secondary={'ንቁ ቁጥር: '+user.userCode}/>
  <ListItemText secondary={'ፆታ: '+user.sex}/>
  <ListItemText secondary={'ስልክ: '+user.phoneNumber}/>
  {user.postion == null?'':<ListItemText secondary={'የስራ ዘርፍ፡ '+user.postion}/>}
  <ListItemButton>
    <Button onClick={()=>{ 
  link('/userdetail',{state:{item:user,query:query}})
}} startIcon={<RemoveRedEye/>} variant='contained'>እይት</Button>
  </ListItemButton>

</ListItem>
 ))
}

</Container>





    </Container>
    </>
  )
}

export default Result