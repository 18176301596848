import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import not from './assets/R.gif'
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 128,
  },
}));

export default function Error() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Link to='/main'>

      <Box sx={{width:"50%",display:"block",margin:"auto"}} component="img" src={not}/>
      <Button variant="contained" size='large' style={{color:"white"}} color="secondary">ድጋሚ ይሞከሩ</Button>
      </Link>
    </Box>
  );
}
