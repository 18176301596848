// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a.src-TaggedContentCard-styles-module__card-link--f0hYD{display:flex;text-decoration:none}.src-TaggedContentCard-styles-module__tagged-content-card--OZvmV{position:relative;display:flex;flex-flow:column;width:420px;height:350px;background-color:#2c3e50;transition:.5s}.src-TaggedContentCard-styles-module__tagged-content-card--OZvmV:hover{transform:scale(1.1)}", "",{"version":3,"sources":["webpack://./src/TaggedContentCard/styles.module.scss"],"names":[],"mappings":"AAAA,wDACE,YAAA,CACA,oBAAA,CAGF,iEACE,iBAAA,CACA,YAAA,CACA,gBAAA,CAEA,WAAA,CACA,YAAA,CAEA,wBAAA,CACA,cAAA,CAEA,uEACE,oBAAA","sourcesContent":["a.card-link {\n  display: flex;\n  text-decoration: none;\n}\n\n.tagged-content-card {\n  position: relative;\n  display: flex;\n  flex-flow: column;\n\n  width: 420px;\n  height: 350px;\n\n  background-color: #2c3e50;\n  transition: 0.5s;\n  \n  &:hover {\n    transform: scale(1.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-link": "src-TaggedContentCard-styles-module__card-link--f0hYD",
	"tagged-content-card": "src-TaggedContentCard-styles-module__tagged-content-card--OZvmV"
};
export default ___CSS_LOADER_EXPORT___;
