import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Add, AdjustOutlined, BarChart, Handshake, HideImageRounded, Map, Menu, MenuBookRounded, Person2, Storage } from '@mui/icons-material';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Divider, IconButton, Skeleton, Typography } from '@mui/material';
import './hover.css'








export default function SideMenues(props) {
  const userInfo = useSelector((state)=>state.userInfo.user)
  const [open, setOpen] = React.useState(true);
  const [active,setactive] = props.states;
  const [show,setshow] = React.useState(true);
  const [Mn,setMn] = props.mn;
  const [popen,setpopen] = React.useState(false)
  const [toggle,setToggle] = props.toggle
  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(()=>
  {  const org = localStorage.getItem("org");
  if(org == null)
  {}
  else 
    setshow(false);
},[])

  const close = () =>{

    setMn(false)

  }


  React.useEffect(()=>{

    
    

  },[])
  

  return (<>

<IconButton onClick={e=>setToggle(!toggle)}><Menu/></IconButton>

  <List sx={{display:toggle?"none":"block",transition:"ease-in-out 0.3s", bgcolor:"#ffffff",borderRadius:5,marginTop:3,outline:"solid 2px #0099ff",border:"solid 5px #00000000",boxShadow:"1px 2px 13px 2px #0099ff", minHeight:"60vh",  width: {xs:"44vh",sm:"90%",md:'90%',lg:'90%',xl:'90%'}, maxWidth: 360, }}>
    
    <Link to="/main" onClick={e=>{setactive(1);}}>
<ListItemButton>
<ListItemIcon>
<Person2  style={active==1?{color:"#0099ff"}:{}} sx={{fontSize:'40px',margin:'auto'}} />
</ListItemIcon>
</ListItemButton>
</Link>


<Link to="/main" onClick={e=>{setactive(5);}}>
<ListItemButton>
<ListItemIcon>
<Storage  style={active==1?{color:"#0099ff"}:{}} sx={{fontSize:'40px',margin:'auto'}} />
</ListItemIcon>
</ListItemButton>
</Link>


<Link to="/main" onClick={e=>{setactive(7);}}>
<ListItemButton>
<ListItemIcon>
<Map  style={active==1?{color:"#0099ff"}:{}} sx={{fontSize:'40px',margin:'auto'}} />
</ListItemIcon>
</ListItemButton>
</Link>

<Link to="/main/org/0"  onClick={e=>{setOpen(true);setactive(2); close()}}>
<ListItemButton>
<ListItemIcon>
<AssuredWorkloadIcon  style={active==1?{color:"#0099ff"}:{}} sx={{fontSize:'40px',margin:'auto'}} />
</ListItemIcon>
</ListItemButton>
</Link>

<Link to="/main" onClick={e=>{setactive(3);}}>
<ListItemButton>
<ListItemIcon>
<LocalMallIcon  style={active==1?{color:"#0099ff"}:{}} sx={{fontSize:'40px',margin:'auto'}} />
</ListItemIcon>
</ListItemButton>
</Link>


<Link to="/main" onClick={e=>{setactive(4);}}>
<ListItemButton>
<ListItemIcon>
<AdjustOutlined  style={active==1?{color:"#0099ff"}:{}} sx={{fontSize:'40px',margin:'auto'}} />
</ListItemIcon>
</ListItemButton>
</Link>


<Link to="/main" onClick={e=>{setactive(6);}}>
<ListItemButton>
<ListItemIcon>
<BarChart  style={active==1?{color:"#0099ff"}:{}} sx={{fontSize:'40px',margin:'auto'}} />
</ListItemIcon>
</ListItemButton>
</Link>




  </List>
    <List
    style={{
      borderTopRightRadius:0,
      display:toggle?"block":"none"
    }}
      sx={{ bgcolor:"#ffffff",borderRadius:5,marginTop:3,outline:"solid 2px #0099ff",border:"solid 5px #00000000",boxShadow:"1px 2px 13px 2px #0099ff", minHeight:"60vh",  width: {xs:"44vw",sm:"44vW",md:'90%',lg:'90%',xl:'90%'}, maxWidth: 360, }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >



              <ListItemButton sx={{bgcolor:"transparent",fontWeight:"900"}} component="div" id="nested-list-subheader">
           <Typography variant='body1'>
           </Typography>
        </ListItemButton>

        <Link to="/main" onClick={e=>{setactive(1);}}>
      <ListItemButton className={'active'} sx={active ==1?{backgroundColor: '#0099ff' ,color: 'white'}:{}}>
        <ListItemIcon>
          <Person2 style={active==1?{color:"white",}:{}} />
        </ListItemIcon>
        <ListItemText style={active==1?{color:"white",}:{}} primary={<Typography variant='h6'>የኔ መረጃ</Typography>} />
      </ListItemButton>
      </Link>
<Divider />
      <Link to="/main" onClick={e=>{setactive(5);}}>
      <ListItemButton className={active ==5?'active':'deactive'}  sx={active ==5?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <Storage/>
        </ListItemIcon>
        <ListItemText style={active==5?{color:"white"}:{}} primary={<Typography variant='h6'>ማስቀመጫ</Typography>} />
      </ListItemButton>
      </Link>
<Divider />
      <Link to="/main" onClick={e=>{setactive(7);}}>
      <ListItemButton className={active ==7?'active':'deactive'}  sx={active ==7?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <Map/>
        </ListItemIcon>
        <ListItemText style={active==7?{color:"white"}:{}} primary={<Typography variant='h6'>ካርታ</Typography>} />
      </ListItemButton>
      </Link>
<Divider/>
{show?     <>   <Link to="/main/org/0"  onClick={e=>{setOpen(true);setactive(2); close()}}>
      <ListItemButton className={active ==2?'active':'deactive'}  sx={active ==2?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <AssuredWorkloadIcon style={active==2?{color:"white"}:{}} />
        </ListItemIcon>
        <ListItemText style={active==2?{color:"white"}:{}} primary={<Typography variant='h6'>የድርጅት ማስተዳደርያ</Typography>} />
      </ListItemButton>
      </Link>
      
      
      <Collapse in={open} timeout="auto" unmountOnExit>
<List component="div" disablePadding>
<Link to="/main/org/0"  onClick={e=>{setactive(2); close();}}>
  <ListItemButton className={active ==2?'active':'deactive'}   sx={{ pl: 4 }}>
    <ListItemIcon>
      <Handshake/>
    </ListItemIcon>
    <ListItemText primary="የኔ ድርጅት" />
  </ListItemButton>
  </Link>
  <Link to="/main/org/1"  onClick={e=>{setactive(2); close()}}>
  <ListItemButton className={active ==1?'active':'deactive'}  sx={{ pl: 4 }}>
    <ListItemIcon>
      <Add />
    </ListItemIcon>
    <ListItemText primary="አዲስ ድርጅት" />
  </ListItemButton>
  </Link>
</List>
</Collapse>
</>
:""}

<Divider/> 

<Link to="/main"  onClick={e=>{setactive(3); close();setpopen(true)}}>
      <ListItemButton className={active ==3?'active':'deactive'}  sx={active ==3?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <LocalMallIcon style={active==3?{color:"white"}:{}} />
        </ListItemIcon>
        <ListItemText style={active==3?{color:"white"}:{}} primary={<Typography variant='h6'>የምርት ማስተዳደርያ</Typography>} />
      </ListItemButton>
      </Link>

<Divider/> 

      <Link to="/main"  onClick={e=>{setactive(4); close();setpopen(true)}}>
      <ListItemButton className={active ==4?'active':'deactive'}  sx={active ==4?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <AdjustOutlined style={active==4?{color:"white"}:{}} />
        </ListItemIcon>
        <ListItemText style={active==4?{color:"white"}:{}} primary={<Typography variant='h6'>የማስታወቅያ ማስተዳደርያ</Typography>} />
      </ListItemButton>
      </Link>      
<Divider/> 
      <Link to="/main"  onClick={e=>{ setactive(6); close();setpopen(true)}}>
      <ListItemButton className={active ==6?'active':'deactive'}  sx={active ==6?{backgroundColor: '#0099ff',color: 'white'}:{}}>
        <ListItemIcon>
          <BarChart style={active==6?{color:"white"}:{}} />
        </ListItemIcon>
        <ListItemText style={active==6?{color:"white"}:{}} primary={<Typography variant='h6'>አክሲዮን ማስተዳደርያ</Typography>} />
      </ListItemButton>
      </Link>
<Divider/> 
    </List>
</>
  );
}