// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-PaymentCard-PaymentCardNumber-styles-module__payment-card-number--BQFsw{position:relative;z-index:10;display:flex;align-items:center;flex-flow:column;justify-content:center;margin-top:1rem;letter-spacing:.4px;color:#ecf0f1;font-family:\"OCR A Std\";font-size:21px;opacity:.85;text-shadow:0px 1px 3px rgba(44,62,80,.7)}", "",{"version":3,"sources":["webpack://./src/PaymentCard/PaymentCardNumber/styles.module.scss"],"names":[],"mappings":"AAAA,6EACE,iBAAA,CACA,UAAA,CAEA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,sBAAA,CACA,eAAA,CAEA,mBAAA,CAEA,aAAA,CAEA,uBAAA,CACA,cAAA,CACA,WAAA,CACA,yCAAA","sourcesContent":[".payment-card-number {\n  position: relative;\n  z-index: 10;\n\n  display: flex;\n  align-items: center;\n  flex-flow: column;\n  justify-content: center;\n  margin-top: 1rem;\n\n  letter-spacing: 0.4px;\n\n  color: #ecf0f1;\n\n  font-family: 'OCR A Std';\n  font-size: 21px;\n  opacity: 0.85;\n  text-shadow: 0px 1px 3px rgba(#2c3e50, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-card-number": "src-PaymentCard-PaymentCardNumber-styles-module__payment-card-number--BQFsw"
};
export default ___CSS_LOADER_EXPORT___;
