import * as React from 'react';
import api from './data/api';
import { Button,Box, Container, Input, TextField, Typography, IconButton, ButtonGroup } from '@mui/material';
import { AdUnits, LocationOn, Money, Search, SearchSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
const style = {center:{
margin: 'auto',
display: 'block'
}}
export default function Show(props) {

  const [query,setQuery] = React.useState('')
  const link = useNavigate();
 

  return (
<>
<Container>
  <Box>
    <Box sx={{width:'162px',paddingTop:25,...style.center}} component='img' src={require('./assets/circle.png')} /> <br/>
    <Typography style={{textAlign:'center',...style.center}} variant='h4'>ኢጵላሣጵ</Typography>
<div style={{display:'flex',margin: 'auto',justifyContent:'center'}}>

    <input
    
    value={query}
    onChange={e=>setQuery(e.target.value)}
    style={{
display: 'block',
width: '58%',
padding: '15px',
borderRadius: '26px',
border: '1px solid darkgray',
boxShadow: 'darkgray 0px 2px 10px -4px',
textAlign: 'center',
fontSize: '15px',
borderTopRightRadius: '0',
borderBottomRightRadius: '0',
borderRight: 'none',
marginRight:0
}} 
placeholder='ፈልግ'
/>
<button

onClick={()=>{

  if(query == '' || query === '')
  { 
    console.log("empty_query")
  }
  else 
  {
      link('result',{state:{query:query}})    

  }


}}


style={{
  cursor:'pointer',
  border: '1px solid darkgray',
  borderRadius: '26px',
  borderTopLeftRadius: '0',
borderBottomLeftRadius: '0',
width:'86px'
}}
><SearchSharp/></button>
</div>

<ButtonGroup style={{marginInline: 'auto',
display: 'flex',
justifyContent: 'center',
marginTop: '58px'}}>
<Button onClick={()=>{window.location = 'https://www.eplusapp.et'}} startIcon={<AdUnits/>} variant='outlined'>ማስታዎቅያ</Button>
<Button onClick={()=>{window.location = 'https://www.eplusapp.et'}} startIcon={<LocationOn/>} variant='outlined'>አድራሻ</Button>
<Button onClick={()=>{window.location = 'https://www.eplusapp.et'}} startIcon={<Money/>} variant='outlined'>ገባያ</Button>
</ButtonGroup>
  </Box>
  </Container> 
</>
);
}
