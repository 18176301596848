import {Grid,Checkbox, Box, Container, Typography, Card, CardContent, TextField, FormGroup, FormControlLabel, Divider, Button, CardActions, Modal, List, ListItem, ListItemIcon, ListItemAvatar, ListItemText, Avatar, ListItemButton, ButtonGroup, IconButton, Snackbar, CardHeader, CardActionArea, Switch, Alert, Collapse } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import JoditEditor,{Jodit} from 'jodit-react';
import { Add, AddAPhotoRounded, AddAlertRounded, AddBox, AddBoxRounded, AddCircle, AdminPanelSettings, ArrowBack, AudioFile, AudioFileSharp, CalendarMonth, Camera, Cancel, CardTravelRounded, ClearAll, Dangerous, Delete, DeleteForever, Done, DoneAll, Edit, Handshake, HotelSharp, Info, Numbers, Person, Person2Rounded, PhotoCamera, PhotoCameraRounded, PlusOne, ProductionQuantityLimitsOutlined, Sell, Send, SendRounded, SendSharp, TextFields, Unarchive, UpdateOutlined, UploadFile, VideoCallRounded } from '@mui/icons-material';
import {Select,FormControl,InputLabel,MenuItem} from '@mui/material';
import axios from 'axios';
import api from '../data/api';
import { useSelector } from 'react-redux';
import SnackBar from '../components/SnackBar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Producs = (props) => {
    const editor = useRef()
    const [active,setactive] = props.states
    const [tempImg,setTempImg] = useState({show:false,img:''})
    const [forms,setForms] = useState({show:false,forms:{
      name:false,
      brand:false,
      model:false,
      size:false,
      thickness:false,
      engine:false,
      process_time:false,
      galleryImage:false,
      weight:false,
      version:false,
      ram:false,
      generation:false,
      material:false,
      quality:false,
      price:false,
      featureImage:false,
      audio:false,
      video:false,
      description:false,
      categoryId:false
    }})
const [formsVal,setVal] = useState({})
    const [catagoris,setCatag] = useState([])
    const [showModal,setModal] = useState(false)
    const [desc,setDesc] = useState("")
    const token = useSelector(state=>state.logedUser)
    const [product_detail,setDetail] = useState({show:false,productId:null})
    const [dynamicF,setDynamic] = useState([{name:'',type:'Nan'}])
    const [memory,setMemory] = useState({name:'',type:'Nan'})
    const [product,setProduct] = useState({name:'',categoryId:'',brand:'',featureImage:'',description:''})
    const [spec,setSpec] = useState([])
    const [userInfo,setUserInfo] = useState({})
    const FetchUserInfo = useSelector(state=>state.userInfo)
    const [sellBy,setSellBy] = useState({sell:null,id:null})
    const user = useSelector(state=>state.logedUser)
    const [orgsList,setOrgsList] = useState([])
    const [id,setid] = useState(0)
    const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
    const fileUp = useRef()
    const fileUp1 = useRef()
    const fileUp2 = useRef()
    const fileUp3 = useRef()
    const [ownProducts,setOwnProducts] = useState([])
    const [editView,setEditView] = useState(true)
    const [updateOwn,setupdateOwn] = useState(false)
    const [memoryUpdate,setMemoryUpdate] = useState({})
    const [deletePr,setDeletePr] = useState(false)
    const [ids,setIds] = useState([])
    const [sendView,setSendView] = useState(false)
    const [noAvlableProduct,setAvlableProduct] = useState(true)
    const [productImagePath,setImgPath] = useState();
    const [productImg,setProductImg] = useState('')
    const [previewUpload,setPreviewUpload] = useState(null)
    const [productPreview,setProductPreview] = useState(``)
    const [productPreviewId,setProductPreviewId] = useState()




    useEffect(()=>{

      (async ()=>{

        
        await axios.get(`${api.address}/addressapi/images/fetch-image?productId=${product_detail.data?.id}`,{
          headers: {
            'Authorization': `Bearer ${user.user}`
          }  
        })
        .then((result) => {
          let data = result.data?.imagePath
          setProductPreviewId(result.data?.id) 
          console.info(`${api.imgAddress}/${data}`)
          setProductPreview(`${api.imgAddress}/${data}`)
        }).catch((err) => {
          console.info(err)
        });

      })()

    },[product_detail])


  const  makeAvb = (e,id) =>{

    let val = e.target.checked
    if(val)
   setIds([...ids,id])
   else 
   {
    let i = ids.indexOf(id)
    ids.splice(i,1)

   }

  }

    useEffect(()=>{

      setUserInfo(FetchUserInfo.user)

      
    },[FetchUserInfo])

    useEffect(()=>{

    (async()=>{

      await axios.get(`${api.address}/addressapi/category/all`)
      .then((result) => {
        setCatag(result.data)
      }).catch((err) => {

        console.log(err)

      });

      await axios.get(`${api.address}/addressapi/organizations/fetchownorgs/`,{
        headers: {
            'Authorization': `Bearer ${user.user}`
          }   
    }).then((resp)=>{
      
      setOrgsList(resp.data)
      
    })
    .catch((er)=>{
      console.log(er)
    })
 

    await axios.get(`${api.address}/addressapi/products/fetchownproducts`,{
      headers: {
        'Authorization': `Bearer ${user.user}`
      }   
    })
    .then((result) => {
      console.warn(result.data)
      setOwnProducts(result.data)
    }).catch((err) => {
console.warn(err)      
    });




    })()
      
    },[])

    useEffect(()=>{


      (async ()=>{
        
    await axios.get(`${api.address}/addressapi/products/fetchownproducts`,{
      headers: {
        'Authorization': `Bearer ${user.user}`
      }   
    })
    .then((result) => {
      console.warn(result.data)
      setOwnProducts(result.data)
    }).catch((err) => {
console.warn(err)      
    });

      })()
   
   
   
    },[updateOwn])


    
    const handleClose = () => setModal(false)
    const handleChange = e =>{
      setForms({...forms,forms:{...forms.forms,[e.target.name]:e.target.checked}})      
    }
    const handleValue = e =>{
 
    
      setProduct({...product,[e.target.name]:e.target.value})
      
    }
    const handleValue1 = e =>{

      console.debug(e.target.value)

      setSpec([...spec,{name:e.target.name,value:e.target.value}])
      
    }


    const updateInfo = async (e)=>{

      let update = {[e.target.name]:e.target.value}
      console.log(update)
      await axios.put(`${api.address}/addressapi/products/${product_detail.data.id}`,update,{
        headers: {
          'Authorization': `Bearer ${user.user}`
        }
      })
      .then((result) => {
        product_detail.data = result.data
        setDetail({...product_detail,data:result.data})
        setsnack({Type:'info',msg:'ይስገቡት መረጃ በትክክል ተስተካክልዋል!',show:true})
      setupdateOwn(!updateOwn)
      

      }).catch((err) => {
        
      });


    }
    
    const setUpdateMemo = (e)=>{

      console.log(product_detail.data[e.target.name])
      product_detail.data[e.target.name] = e.target.value

    }
    
    const Joditsub = ()=>{

      setVal({...formsVal,...{description:editor.current?.text}})

    }


    

    const Submit = ()=>{
      
      
      let dataPacket = {}
      if(sellBy?.sell == 'user')
      {

      }
      else if(sellBy?.sell == 'org')
      {
        dataPacket = {...dataPacket,orgId:sellBy.id}
      }
      dataPacket = {...dataPacket,...product,specification:JSON.stringify(spec)}

      dataPacket.price = parseInt(dataPacket?.price)
      dataPacket.quantity  = parseInt(dataPacket.quantity)
      if(dataPacket.name != '' && dataPacket.featureImage != '' && dataPacket.categoryId != '' && dataPacket.quantity != '' && dataPacket.price != '')
      {
        
        axios.post(`${api.address}/addressapi/products/add`,dataPacket,{
        headers: {
          'Authorization': `Bearer ${user.user}`
        }
      })
      .then(async (result) => {
        console.info(result?.data.id)
        let firstResult = result?.data;
        let fd = new FormData();
        fd.append('featureImage',fileUp.current.files[0])
        fd.append('productId',result.data?.id)
        await axios.post(`${api.address}/addressapi/files/uploadfile`,fd,{
          headers:{
            'Authorization': `Bearer ${user.user}`
          }
        })
        .then((result) => {
          alert("Success")
          setOwnProducts([...ownProducts,firstResult]);
          setProduct({name:'',categoryId:'',brand:'',featureImage:'',description:''})
        }).catch((err) => {
          console.log(err)
        });

        setsnack({Type:'info',msg:"መረጃው በተሳካ ሁኔታ ገብትዋል!",show:true})
        setPreviewUpload(null)




      }).catch((err) => {
        
        console.info(err)
        setsnack({Type:'error',msg:err.response?.data?.message,show:true})

      })

        
      }
      else 
      {
        setsnack({Type:'info',msg:'ሁሉንም ምጠይቆች ያሟሉ',show:true})
      }

    }

    const {name,brand,model,size,thickness,engine,process_time,galleryImage,weight,version,ram,generation,material,quality,price,featureImage,audio,video,description,categoryId} = forms.forms


    const buttons = ["  ስለ ምርቱ ገለፃ      ","\n","bold","brush","italic","paragraph","underline","left","right","center","font","fontsize"]


    const config = {
        buttonsMD:buttons,
        buttonsSM:buttons,
        buttonsXS:buttons,
        allowResizeX:false,
        "showCharsCounter": false,
        "showWordsCounter": false,
        "showXPathInStatusbar": false
    }

    return (<>

<SnackBar snack={snack} />

  {product_detail.show?
  <Box sx={{display:active ==3?"block":"none"}} style={{padding:35}}>

<Grid container>
<IconButton style={{height: 'fit-content'}} onClick={e=>setDetail({show:false})}><ArrowBack style={{fontSize:45}}/></IconButton>
{/* <Button onClick={e=>setDetail({show:false})} startIcon={<ArrowBack/>}/> */}

<Grid item xl={8} lg={8} sm={12} xs={12} md={8} >

{editView?  <Box style={{width: '326px',
borderRadius: '8px',
boxShadow: '3px 4px 5px 0px',
marginBottom:10}} component='img' src={productPreview} />:<>
<Box style={{width: '326px',
borderRadius: '8px',
boxShadow: '3px 4px 5px 0px',
marginBottom:10}} component='img' src={productPreview} />

</>}

<IconButton  onClick={()=>{
fileUp3.current.click()
}} style={{margin: '15px',
position: 'relative',
right: '6rem',
bottom: '2rem'}}>
<PhotoCameraRounded style={{fontSize:50}}/>
</IconButton>

<input style={{display:'none'}} type='file' ref={fileUp3} onChange={async e=>{

let file = e.target.files[0]
let fd = new FormData();
fd.append('featureImage',file)
fd.append(`productId`,productPreviewId)
await axios.put(`${api.address}/addressapi/files/${productPreviewId}`,fd,{
  headers: {
    'Authorization': `Bearer ${user.user}`
  } 
})
.then(async (result) => {

  console.info(result.data)
  await axios.get(`${api.address}/addressapi/images/fetch-image?productId=${product_detail.data?.id}`,{
    headers: {
      'Authorization': `Bearer ${user.user}`
    }  
  })
  .then((result) => {
    let data = result.data?.imagePath
    setProductPreviewId(result.data?.id) 
    console.info(`${api.imgAddress}/${data}`)
    setProductPreview(`${api.imgAddress}/${data}`)
  }).catch((err) => {
    console.info(err)
  });

}).catch((err) => {
  
  console.log(err)
});





}} />
<hr />
<div style={{display:'flex'}}>
<Switch checked={product_detail.data.forSale} 
onChange={ e=>setDetail({...product_detail,data:{...product_detail.data,forSale:e.target.checked}}) }
 onBlur={async e=>{
  
  await axios.put(`${api.address}/addressapi/products/${product_detail.data.id}`,{forSale:e.target.checked},{
    headers: {
      'Authorization': `Bearer ${user.user}`
    } 
  })
.then((result) => {
  console.log(result)
}).catch((err) => {
  console.log(err)
});

setupdateOwn(!updateOwn)

 }}
 />  <Typography  variant='h6'>{`ምርቱ ለገባያ ${product_detail.data.forSale?'ውልዋል':'አልዋለም'}!`} </Typography>



</div><hr />
<div style={{display:'flex'}}>
<Switch checked={product_detail.data.forPromotion} 
onChange={ e=>setDetail({...product_detail,data:{...product_detail.data,forPromotion:e.target.checked}})}
 onBlur={async e=>{
  await axios.put(`${api.address}/addressapi/products/${product_detail.data.id}`,{forPromotion:e.target.checked},{
    headers: {
      'Authorization': `Bearer ${user.user}`
    } 
  })
.then((result) => {
  console.log(result)
}).catch((err) => {
  console.log(err)
});

setupdateOwn(!updateOwn)
 }}
 />  <Typography  variant='h6'>{`ምርቱ ማስታዎቅያ ላይ ${product_detail.data.forPromotion?'ተዋውቋል':'አልተዋወቀም'}!`} </Typography>



</div>

<hr />
<div>{editView?<Typography variant='h4'>{product_detail.data.name}</Typography>
:<TextField
name='name'
value={product_detail.data.name}
label={<Typography variant='h5'>{'ስም'}</Typography>}
onBlur={updateInfo}
onChange={ e=>setDetail({...product_detail,data:{...product_detail.data,name:e.target.value}}) }
/>}</div>
<div style={{margin:10}}>{editView?<Typography variant='body1'>
{
  product_detail.data.description
}</Typography>:
<TextField
onChange={ e=>setDetail({...product_detail,data:{...product_detail.data,description:e.target.value}}) }
onBlur={updateInfo}
style={{width:'100%'}}
value={product_detail.data.description}
multiline
name='description'
rows={6}
label={<Typography variant='h5'>{'ገለፃ'}</Typography>}
/>}</div>
<List>
  <ListItem>
  <ListItemText secondary={<>{editView?<Typography variant='h6'>{`ዋጋ ${product_detail.data.price} `}</Typography>
:  <TextField
  name='price'
  onBlur={updateInfo}
  value={product_detail.data.price}
  label={<Typography variant='h5'>{'ዋጋ'}</Typography>}
  type='number'
  onChange={ e=>setDetail({...product_detail,data:{...product_detail.data,price:e.target.value}}) }

  />}
  </>}  />
  <ListItemText secondary={<>{editView?<Typography variant='h6'>{`ሞዴል ${product_detail.data.model}`}</Typography>
  :<TextField
  name='model'
  onBlur={updateInfo}
  value={product_detail.data.model}
  label={<Typography variant='h5'>{'ሞዴል'}</Typography>}
  onChange={ e=>setDetail({...product_detail,data:{...product_detail.data,model:e.target.value}}) }
  />}
  </>}  />
  <ListItemText secondary={<>{editView?<Typography variant='h6'>{`የምርት አይነት ${catagoris.map(cat=>{
    if(cat.id == product_detail.data.categoryId)
    return cat.categoryName
  })} `} </Typography>
  :
  <Select value={product_detail.data.categoryId}
  label="ይምርት አይነት"
  name='categoryId'
  onBlur={updateInfo}
  onChange={ e=>setDetail({...product_detail,data:{...product_detail.data,categoryId:e.target.value}}) }

  >
    {
      catagoris.map((cat)=>(
        <MenuItem value={cat.id} key={cat.id}>{cat.categoryName}</MenuItem>
      ))
    }
  </Select>
}
  
  </>} />
  </ListItem>
  <ListItem>
  <ListItemText secondary={<>{editView?<Typography variant='h6'>{`ብዛት ${product_detail.data.quantity}`}</Typography>
  :<TextField
  value={product_detail.data.quantity}
  type='number'
  name='quantity'
  onBlur={updateInfo}
  onChange={ e=>setDetail({...product_detail,data:{...product_detail.data,quantity:e.target.value}}) }
  label={<Typography variant='h5'>{'ብዛት'}</Typography>}
  />}
  </>}  />
</ListItem>
<ListItem>
{
  product_detail.data.specification != "{}" ||  product_detail.data.specification != "[]" ||  product_detail.data.specification != "" ?
  JSON.parse(product_detail.data.specification).map((value,index)=>{
  return (
    <ListItemText secondary={<>{ editView?<Typography variant='h6'>{`${value.name}  ${value.value}`}</Typography>
  :  <TextField value={value.value}  onChange={e=>{
     let allValue = JSON.parse(product_detail.data.specification);
   let newValue =  JSON.parse(product_detail.data.specification)[index]
   newValue.value = e.target.value != ''?e.target.value:'_'
   allValue[index] = newValue
   setDetail({...product_detail,data:{...product_detail.data,specification:JSON.stringify(allValue)}})
  }} 

onBlur={async e=>{

await axios.put(`${api.address}/addressapi/products/${product_detail.data.id}`,{specification:product_detail.data.specification},{
  headers: {
    'Authorization': `Bearer ${user.user}`
  }   

})
.then((result) => {
  setsnack({Type:'info',msg:'ይስገቡት መረጃ በትክክል ተስተካክልዋል!',show:true})
  console.debug(result)
}).catch((err) => {
  setsnack({Type:'error',msg:'የሆነ ችግር አለ!',show:true})
  console.debug(err)
  
});


}}


  name='specification' label={<Typography variant='h5'>{value.name}</Typography>}/>
    }</>}  />    
  )
}):''

}
  <ListItemText secondary={<Typography variant='h6'>{``}</Typography>}  />
  </ListItem>
  <ListItem>
  <ListItemText secondary={<>{editView?
<video controls src={`${api.videos}${product_detail.data.video}`}/>
:''}</>}  />
  </ListItem>
  <ListItem>
  <ListItemText secondary={<>{editView?
<audio controls src={`${api.audios}${product_detail.data.audio}`}/>:''
}</>}  />
  </ListItem>


</List>
<Box>
<List>
  <ListItem>
  <ListItemButton>
  {!editView?<>
    <Button onClick={e=>setEditView(true)} variant='contained' color='success' startIcon={<Done/>}>መመለስ</Button>   
  </>:<><Button onClick={e=>setEditView(false)} variant='contained' startIcon={<Edit/>} color='warning'>ማስተካከል</Button> &nbsp;
  <Button variant='contained' startIcon={<Delete/>} onClick={e=>setDeletePr(true)} color='error'>ማጥፋት</Button> &nbsp;

{/* if(ask)
{

await axios.delete(`${api.address}/addressapi/products/${product_detail.data.id}`,{
  headers: {
    'Authorization': `Bearer ${user.user}`
  }   
}) */}
{deletePr?<Box style={{
  width:'100%',
  height:'100%',
  position:'fixed',
  top:0,
  left:0,
  backgroundColor:'#1e1d1cd9',
  zIndex:10,
}}>
<Card style={{margin:'auto',width:'40%',marginTop:'10%'}}>
  <CardHeader style={{background:'#ebebebd9'}} title={<Box style={{display: 'flex'}}><Dangerous style={{marginRight:'10%'}} color='error'/><Typography variant='h5' color='error'>እርግጠኛ ነዎት!</Typography></Box>}/>
  <CardContent>
    <Typography variant='h6'>እርግጠኛ ነዎት {product_detail.data.name} ምርት ከ ቋት ማጥፋት ይፈልጋሉ?</Typography>
  </CardContent>
  <CardActions>
  <CardActionArea>
    <Button variant='contained' color='error' onClick={ async e=>{
  await axios.delete(`${api.address}/addressapi/products/${product_detail.data.id}`,{
    headers: {
      'Authorization': `Bearer ${user.user}`
    }   
  })    
  .then((result) => {
    setDeletePr(false)
    setDetail({show:false,data:{}})
    setupdateOwn(!updateOwn)
    
    
  }).catch((err) => {
    console.log(err)    
  });
    }} startIcon={<DeleteForever/>}>&nbsp;&nbsp;&nbsp;አወ&nbsp;&nbsp;&nbsp;</Button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <Button variant='contained' onClick={e=>{setDeletePr(false)}} color='success' startIcon={<Done/>}>አደለሁ፣ም</Button>
    </CardActionArea>
  </CardActions>
</Card>
</Box>:''
}




  </>
  }
 </ListItemButton>
  </ListItem>
  <Divider/>
  <ListItem>
    <ListItemText primary={<Typography>የመውደድ ብዛት {product_detail.data.likes}</Typography>} />
  </ListItem>
  <Divider/>
  <ListItem>
    <ListItemText primary={<Typography variant='body1'>የተሰጡ ኣሰተያየቶች</Typography>} />
  </ListItem>
  <Collapse>
 <ListItem>
  <ListItemAvatar>
    <Avatar/>
  </ListItemAvatar>
  <ListItemText primary={<Typography>unknown</Typography>} />
  <ListItemText secondary={<Typography>Nice product</Typography>} />
  </ListItem>   
    </Collapse>
 
  </List>
</Box>
</Grid>
<Grid item xl={3} lg={3} sm={12} xs={12} md={3} >
<hr />
<Typography>የምርቶች ዝርዝር</Typography>
<hr />
<List>



{
  ownProducts.map((prod)=>{
    if(true)
    return ( 
<ListItem onClick={()=>setDetail({show:true,data:prod})} style={{cursor:'pointer'}}>
<ListItemAvatar>
    </ListItemAvatar>
    <ListItemText primary={prod.name}/>
    <ListItemText secondary={'$ '+prod.price+' Birr'}/>
  </ListItem>
  )
  else
  return(<></>)
  })
}






</List>
</Grid>

</Grid>


  </Box>
  :
    <Box sx={{display:active ==3?"block":"none"}}>
      <Typography sx={{padding:3}} color='GrayText' variant='h4'>የምርት ማስገብያ እና ማስተዳደርያ ገፅ</Typography>
      <Modal                                 
  open={showModal}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
    <AddBoxRounded style={{position:'relative',top: '8px'}}/>  መለክያዎችን ይጨምሩ
    </Typography>
    {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>

    <FormControlLabel control={<Checkbox checked={brand} onChange={handleChange} name="brand" />} label="የምርት ብራንድ" />
    <FormControlLabel control={<Checkbox checked={galleryImage} onChange={handleChange} name="galleryImage" />} label="የምርት ምስል" />
    <FormControlLabel control={<Checkbox checked={price} onChange={handleChange} name="price" />} label="የምርት ዋጋ" />
    <FormControlLabel control={<Checkbox checked={featureImage} onChange={handleChange} name="featureImage" />} label="የምርት ምስል" />
    <FormControlLabel control={<Checkbox checked={audio} onChange={handleChange} name="audio" />} label="የምርት ድምፅ" />
    <FormControlLabel control={<Checkbox checked={video} onChange={handleChange} name="video" />} label="የምርት ተንቀሳቃሽ ምስል" />
    <FormControlLabel control={<Checkbox checked={description} onChange={handleChange} name="description" />} label="የምርት ገለፃ" />
    <FormControlLabel control={<Checkbox checked={categoryId} onChange={handleChange} name="categoryId" />} label="የምርት ምድብ" />
    </Typography> */}
    <br />
    <ButtonGroup>
    <TextField onChange={e=>setMemory({...memory,name:e.target.value})} value={dynamicF.name} placeholder='የመረጃው ስም' /> 
    <Select onChange={e=>setMemory({...memory,type:e.target.value})} value={memory.type}  placeholder='የመረጃው አይነት'>
    <MenuItem value='Nan'>የመርጃው አይነት</MenuItem>
    <MenuItem value='text'><TextFields/><Typography variant='body1'>ፀሁፍ</Typography> </MenuItem>
    <MenuItem value='number'><Numbers/>ቁጥር</MenuItem> 
    <MenuItem value='date'><CalendarMonth/> ቀን</MenuItem> 
    <MenuItem></MenuItem>
    </Select>
    </ButtonGroup>
    <div>

    </div>
    <br /><br />    <br />
    <Button variant='contained' onClick={()=>{
  if(memory.name != '' && (memory.type != '' || memory.type != 'Nan'))
  {
    setDynamic([...dynamicF,memory])
    console.log(dynamicF)
    setMemory({name:'',type:'Nan'})
    handleClose()
  }  
  }}>ጨርስ</Button>
  </Box>
</Modal>

    <Box style={{padding: 20}}>
    
     <Grid container spacing={1}>
     <Grid item lg={8}>

<Typography variant='body2' color='GrayText' style={{marginBottom:10}}>
 <Handshake style={{position:'relative',top: '8px'}}/> በእርስዎ ወይም በድርጅቶ እሚመረት ምርት ካለ ለገባያ በ ኢጵላሳጵ መገበያያ በኩል መሸጥ ከፈለጉ ያስገቡ!
</Typography>
     <br/><br/>
     <Typography  variant='h5'><Info style={{position:'relative',top: '4px'}}/> አስፈላጊ መረጃዎች</Typography>
     <hr />
     <Box>
     <Button variant='outlined' size='large' startIcon={<PhotoCamera/>} onClick={e=>{fileUp.current.click();}}>
     {tempImg.show && product.featureImage !=''?<Box component='img' src={tempImg.img} style={{width:'200px'}}/>:''}
       {product.featureImage !=''?product.featureImage:'የምርት ምስል'}</Button>
      <input style={{display:'none'}} type='file' ref={fileUp} onChange={async e=>{
        let file = e.target.files[0]
        // let formData = new FormData();
        // formData.append('featureImage',e.target.files[0])
        setProduct({...product,featureImage:file.name})
        let fr = new FileReader();
        fr.readAsDataURL(file)
        fr.addEventListener('load',()=>{
          setPreviewUpload(fr.result)
        })
        // await axios.post(`${api.address}/addressapi/files/uploadfile`,formData)
        // .then(resp=>{
        //   setProduct({...product,featureImage:resp.data?.featureImage[0].filename})
        //   let fR = new FileReader();
        //   fR.onload = function(evt){
        //     setTempImg({show:true,img:evt.target.result})
        //   }
        //   fR.readAsDataURL(file)
        // })
        // .catch(err=>{
        //   console.error(err)
        // })

      }} />
      <Box sx={{width: '123px',
height: '128px'}} component="img" src={previewUpload} />
      <br/><br/>

      <TextField name='name' onChange={handleValue} value={product.name} id="standard-b1asic" style={{margin:5}} label="የምርት ስም" variant="filled" />
      <TextField name='brand' onChange={handleValue} value={product.brand} id="standar12d-basic" label="የምርት ብራንድ" style={{margin:5}} variant="filled" />
      <TextField name='model' onChange={handleValue} value={product.model} id="standard-454basic" label="የምርት ሞዴል" style={{margin:5}} variant="filled" />
      <TextField  name='price' onChange={handleValue} value={product.price} type='number' id="5456standard-basic" style={{margin:5}} label="የምርት ዋጋ" variant="filled" />
      <TextField  name='quantity' onChange={handleValue} type='number' value={product.quantity} id="st7878andard-basic" style={{margin:5}} label="የምርት ብዛት" variant="filled" />
      <br />
<Card style={{padding:10,display:'flex',justifyContent:'center',flexDirection:'column'}}>
<Button variant='outlined' size='large' startIcon={<AudioFileSharp/>} onClick={e=>{fileUp1.current.click();}}> {product.audio !=null?product.audio:'የምርት መረጃ በድምፅ'}</Button>
      <input key={Math.random()} style={{display:'none'}} type='file' ref={fileUp1} onChange={async e=>{

        console.debug(e.target.files[0])
        let file = e.target.files[0]
        let formData = new FormData();
        formData.append('audio',e.target.files[0])
        setProduct({...product,audio:'Loading....'})
        let audio = await axios.post(`${api.address}/addressapi/files/uploadfile`,formData)
        .then(resp=>{
          console.debug(resp.data)
          setProduct({...product,audio:resp.data.audio})
          return resp
        })
        .catch(err=>{
          console.debug(err)
          alert('Connection err')
          return err
        })
        console.debug('From audio input',audio)
        return audio

      }} />
      <br />
<Button variant='outlined' size='large' startIcon={<VideoCallRounded/>} onClick={e=>{fileUp2.current.click();}}> {product.video !=null?product.video:'የምርት መረጃ በድምፅ'}</Button>
      <input key={Math.random()} style={{display:'none'}} type='file' ref={fileUp2} onChange={async e=>{

        console.debug(e.target.files[0])
        let file = e.target.files[0]
        let formData = new FormData();
        formData.append('video',e.target.files[0])
        setProduct({...product,video:'Loading....'})
        let video = await axios.post(`${api.address}/addressapi/files/uploadfile`,formData)
        .then(resp=>{
          console.debug(resp.data)
          setProduct({...product,video:resp.data.video})
          return resp
        })
        .catch(err=>{
          console.debug(err)
          alert('Connection err')
          return err
        })
        console.debug('From audio input',audio)
        return video

      }} />

</Card>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            
            <InputLabel id="demo-simple-select-standard-label">የምርት ምድብ</InputLabel>
             <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={handleValue}
              label="የምርት ምድብ"
              name='categoryId'
              variant='filled'
              value={product.categoryId}
            >
              <MenuItem value="">
              የምርት ምድብ
              </MenuItem>

       {
        
       catagoris.map((catag)=>(<MenuItem key={catag.id} value={catag.id}>{catag.categoryName}</MenuItem>)) 
        
        }
            </Select>


          </FormControl><br />
          <TextField style={{width:'100%',margin:5}} name='description'     value={product.description}          onChange={handleValue} id="standard-basic" label="የምርት ገለፃ" multiline rows={5} variant="filled" />
<Typography><Person style={{position:'relative',top: '4px'}}/>የምርቱ ባለቤት</Typography>
<Select value={id} onChange={e=>setid(e.target.value)} variant='filled'  style={{width:'60%'}} >
  <MenuItem id={0} value={0}><Typography>የምርቱን ባለቤት የግለፁ</Typography></MenuItem>
  <MenuItem onClick={e=>{setSellBy({sell:'user',id:userInfo.id})}} value={userInfo.id} >
  <ListItem>
  <ListItemAvatar>
    </ListItemAvatar>
    <ListItemText primary={userInfo.firstName+' '+userInfo.middleName}/>  
    </ListItem>
    </MenuItem>

{orgsList.map((org)=>(
  <MenuItem onClick={e=>{setSellBy({sell:'org',id:org.id})}} value={org.id}>
  <ListItem>
  <ListItemAvatar>
    </ListItemAvatar>
    <ListItemText primary={org.name}/>  
    </ListItem></MenuItem>
))}
</Select>
<>

{/* <JoditEditor onBlur={Joditsub} onClose={Joditsub}  ref={editor} config={config}  onChange={Jodithandl}/> */}

</>
<br /><br /><br />
<Typography  variant='h5'><Add style={{position:'relative',top: '4px'}}/> ተጨማሪ መረጃዎች</Typography>
    <hr />
    <ButtonGroup>
    <Button variant='contained' color='success' onClick={e=>setModal(true)}><AddBoxRounded/>መረጃ ይጨምሩ</Button>
    <Button variant='contained' color='error' onClick={e=>{ setDynamic([{name:'',type:'Nan'}]) }}><ClearAll/>ተጨማሪ መረጃወን ይስርዙ</Button>
    </ButtonGroup>

    <br/>
    {
      dynamicF.map(form=>(
        form.name!=''?<TextField type={form.type} name={form.name} onBlur={handleValue1} id="standard-basic" style={{margin:5}} label={form.name} variant="filled" />:''
      ))
    }


<div style={{marginTop:25}}>
  <br />
  <hr />
<Button  variant='contained' onClick={Submit} size='large'> <Send/>&nbsp;&nbsp;ላክ</Button>

</div>
    </Box>


        </Grid>
        <Grid style={{borderLeft: 'solid 1px #9a9a9a'}} item lg={4}>



        {sendView?<Button style={{borderRadius:'30px'}} onClick={e=>setSendView(false)} size='large' variant='text' startIcon={<ArrowBack/>} color='info'>መመለስ</Button>:<Button size='large' variant='contained' startIcon={<SendRounded/>}  onClick={e=>setSendView(true)} style={{borderRadius:'30px'}}>ወደ ገበያ ለመላክ</Button>}
        <hr />
       { sendView?<Card>
          <List>
            {
              
              ownProducts.map((prod)=>{
                
                
                if(!prod.forSale)
          { 
            
            
            return(
              <ListItem>
              <ListItemAvatar>
                <Avatar src={`${api.imgAddress}${prod.featureImage}`}/>
              </ListItemAvatar>
              <ListItemText primary={prod.name} secondary={
                    <FormControlLabel control={<Checkbox onChange={e=>{makeAvb(e,prod.id);}} name="name" />} label="ወደ ገበያ ይላክ" />
              }/>
            </ListItem>)

          }
          })
        }
          </List>
          <CardActions>
            <Button disabled={ids.length == 0 ?true:false} style={{borderRadius:'30px',width:'100%'}} onClick={async e=>{

ids.map(async singleId=>{
  await axios.put(`${api.address}/addressapi/products/${singleId}`,{forSale:true},{
    headers: {
      'Authorization': `Bearer ${user.user}`
    } 
  })
.then((result) => {
  console.log(result)
}).catch((err) => {
  console.log(err)
});
  

})

setSendView(false);
setIds([])
setsnack({Type:'info',msg:'ምርቶቹ ወደ ገባያ ተልከዋል!',show:true})
setupdateOwn(!updateOwn)        


     


}} variant='contained' startIcon={<SendSharp/>}>ላክ</Button>
          </CardActions>
        </Card>:''}
        <br /><br /><br />

        <Typography variant='h6' color="GrayText"> <Person2Rounded style={{position:'relative',top: '6px'}}/> በእርሶ ስም የገቡ ምርቶች ዝርዝር</Typography>
        <hr />
<List>

{
  ownProducts.map((prod)=>{
    if(prod.orgId == null)
    return ( 
<ListItem onClick={()=>setDetail({show:true,data:prod})} style={{cursor:'pointer'}}>
<ListItemAvatar>
    <Avatar src={`${api.imgAddress}${prod.featureImage}`} />
    </ListItemAvatar>
    <ListItemText primary={prod.name}/>
    <ListItemText secondary={'$ '+prod.price+' Birr'}/>
  </ListItem>
  )
  else
  return(<></>)
  })
}

</List>
<br /><br />
<Typography variant='h6' color="GrayText"><AdminPanelSettings style={{position:'relative',top: '6px'}}/>በድርጅትዎ ስም የገቡ ምርቶች ዝርዝር</Typography>
<br />
<hr />
<List>

{
  ownProducts.map((prod)=>{
    if(prod.orgId != null)
    return ( 
<ListItem onClick={()=>setDetail({show:true,data:prod})} style={{cursor:'pointer'}}>
<ListItemAvatar>
    <Avatar src={`${api.imgAddress}${prod.featureImage}`} />
    </ListItemAvatar>
    <ListItemText primary={prod.name}/>
    <ListItemText secondary={'$ '+prod.price+' Birr'}/>
    <ListItemIcon>
      <AdminPanelSettings/>{ orgsList.map(org=>{ 

if(prod.orgId == org.id)
return(<Typography>{org.name}</Typography>)

      })}
    </ListItemIcon>
  </ListItem>
  )
  else
  return(<></>)
  })
}

</List>
<br /><br />
<Typography variant='h6' color="GrayText"><CardTravelRounded style={{position:'relative',top: '6px'}}/> የተሸጡ ምርቶች</Typography>
<br />
<hr />



        </Grid>
      </Grid> 
    
    </Box>
        </Box>
}
        </>
    )
}






export default Producs