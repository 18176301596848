const define = {

Am:{ 
        logoText : "ኢጵላሣጵ",
        welcometxt:"እንኳን ወደ ኢጰላሣጵ የአድራሻ አውድ ደና መጡ!",
        suggest1:"ወደ አድራሻ አውድ ይግቡ!",
        suggest2:"ወደ አድራሻ አውድ ይመዝቡ!",
        idlable:"ንቁጥር",
        password:"የይለፍ ቃል",
        loginBtn:"ግብት",
        createNewBtn:"አዲስ አድራሻ ለማልማት",
        faqbtn:"ተጨማሪ",
        faqdesc:"ለዚሀ ገፅ አዲስ ከሆኑ እና ስለ አድራሻ ተጨማሪ ለማዎቅ ከፈለጉ ተጨማሪ እሚለውን ማስፈንጠርያ ጠቅ ያድርጉ",
        regBtn:"ምዝግብ",
        haveAcc:"ከዚ በፊት አድራሻ አልምተው ከሆነ",
        fname:"ስም",
        mname:"የአባት ስም",
        lname:"የአያት ስም",
        sex:"ፆታ",
        email:"ኢሜል",
        phone:"ስልክ",
        
        
        
    },
    En: {  }
}
export default define;