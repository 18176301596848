import { CardContent, CardHeader, Container, Grid, Card, Typography, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import lang from '../lang.json'
import { useSelector } from 'react-redux';
import { AdminPanelSettingsSharp, BiotechSharp, BookSharp, Person, SchoolSharp } from '@mui/icons-material';
import Chart_View from '../component/Chart_View';
const Dashboard = () => {
    const text = lang[0];
    const schoolDetail = useSelector(state=>state.schooldetail)
    const [details,setDetails] = useState([])
    useEffect(()=>{

        if(schoolDetail?.isLoaded)
        setDetails(schoolDetail.detail)

    },[])
  return (
    <Container sx={{padding:8}}>
        <Typography variant='h5'>{text.title}</Typography>
        <br /><br />
        <Grid container>
        <Grid item md={3}>
          <Card>
                <CardHeader title={<Typography color="green">{text.numStudent}</Typography>} />
                  <CardContent>
                      <Typography variant='h4' color="green"><SchoolSharp sx={{fontSize:43,position:"relative",bottom:-10}}/>12</Typography>    
                  </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
        <Card>
                <CardHeader title={<Typography color="secondary">{text.numTeacher}</Typography>} />
                  <CardContent>
                      <Typography variant='h4' color="secondary" ><BiotechSharp sx={{fontSize:43,position:"relative",bottom:-10}}/>12</Typography>    
                  </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
        <Card>
                <CardHeader title={<Typography color="#0099ff">{text.numLiber}</Typography>} />
                  <CardContent>
                      <Typography variant='h4' color="#0099ff"><BookSharp sx={{fontSize:43,position:"relative",bottom:-10}}/>12</Typography>    
                  </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
        <Card>
                <CardHeader title={<Typography>{text.numAdmin}</Typography>} />
                  <CardContent>
                      <Typography variant='h4'><AdminPanelSettingsSharp sx={{fontSize:43,position:"relative",bottom:-10}}/>12</Typography>    
                  </CardContent>
          </Card>
        </Grid>
        </Grid>
        <Paper>
          <Chart_View/>
        </Paper>
    </Container>
  )
}


export default Dashboard
