import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from './api';
const baseURL = api.address;


const initialState = {

    Org:{
        description:"",
        name:"",
        brand:"",
        tinNumber:"",
        address:"",
        type:"",
        streetNumber:"",
        businessSector:"",
         bank:"",
         numberOfStocks:"",
         email:"",
         logo:"",
         establishment:"",
         capital:"",
         employees:"",
         currentCapital:"",
         stockFounder:"",
         country:"",
         region:"",
         zone:"",
         woreda:"",
         kebele:"",
         city:"",
         houseNumber:"",
         subCity:"",
         currentCapital: "",
         stockFounder: "",
         currentStock: ""
    },
    MyOrg:[],
    isloading:false,
    status:"",
    msg:"",
    createStatus:"",
    resp:{}
}

export const createOrg = createAsyncThunk('createOrg/Org', async (data,file)=>{

    var org = data.org;
    const token = data.token;
    const response = await axios.post(`${baseURL}/addressapi/organizations/create`,org,{
        headers: {
            'Authorization': `Bearer ${token}`
          }   
    }).then((resp)=>{
     

        return resp;
    });
    return response;

})





export const fetchMyown = createAsyncThunk('fetchMyown/Org', async (token)=>{

    const response = await axios.get(`${baseURL}/addressapi/organizations/fetchownorgs/`,{
        headers: {
            'Authorization': `Bearer ${token}`
          }   
    }).then((resp)=>resp)
 
    .catch((err)=>{

        console.log(err)
        return {data:[]}
    })

    console.debug("Own",response)
    return response;

})





const createOrgSlice = createSlice({
    name:"createOrg",
    initialState,
    reducers:{
                setOrg:(state,action)=>{
                const filed = action.payload.filed   //filed value
                let value = action.payload.value   //value
                    if(!isNaN(value))
                    {
                        if(filed != "")
                        {
                            state.Org[filed] = parseInt(value)
                            state.createStatus = ""


                        }

                    }
                    else
                    { 
                        if(value != undefined || value != null || value != NaN)
                        {
                            if(value == "_")
                            state.Org[filed] = "";
                            else
                            state.Org[filed] = value;
                        }
                    }

                    console.log(state.Org)
                },
                Reset:(state,avtion)=>{
                    state.isloading = false;
                    state.status = ''   
                    console.log("State Reseted") 

                }
    },
    extraReducers:(builders)=>{

        builders.addCase(createOrg.pending, (state,action)=>{
            state.isloading = true;
            state.createStatus = 'progress'
            console.log(state.createStatus)
    });

    builders.addCase(createOrg.fulfilled, (state,action)=>{
        state.isloading = false;
        state.createStatus = 'success'
        state.msg = "created"
        state.resp = action.payload;
        console.log(state.createStatus)
        state.Org = {name:"",
        brand:"",
        tinNumber:"",
        address:"",
        type:"",
        streetNumber:"",
        businessSector:"",
         bank:"",
         numberOfStocks:"",
         email:"",
         logo:"",
         establishment:"",
         capital:"",
         employees:"",
         currentCapital:"",
         stockFounder:"",
         country:"",
         region:"",
         zone:"",
         woreda:"",
         kebele:"",
         city:"",
         houseNumber:"",
         subCity:"",
         currentCapital: "",
         stockFounder: "",
         currentStock: "",
         description:""
}
    });

    builders.addCase(createOrg.rejected, (state,action)=>{
        state.isloading = false;
        state.createStatus = 'failed'
        console.log(state.createStatus)
        state.msg = action.payload 
        console.log(action.payload)

    });


    //fetchMyown Organization


    builders.addCase(fetchMyown.pending, (state,action)=>{
        state.isloading = true;
        state.status = 'progress'
        console.log(state.status)
        state.createStatus = ''

        });

    builders.addCase(fetchMyown.fulfilled, (state,action)=>{
    state.isloading = false;
    state.status = 'success'
    state.MyOrg = action.payload.data
    console.log(state.status)
    state.createStatus = ''

        });

    builders.addCase(fetchMyown.rejected, (state,action)=>{
    state.isloading = false;
    state.status = 'failed'
    console.log(state.status)
    state.msg = action.payload 
    state.createStatus = ''

    });



    }
})






export const {setOrg,Reset} = createOrgSlice.actions
export default createOrgSlice.reducer