import React from 'react'
import { Typography } from '@mui/material'

const Break_point = () => {



  const display = false;

  return (
<>
{display?    <div style={{width: '4rem',background: 'black',color: '#04ff00',position: 'fixed',padding: '5px'}}>
<Typography variant='body1' sx={{display:{xs:"block",sm:"none",md:"none",lg:"none",xl:"none"}}}>Xsmall</Typography>
<Typography variant='body1' sx={{display:{xs:"none",sm:"block",md:"none",lg:"none",xl:"none"}}}>Small</Typography>
<Typography variant='body1' sx={{display:{xs:"none",sm:"none",md:"block",lg:"none",xl:"none"}}}>Medium</Typography>
<Typography variant='body1' sx={{display:{xs:"none",sm:"none",md:"none",lg:"block",xl:"none"}}}>Large</Typography>
<Typography variant='body1' sx={{display:{xs:"none",sm:"none",md:"none",lg:"none",xl:"block"}}}>XLarge</Typography>
</div>:
<></>
}
</>
    )
}

export default Break_point




