const api  ={






    // address:"http://196.189.126.180:11215",
    // imgAddress:"http://196.189.126.180:11215/images/",
    // audios:'http://196.189.126.180:11215/audios/',
    // videos:'http://196.189.126.180:11215/audios/',
    // stock:'http://192.168.0.113:11214/stockapi/',

    
    // address:"http://192.168.0.107:11215",
    // imgAddress:"http://192.168.0.107:11215",
    // audios:'http://192.168.0.107:11215/audios/',
    // videos:'http://192.168.0.107:11215/audios/',
    // stock:'http://192.168.0.113:11214/stockapi/',


    
    address:"http://192.168.0.10:11215",
    imgAddress:"http://192.168.0.10:11215",
    audios:'http://192.168.0.10:11215/audios/',
    videos:'http://192.168.0.10:11215/audios/',
    stock:'http://192.168.0.138:11214/stockapi/',


    // address:"http://192.168.0.153:11215",
    // imgAddress:"http://192.168.0.153:11215",
    // audios:'http://192.168.0.153:11215/audios/',
    // videos:'http://192.168.0.153:11215/audios/',
    // stock:'http://192.168.0.153:11214/stockapi/',
    

}
export default api;
