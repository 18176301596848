import JoditEditor from 'jodit-react'
import React from 'react'
import Barcode from 'react-barcode'
import { useState } from 'react'
import { Card, CardActionArea,Button, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import {Grid} from '@mui/material'
import api from '../data/api'
import { Download } from '@mui/icons-material'
import bg from '../assets/1.jpg'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { useEffect } from 'react'
import axios from 'axios'
export const UserInfo = ({userInfo,posList,org}) => {

    const [avater,setAvater] = useState("")

    useEffect(()=>{
 

        
    },[userInfo])
    

  return (
<>
<div id='idCard'>
<Card variant='outlined' style={{border:"solid #3b92cc",
backgroundImage:`url(${bg})`,
}}>
<CardContent >
<Grid container >

<Grid item  xl={3} lg={3} md={3} sm={3}  xs={3} >
    <img src={api.imgAddress+userInfo.avatar} style={{width:"85%",height:"120px"}} />
</Grid>
<Grid style={{border:"solid #3b92cc",borderRadius:"5px",padding:10,backgroundColor:"white"}} item  xl={9} lg={9} md={9} sm={9}  xs={9} >
<Grid container>
    <Grid item xl={6} lg={6} md={6} sm={6}  xs={6}>
    <Typography variant='body1'>ስም፡  <i><b>{userInfo.firstName}</b></i> </Typography>
    <Typography variant='body1'>የአባት ስም: <i><b>{userInfo.middleName}</b></i>  </Typography>
    <Typography variant='body1'>የአያት ስም: <i><b>{userInfo.lastName} </b></i>  </Typography>
    <Typography variant='body1'>ፆታ፡  <i><b>{userInfo.sex}</b></i> </Typography>
    </Grid>
    <Grid item xl={6} lg={6} md={6} sm={6}  xs={6}>
    <Typography variant='body1'>ስልክ ቁጥር :  <i><b>{userInfo.phoneNumber}</b></i></Typography>
    <Typography variant='body1'>አድራሻ : <i><b> {userInfo.kebele+', '+userInfo.city+', '+userInfo.region+', '+userInfo.country}</b></i></Typography>
    <Typography variant='body1'>የስራ ሃላፊነት : <i><b> {
      posList.map((pos)=>{
        if(pos.id == userInfo.positionId)
        return pos.title
      })
      }</b></i></Typography>
    
    </Grid>

</Grid>


</Grid>

</Grid>
<Grid container >

<Grid item  xl={3} lg={3} md={3} sm={3}  xs={3} >
    <img src={api.imgAddress+userInfo.avatar} style={{width:"85%",height:"120px"}} />
</Grid>
<Grid style={{border:"solid #3b92cc",borderRadius:"5px",padding:10,backgroundColor:"white"}} item  xl={9} lg={9} md={9} sm={9}  xs={9} >
<Grid container>
    <Grid item xl={6} lg={6} md={6} sm={6}  xs={6}>
    <Typography variant='body1'>ስም፡  <i><b>{userInfo.firstName}</b></i> </Typography>
    <Typography variant='body1'>የአባት ስም: <i><b>{userInfo.middleName}</b></i>  </Typography>
    <Typography variant='body1'>የአያት ስም: <i><b>{userInfo.lastName} </b></i>  </Typography>
    <Typography variant='body1'>ፆታ፡  <i><b>{userInfo.sex}</b></i> </Typography>
    </Grid>
    <Grid item xl={6} lg={6} md={6} sm={6}  xs={6}>
    <Typography variant='body1'>ስልክ ቁጥር :  <i><b>{userInfo.phoneNumber}</b></i></Typography>
    <Typography variant='body1'>አድራሻ : <i><b> {userInfo.kebele+', '+userInfo.city+', '+userInfo.region+', '+userInfo.country}</b></i></Typography>
    <Typography variant='body1'>የስራ ሃላፊነት : <i><b> {
      posList.map((pos)=>{
        if(pos.id == userInfo.positionId)
        return pos.title
      })
      }</b></i></Typography>
    
    </Grid>

</Grid>


</Grid>

</Grid>
</CardContent>
    <CardActionArea style={{display:'flex',alignItems:'center',alignContent:'center'}}>
    <Barcode 
    height= {30}
    format= {"CODE39"}
    // displayValue: true,
    // fontOptions: "",
    // font: "monospace",
    // textAlign: "center",
    // textPosition: "bottom",
    // textMargin: 2,
    // fontSize: 20,
     background={ ""}
    // lineColor: "#000000",
    // margin: 10,
    // marginTop: undefined,
    // marginBottom: undefined,
    // marginLeft: undefined,
    // marginRight: undefined

     value={+userInfo?.userCode}

/>
<Typography>{org.name+' '+org.type}</Typography>
    </CardActionArea>
</Card>
</div>
<Button color='secondary' variant='contained' onClick={ async ()=>{

const pdf = new jsPDF("p", "px", "a4")
let data = await html2canvas(document.querySelector('#idCard'),{
    onrendered: function(canvas)
    {
      document.body.appendChild(canvas);
    },
    logging:true,
  });
const img = data.toDataURL("image/png")
const imgProp = pdf.getImageProperties(img)

pdf.addImage(img,0,0)
.save(`${userInfo.firstName}_IDCard.pdf`);

}} style={{color:"white"}}>ዳውንሎድ PDF</Button>

</>

  )
}
