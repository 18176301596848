// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-ProductCard-styles-module__product-card--3ylL8{display:flex;flex-flow:column;width:300px;height:450px;box-shadow:0 10px 30px rgba(44,62,80,.5)}", "",{"version":3,"sources":["webpack://./src/ProductCard/styles.module.scss"],"names":[],"mappings":"AAAA,oDACE,YAAA,CACA,gBAAA,CAEA,WAAA,CACA,YAAA,CAEA,wCAAA","sourcesContent":[".product-card {\n  display: flex;\n  flex-flow: column;\n  \n  width: 300px;\n  height: 450px;\n\n  box-shadow: 0 10px 30px rgba(#2c3e50, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"product-card": "src-ProductCard-styles-module__product-card--3ylL8"
};
export default ___CSS_LOADER_EXPORT___;
