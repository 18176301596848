// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-PaymentCard-styles-module__payment-card--MU\\+iK{width:21rem;min-width:21rem;max-width:21rem;height:13.5rem;min-height:13.5rem;max-height:13.5rem}.src-PaymentCard-styles-module__payment-card--MU\\+iK .src-PaymentCard-styles-module__front--u0-5h{display:flex;flex-flow:column}", "",{"version":3,"sources":["webpack://./src/PaymentCard/styles.module.scss"],"names":[],"mappings":"AAAA,qDACE,WAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,kBAAA,CACA,kBAAA,CAEA,kGACE,YAAA,CACA,gBAAA","sourcesContent":[".payment-card {\n  width: 21rem;\n  min-width: 21rem;\n  max-width: 21rem;\n  height: 13.5rem;\n  min-height: 13.5rem;\n  max-height: 13.5rem;\n\n  .front {\n    display: flex;\n    flex-flow: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-card": "src-PaymentCard-styles-module__payment-card--MU+iK",
	"front": "src-PaymentCard-styles-module__front--u0-5h"
};
export default ___CSS_LOADER_EXPORT___;
