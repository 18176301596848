import React, { useRef, useState } from 'react'
import {Button, ButtonGroup, LinearProgress, TextField} from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UploadFile } from '@mui/icons-material'
import { set, useForm } from 'react-hook-form'
import axios from 'axios'
import api from '../../data/api';
import Loading from '../../components/Loading'
import { useSelector } from 'react-redux'
const baseURL = api.address;
const Org5 = () => {

  const {register,handleSubmit, formState:{errors}} = useForm();
  const {state} = useLocation();
  const orgId = state.id;
  const file1 = useRef();
  const file2 = useRef();
  const [filename,setfilename] = useState({file1:"",file2:"",file1P:false,file2P:false}); 
  const user = useSelector((state)=>state.logedUser)
  const active = {file1P:true};
  const off = {file1P:false};
  const [load,setload] = useState(false);
  const [f1,setf1] = useState({})
  const [f2,setf2] = useState({})
  const link = useNavigate()


  const submit =async data => {

    setload(true);
    let newData = {...data,...f1,...f2,...{orgId:orgId}}
    console.log(newData)
    let token = user.user;
    const create = await axios.post(`${baseURL}/addressapi/projectoffice/create`,newData,{
      headers: {
        'Authorization': `Bearer ${token}`
      }   
    }).then((resp)=>{
      setload(false); 

      return resp
    }).catch((err)=>{
      setload(false);  
      return err})

      if(create.data?.createdAt != undefined)
      {
        link('../view',{state:{id:orgId}});        
      }
      else
      {
        alert("ስህተት አለ")

      } 
    
  }


  const Upload = async e => {
 
    setfilename({...active})
    const formData = new FormData();
    formData.append(e.target.name,e.target.files[0]);
    const fileUpload = await axios.post(`${baseURL}/addressapi/files/uploadfile`,formData).then((resp)=>{

      setfilename({...off})
      setf1(resp.data);
      return resp;
    }).catch((err)=>{
      
      setfilename({...off})

      console.log(err.response)

    return  err;
    });
 
    console.log(fileUpload.data)
  }



  const Upload1 = async e => {
 
    setfilename({...active})
    const formData = new FormData();
    formData.append(e.target.name,e.target.files[0]);
    const fileUpload = await axios.post(`${baseURL}/addressapi/files/uploadfile`,formData).then((resp)=>{

      setfilename({...off})
      setf2(resp.data);
      return resp;
    }).catch((err)=>{
      
      setfilename({...off})

      console.log(err.response)

    return  err;
    });
 
    console.log(fileUpload.data)
  }












  return (

    <Card>
      <Loading load={load} />
      <form onSubmit={handleSubmit(submit)}>
    <CardContent sx={{width:"100%"}}>
       <Loading load={load} />
    <Grid container >
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h6' color="primary">የጵሮጀክት ፅፈትቤት አደረጃጀት!</Typography>

        <TextField
          id="standard-multiline-static"
          label="ይግብር ፈቃድ የተሰጠው ጵሮጀክት"
          multiline
          {...register('licensedProject',{
            required:{
              value:true
            }
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="የቀን የሳምንት ወርሃዊ የ3ወርና የ6 ወር እቅድ ቅፅ"
          multiline
          {...register('monthlyReportExpert',{
            required:{
              value:true
            }
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="የደሞዝ አበልና ሌሎች መክፈያ ደንብ"
          multiline
          {...register('paymentOrderForm',{
            required:{
              value:true
            }
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />
    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h6' color="primary">ተወዳዳሪነት!</Typography>

        <TextField
          id="standard-multiline-static"
          label="ወርሃዊ ሪፖርትና ክትትል ደንብ"
          multiline
          {...register('planForm',{
            required:{
              value:true
            }
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="የማስታወቅያና ህዝብ ግንኙነት ደንብ"
          multiline
          {...register('promotionExpert',{
            required:{
              value:true
            }
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />
          <InputLabel htmlFor="component-simple">የሂሳብ መዝገብ የሰው</InputLabel>
          <InputLabel htmlFor="component-simple" color='primary'>{filename.file1}</InputLabel>
          <Button onClick={e=>file1.current.click()} startIcon={<UploadFile/>}>ለማስገባት</Button>
          {/* <Input {...register('payrollForm')} type='file' sx={{display:"none"}} id="component-simple"  /> */}
          <input ref={file1} name='paymentOrderForm' onChange={e=>Upload(e)} type="file"  style={{display:"none"}} />
          {filename.file1P?<LinearProgress color='primary' />:""}

          <InputLabel htmlFor="component-simple">የሂሳብ መዝገብ የሰው</InputLabel>
          <InputLabel htmlFor="component-simple" color='primary'>{filename.file2}</InputLabel>

            <Button  onClick={e=>file1.current.click()} startIcon={<UploadFile/>}>ኃብትና የፋይናንስ ደንብ</Button>
            {/* <Input type='file' sx={{display:"none"}} id="component-simple" /> */}
            <input ref={file2} name='payrollForm' onChange={e=>Upload1(e)}  type="file" style={{display:"none"}} />
            {filename.file1P?<LinearProgress color='primary' />:""}

    </Grid>

    <Grid  item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:4}}>
    
    <Typography variant='h6' color="primary">ያሳለፉት መረጃና ሃላፊነት!</Typography>

    <TextField
  id="standard-multiline-static"
  label="ወርሃዊ ሪፖርት ክትትል ባለሞያ"
  multiline
  {...register('typist')}
  rows={4}
  variant="standard"
  style={{width:"100%"}}
  />


<TextField
  id="standard-multiline-static"
  label="የውል ስምምነት ደንብ"
  multiline
  {...register('contractForm')}
  rows={4}
  variant="standard"
  style={{width:"100%"}}
  />



    </Grid>
    </Grid>
    </CardContent>
    <CardActions sx={{paddingLeft:20}}>        
    <Link to="../view" state={{id:orgId}}><Button color='secondary' style={{color:"white"}} size='large' startIcon={<ArrowBackIosNewRounded /> } variant="contained">መመለስ</Button></Link> 
             &nbsp;
<Button type='submit' size='large' endIcon={<ArrowForwardIosRounded/> } variant="contained">መዝግብ</Button> 

    </CardActions>
    <br /><br />
    </form>
</Card>

    )
}

export default Org5