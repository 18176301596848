import React, { useEffect, useRef, useState } from 'react'
import { Button, Box, ButtonGroup, Grid, TextField, Toolbar, Typography, Paper, IconButton, InputBase, Input, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { ArrowBack, Cancel, Directions, DirectionsBike, DirectionsCar, DirectionsTransit, DirectionsWalk, GpsFixed, PointOfSaleRounded, RuleRounded, SearchRounded, Share, Start } from '@mui/icons-material'
import { useJsApiLoader, GoogleMap, Marker,
    Autocomplete,
    DirectionsRenderer
} from '@react-google-maps/api'
import { InfinitySpin, ProgressBar } from 'react-loader-spinner'
import Loading from '../components/Loading'
import axios from 'axios'
import "../assets/maps.css"
export const Maps = (props) => {

    const [active,setactive] = props.states
    const [center,setCenter] = useState({lat:11.594502,lng:37.388324,zoom:17})
    const [Msg,setMsg] = useState(null)
    const [travleMode,setTmode] = useState(1)
    const [dInfo,setDinfo] = useState({show:false,distance:0,time:0})
    const [detail,setDetail] = useState({})
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo",
        libraries:["places"]
      })
      const [map,setMap] = useState(/** @type google.maps.Map */(null))
      const [maps,setMaps] = useState(/** @type google.maps */(null))
      const [diResp,setDiResp] = useState(null)
      const [markMy,setMy] = useState(false)
      const [pointresp,setPointresp] = useState({})
      /**
       @type React.MutableObject<HTMLInputElement>
       */
      const searchText = useRef()
      const start = useRef();
      const end = useRef();
      const [localRoute,setLocalRoute] = useState({start:null,end:null})
      const [current,setCurrent] = useState(null); 
    useEffect(()=>{

        (async()=>{
          setMsg("Loading GPS.........")
          if(navigator.geolocation)
          {
           let data = await navigator.geolocation.getCurrentPosition(async (postion)=>{
      
            setTimeout(()=>{setCenter({lat:postion.coords.latitude,lng:postion.coords.longitude,zoom:18}); setMsg("")},1000)
      
            return {lat:postion.coords.latitude,lng:postion.coords.longitude};
      
            })
            
      
          }
          else
          alert("አባክዎ Location Allow ይበሉት!")
        })()
      
      },[])
  return (
    <Box style={{display: active == 17?'block':'none', transition: 'eas-in-out 0.5s'}}>
  <Toolbar style={{borderBottom:"solid 1px #0099ff"}}> 
    <GpsFixed/>
   &nbsp;    <Typography variant='h5'>አድራሻ</Typography>
  <Button color='info' startIcon={  <img style={{width:"30px"}} src={require('../assets/man.png')} />} size='large' sx={{
        marginLeft:"20vw"
    }}
    
    onClick={async e=>{
    
        await navigator.geolocation.getCurrentPosition((pos)=>{
            setCenter({lat:pos.coords.latitude,lng:pos.coords.longitude,zoom:20})
        })
        map.panTo(center)
        setMy(true)
    }}  

    >
    የኔ መገኛ
    </Button>
    


  <div style={{
    marginLeft:40,
display: 'flex',
alignItems: 'center',
justifyContent: 'center',
width: '50%'
  }}>

<select
style={{
padding: '12px 20px',
margin: '8px 0',
display: 'inline-block',
border: '1px solid #ccc',
borderRadius: '8px',
boxSizing: 'border-box',
borderTopRightRadius:"0px",
borderBottomRightRadius:"0px"
}}
value={travleMode}
onChange={e=>setTmode(e.target.value)}
>

<option value={1}>
የግር ጉዞ  
</option>
<option value={2}>
መኪና 
</option>
<option value={3}>
ብስክሌት
</option>
<option value={4}>
ባቡር
</option>
</select>
  {isLoaded?<Autocomplete style={{marginLeft:5}}>
   
     <input
     ref={start}
        placeholder="መነሻ"
        style={{
            width: '100%',
padding: '12px 20px',
margin: '8px 0',
display: 'inline-block',
border: '1px solid #ccc',
boxSizing: 'border-box',
borderTopRightRadius:"0px",
borderBottomRightRadius:"0px"

        }}
        onBlur={ async e=>{

            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${start.current.value}&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`)
            .then((result) => {
                let newCent = result.data.results[0].geometry.location
               

            }).catch((err) => {
                console.log(err)
                
            });

        }}
      />
      </Autocomplete>:"Loading..."}

  {isLoaded?<Autocomplete>
     <input
     ref={end}
        placeholder="መዳረሻ"
        style={{
            width: '1@0%',
padding: '12px 20px',
margin: '8px 0',
display: 'inline-block',
border: '1px solid #ccc',
borderRadius: '0px',
boxSizing: 'border-box',
        }}
        onBlur={ async e=>{

            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${end.current.value}&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`)
            .then((result) => {
                let newCent = result.data.results[0].geometry.location
               

            }).catch((err) => {
                console.log(err)
                
            });

        }}
      />
      </Autocomplete>:"Loading..."}
      <Button variant='contained' style={{
        borderTopLeftRadius:"0px",
        borderBottomLeftRadius:"0px",
        padding: '8px 20px',
        
      }} onClick={async e=>{

        if(start.current.value === "" || end.current.value === "")
        start.current.value === ""?start.current.focus():end.current.focus()
        else
        {
            const google = window.google;
            const directionServ = new google.maps.DirectionsService();
            const result = await directionServ.route({
                origin:start.current.value,
                destination:end.current.value,
                travelMode:google.maps.TravelMode.DRIVING
            });
            setDiResp(result);
            setDinfo({show:true,distance:result.routes[0].legs[0].distance.text,time:result.routes[0].legs[0].duration.text})
        } 

      }} startIcon={<Directions/>}>መንገድ ጠቁም</Button>
      </div>
      <Paper elevation={4} style={{
        display:dInfo.show?"flex":"none",
position: 'absolute',
right: '18%',
padding: '12px',
top: '120%',
zIndex: '3',
width: '50%'    ,
transition:"ease-in-out 0.5se",
alignContent:"center",
alignItems:"center",
justifyContent:"space-evenly"
}}>
        <Typography variant='h5'> እርቅት</Typography>
        <Typography variant='h6' color="#0099fe">&nbsp;{dInfo.distance}</Typography>
        <Typography style={{marginLeft:25}} variant='h5'>የሚወስደው ስኣት</Typography>
        <Typography variant='h6' color="#0099fe">&nbsp; {dInfo.time}</Typography>
        <Typography variant='h6' color="#0099fe">&nbsp; {
        travleMode==1?"በግር":travleMode==2?"በመኪና":travleMode==3?"በብስክሌት":"በባቡር"
        }</Typography>
        <Cancel        
        color='warning'
        onClick={
            e=>{
                setDiResp(null);
                start.current.value = null
                end.current.value = null
                setDinfo({show:false,time:0,distance:0})
                setLocalRoute({start:null,end:null})
            }
        }

        />

      </Paper>
  </Toolbar>
    <Grid container>
    <Grid item md={3} sx={{padding:1,height:"80vh",borderRight:"solid 1px #0099ff"}}>



{isLoaded?<Autocomplete style={{width:"100%"}}>
     <input
        placeholder="በ ፈለገ"
        ref={searchText}
        style={{
            width: '100%',
padding: '12px 20px',
margin: '8px 0',
display: 'inline-block',
border: '1px solid #ccc',
borderRadius: '4px',
boxSizing: 'border-box',
width:"100%"
        }}
        onBlur={ async e=>{

            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${searchText.current.value}&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`)
            .then((result) => {
                let newCent = result.data.results[0].geometry.location
               
                setCenter(newCent)
                setMy(false)
            }).catch((err) => {
                console.log(err)
                
            });

        }}
      />
      </Autocomplete>:"Loading..."}
      {/* <Button onClick={e=>{
        console.log(searchText.current.value)
      }} startIcon={<SearchRounded/>}  aria-label="search">
       ፈለገ
      </Button> */}
      <br />
      <br />
      <Typography variant='h6' color='primary'>Short Address</Typography>
      <Typography variant='body1' color="GrayText" style={{
        textAlign:"center"
      }}>FS-6787</Typography>
      <br />
      <hr />
      <Typography variant='h6' color='primary'>Kebele</Typography>
      <Typography variant='body1' color="GrayText" style={{
        textAlign:"center"
      }}>FS-6787</Typography>
        <hr />
        <Typography variant='h6' color='primary'>Sub-City</Typography>
      <Typography variant='body1' color="GrayText" style={{
        textAlign:"center"
      }}>{detail.subCity}</Typography>
        <hr />
        <Typography variant='h6' color='primary'>City</Typography>
      <Typography variant='body1' color="GrayText" style={{
        textAlign:"center"
      }}>{detail.city}</Typography>
        <hr />
        <Typography variant='h6' color='primary'>Region</Typography>
      <Typography variant='body1' color="GrayText" style={{
        textAlign:"center"
      }}>{detail.region}</Typography>
        <hr />
        <br />
        <ButtonGroup style={{
            width:"100%",
            margin:4
        }}>

<select
style={{
padding: '12px 20px',
display: 'inline-block',
border: '1px solid #ccc',
borderRadius: '8px',
boxSizing: 'border-box',
borderTopRightRadius:"0px",
height:"100%",
borderBottomRightRadius:"0px",
border:"#0099ff"
}}
value={travleMode}
onChange={e=>setTmode(e.target.value)}
>

<option value={1}>
የግር ጉዞ  
</option>
<option value={2}>
መኪና 
</option>
<option value={3}>
ብስክሌት
</option>
<option value={4}>
ባቡር
</option>
</select>



        <Button disabled={localRoute.start == null?false:true} onClick={e=>{
            setLocalRoute({...localRoute,start:center})
            e.target.disabled = true
        }} style={{
                        width:"100%",
                        color:"white"    
        }} color='info' variant='contained' startIcon={<Directions/>}>መነሻየ</Button>
        <Button onClick={async e=>{
            setLocalRoute({...localRoute,end:center})
            e.target.disabled = true;

            if(localRoute.start == null)
            {
                alert("Set መነሻየ first!")
            }
            else
            {
                const google = window.google;
                const directionServ = new google.maps.DirectionsService();
                const result = await directionServ.route({
                    origin: localRoute.start,
                    destination:center,
                    travelMode: travleMode ==1?google.maps.TravelMode.WALKING:travleMode==2?google.maps.TravelMode.DRIVING:travleMode==3?google.maps.TravelMode.BICYCLING:google.maps.TravelMode.TRANSIT
                });
                console.log(result)
                setDiResp(result);
                setDinfo({show:true,distance:result.routes[0].legs[0].distance.text,time:result.routes[0].legs[0].duration.text})
            } 


        }} style={{
                        width:"100%",
                        color:"white"    
        }} startIcon={<Directions/>} color='warning' variant='contained'>መዳረሻየ</Button>


        </ButtonGroup>
        <br />
        
        <Button style={{
            width:"100%",
            color:"white"
        }} startIcon={<Share/>} variant='contained' color='secondary'>Share</Button>
        </Grid>
        
    <Grid item md={9} sx={{padding:0}}>

{
    isLoaded?
    <GoogleMap
    center={center}
    zoom={center.zoom}
    mapContainerStyle={{width:"100%",height:"100%"}}
    options={{
        
        streetViewControl:false,
        mapTypeControl:false,
        fullscreenControl:false,

    }}
    onLoad={(map)=>setMap(map)}
    onClick={async (e)=>{
        console.log(e.latLng.toJSON())

        let point = e.latLng.toJSON()
        searchText.current.value = "Digital Address: ?????";
        setCenter(point)
        setCurrent(point)

        await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat+","+point.lng}&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`)
        .then((result) => {
          
          setDetail({ country:result.data.results[9].formatted_address,
                      region:result.data.results[2].address_components[3].long_name,
                      city:result.data.results[2].address_components[2].long_name,
                      subCity:result.data.results[0].address_components[1].long_name
                    })
      

        }).catch((err) => {
            console.log(err)
            
        });

        // let google = window.google
        // new google.maps.Marker({
        //     position: point,
        //     map,
        //     title: "Hello World!",
        //     icon:require('../assets/man.png')
        //   });
        



    }}
    >
          {
            markMy?<Marker position={center} onClick={(e=>{
                console.log(e)
            })} icon={require('../assets/man.png')} children={<GpsFixed/>} />:<></>
            
            }
            {diResp && <DirectionsRenderer directions={diResp} />}

            {current?<Marker icon={require('../assets/flag.png')} position={current} />:""}


    </GoogleMap>:<Typography color="purple" variant='h5' style={{margin:10}}>Loading map...</Typography>
}
        </Grid>
        

    </Grid>
    </Box>

  )
}
