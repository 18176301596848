// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-PaymentCard-PaymentCardBack-styles-module__payment-card-back--bm6bY{position:relative;display:flex;flex-flow:column;width:100%;height:100%}.src-PaymentCard-PaymentCardBack-styles-module__payment-card-back--bm6bY .src-PaymentCard-PaymentCardBack-styles-module__magnetic-stripe--6DO1d{width:100%;height:3rem;margin-top:1rem;background:#2c3e50}.src-PaymentCard-PaymentCardBack-styles-module__payment-card-back--bm6bY .src-PaymentCard-PaymentCardBack-styles-module__signature-field--me5aY{display:flex;align-items:flex-end;flex-flow:row;justify-content:flex-end;height:2rem;margin-top:.5rem;margin-right:6rem;margin-left:.5rem;background-image:repeating-linear-gradient(180deg, #f1c40f, #f1c40f 3px, #ecf0f1 3px, #ecf0f1 6px)}.src-PaymentCard-PaymentCardBack-styles-module__payment-card-back--bm6bY .src-PaymentCard-PaymentCardBack-styles-module__cvv--edHLX{color:#2c3e50;font-family:\"OCR A Std\"}", "",{"version":3,"sources":["webpack://./src/PaymentCard/PaymentCardBack/styles.module.scss"],"names":[],"mappings":"AAAA,yEACE,iBAAA,CAEA,YAAA,CACA,gBAAA,CAEA,UAAA,CACA,WAAA,CAEA,gJACE,UAAA,CACA,WAAA,CACA,eAAA,CAEA,kBAAA,CAGF,gJACE,YAAA,CACA,oBAAA,CACA,aAAA,CACA,wBAAA,CAEA,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA,CAEA,kGAAA,CAGF,oIACE,aAAA,CAEA,uBAAA","sourcesContent":[".payment-card-back {\n  position: relative;\n\n  display: flex;\n  flex-flow: column;\n\n  width: 100%;\n  height: 100%;\n\n  .magnetic-stripe {\n    width: 100%;\n    height: 3rem;\n    margin-top: 1rem;\n\n    background: #2c3e50;\n  }\n\n  .signature-field {\n    display: flex;\n    align-items: flex-end;\n    flex-flow: row;\n    justify-content: flex-end;\n\n    height: 2rem;\n    margin-top: 0.5rem;\n    margin-right: 6rem;\n    margin-left: 0.5rem;\n\n    background-image: repeating-linear-gradient(180deg, #f1c40f, #f1c40f 3px, #ecf0f1 3px, #ecf0f1 6px);\n  }\n\n  .cvv {\n    color: #2c3e50;\n\n    font-family: 'OCR A Std';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-card-back": "src-PaymentCard-PaymentCardBack-styles-module__payment-card-back--bm6bY",
	"magnetic-stripe": "src-PaymentCard-PaymentCardBack-styles-module__magnetic-stripe--6DO1d",
	"signature-field": "src-PaymentCard-PaymentCardBack-styles-module__signature-field--me5aY",
	"cvv": "src-PaymentCard-PaymentCardBack-styles-module__cvv--edHLX"
};
export default ___CSS_LOADER_EXPORT___;
