import { useEffect, useRef, useState } from 'react';
import { Box,AppBar,Button,Card,Container,Toolbar, Typography, CardActions, TextField, LinearProgress, Snackbar} from '@mui/material'
import style from '../styles';
import define from '../strings';
import {Grid,FormControl,InputLabel,Input,Select,MenuItem} from '@mui/material';
import Break_point from '../breakpoint';
import UserAppbar from './userAppBar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { ArrowBackIosNew, Bloodtype, PanoramaFishEye, RemoveRedEye, UploadFile } from '@mui/icons-material';
import {motion, progress} from "framer-motion";
import {InputAdornment} from '@mui/material';
import { useSelector,useDispatch } from 'react-redux';
import { UpdateUserInfo,updateprivateInfo } from '../data/updateUser';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SnackBar from '../components/SnackBar';
import api from '../data/api';
import {fetchUserInfo} from '../data/userInfo';
const baseURL = api.address
const PrivateInfo = () => {

        const link = useNavigate();
        const file = useRef();
        const user = useSelector((state)=>state.updateuser)
        const userLoged = useSelector((state)=>state.logedUser)
        const [step,setstep] = useState(2);
        const [lang,setlang] = useState(define.Am)
        const blood = ["A","B","AB+","AB-","O+","O-"];
        const steps = ["የመጀመርያ ደረጃ",'ሁለተኛ ደረጃ','ሶስተኛ ደረጃ','አራተኛ ደረጃ'];
        const [load,setload] = useState(false);
        const run = useDispatch()
        const [dis,setdis] = useState(false)
        const [progress,setprogress] = useState(false)
        const upbutton = useRef()
        const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
  



        useEffect(()=>{

          run(fetchUserInfo(userLoged.id))


        },[])





const Upload = e =>{
  let file_name = "";
  const data = file.current.files[0];

  setdis(true)  
setprogress(true)
var formData = new FormData();
formData.append('studyDesign',data)
const uploadF = async () =>{

  return await axios.post(baseURL+'/addressapi/files/uploadfile',formData,{ 
     headers: {
    'Content-Type': 'multipart/form-data'
  }
}).then((resp)=>{

  console.log(resp)
    file_name = resp.data.studyDesign

}).catch((error)=>{
  if(error)
  {
    setsnack({Type:"warning",msg:"ኒትዎርኩ በትክክል እየሰራ አደልም! በድጋሚ ይሞከሩ",show:true})
  }
})

}

uploadF().then(()=>{

setprogress(false) 
setdis(false)

alert(file_name)

})

}
        
const InputEv = e =>{
  
   run(UpdateUserInfo({name:e.target.name,data:e.target.value}));

}


const submit = () =>{

var newUser = user;
console.log(userLoged)
console.log(newUser)

  run(updateprivateInfo({user:newUser,userId:userLoged.id,token:userLoged.user}))


}

useEffect(()=>{

  if(user.isloading)
  setload(true)
  else if(user.status == 'faild')
  {
    setload(false)
    setsnack({Type:"warning",msg:"ኔትዎርኩ በትክክል እየሰራ አደልም! በድጋሚ ይሞከሩ",show:true})
  }
  else if(user.status == 'success')
  {
    setload(false)
    
    window.location = "/";

  }

},[user])



  return (
    <div style={{backgroundColor:'#edeef2',height:'100vh'}}>
        <UserAppbar/>
        <SnackBar snack={snack} />
        <Loading load={load} />
<Container sx={{paddingTop:"30px"}}>


    <Card sx={{padding:"8vh"}}    initial={{opacity:0}}  animate={{opacity:1}} component={motion.div}>
    <Box sx={{ width: '100%',margin:"10px" }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
      <Typography color='secondary' variant='h5'>User ወቅታዊ አቋም መለክያ </Typography>
      <hr />
    <br />
    <Grid container spacing={3} sx={{width:"auto"}}>





    <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
      <Typography variant='h6' color={style.colors.high}>ጤና ተኮር መረጃ</Typography>


      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <TextField
        name='weight'
          label="ክብደት፡"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">(በኪሎ ግራም)</InputAdornment>,
          }}
          value={user.weight}
          type='number'
          variant="standard"
          onChange={InputEv}
        />
      </FormControl> 
      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <TextField
          type='number'
          label="ቁመት፡"
          name='hight'
          value={user.hight}
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">(በሜትር)</InputAdornment>,
          }}
          variant="standard"
          onChange={InputEv}
        />
      </FormControl> 


      <FormControl style={{width:"100%",margin:5}} variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label"> የደም አይነት</InputLabel>
        <Select 
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          label="ክልል"
          name='bloodType'
          value={user.bloodType}
          onChange={InputEv}
        >
          {
            blood.map((region)=>
              <MenuItem key={region} value={region}>{region}</MenuItem>
            )
          }
        </Select>
      </FormControl>

      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <TextField
        
          name='eyeColor'
          label="የአይን ቀለም፡"
          value={user.eyeColor}
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start"><RemoveRedEye/></InputAdornment>,
          }}
          variant="standard"
          onChange={InputEv}
        />
      </FormControl> 



    </Grid>

    <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
      <Typography variant='h6' color={style.colors.high}>የስራ ልምዶች</Typography>
      
      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የተሰማራባት የስራ ዘርፍ፡</InputLabel>
        <Input value={user.position} id="component-simple" name='position' onChange={InputEv} />
      </FormControl> 



      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የትምህርት ደረጃ፡</InputLabel>
        <Input value={user.education} name='education' id="component-simple" onChange={InputEv} />
      </FormControl> 


      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የሙያ ዘርፍ፡</InputLabel>
        <Input value={user.profession} name='profession' id="component-simple" onChange={InputEv} />
      </FormControl> 




    </Grid>

    <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
      <Typography variant='h6' color={style.colors.high}>ተጨማሪ መረጃ</Typography>
      <FormControl> 
      <TextField
          id="standard-multiline-static"
          label="ምኞት"
          multiline
          rows={2}
          variant="standard"
          onChange={InputEv}
          
        />
     </FormControl> 
     <FormControl> 
      <TextField
        name='skill'
        value={user.skill}  
        id="standard-multiline-static"
          label="ችሎታ"
          multiline
          rows={2}
          variant="standard"
          onChange={InputEv}
        />
     </FormControl> 
     <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ስኬት፡</InputLabel>
        <Input name='achievement' value={user.achievement} id="component-simple"  type='text' onChange={InputEv}/>
      </FormControl> 

      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ትርፍ ጊዜን ማሳለፍያ፡</InputLabel>
        <Input  name='hobbies'  value={user.hobbies} id="component-simple"  type='text' onChange={InputEv}/>
      </FormControl> 


    </Grid>





</Grid>
<CardActions sx={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
<Link to="/welcome/">
<Button variant="contained"  disabled={dis} startIcon={<ArrowBackIosNew/>} size="large" >የበፊት</Button>
</Link>
<Link to="#">
<Button variant="contained" color='success' disabled={dis} endIcon={<ArrowForwardIosIcon/>} onClick={submit} size="large">ቀጣይ</Button>
</Link>

</CardActions>
    </Card>
</Container>


<Break_point/>

    </div>
    )
}

export default PrivateInfo