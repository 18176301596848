// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-PaymentCard-IssuerIcon-styles-module__issuer-icon--WqMcG{position:relative;z-index:10;display:flex;align-items:flex-start;flex-flow:row;justify-content:flex-end;box-sizing:border-box;width:100%;padding:1.5rem 1.5rem 0}.src-PaymentCard-IssuerIcon-styles-module__issuer-icon--WqMcG img{width:20%}", "",{"version":3,"sources":["webpack://./src/PaymentCard/IssuerIcon/styles.module.scss"],"names":[],"mappings":"AAAA,8DACE,iBAAA,CACA,UAAA,CAEA,YAAA,CACA,sBAAA,CACA,aAAA,CACA,wBAAA,CAEA,qBAAA,CACA,UAAA,CACA,uBAAA,CAEA,kEACE,SAAA","sourcesContent":[".issuer-icon {\n  position: relative;\n  z-index: 10;\n\n  display: flex;\n  align-items: flex-start;\n  flex-flow: row;\n  justify-content: flex-end;\n\n  box-sizing: border-box;\n  width: 100%;\n  padding: 1.5rem 1.5rem 0;\n\n  img {\n    width: 20%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"issuer-icon": "src-PaymentCard-IssuerIcon-styles-module__issuer-icon--WqMcG"
};
export default ___CSS_LOADER_EXPORT___;
