// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-UserCard-UserCardAvatar-styles-module__user-card-avatar--LDsnQ{position:absolute;display:flex;flex:0 0 auto;height:6rem;width:6rem;top:calc(50% - 3rem);left:calc(50% - 3rem);background-size:cover;background-repeat:no-repeat;background-position:center;border-radius:100%;z-index:10}", "",{"version":3,"sources":["webpack://./src/UserCard/UserCardAvatar/styles.module.scss"],"names":[],"mappings":"AAAA,oEACE,iBAAA,CACA,YAAA,CACA,aAAA,CACA,WAAA,CACA,UAAA,CACA,oBAAA,CACA,qBAAA,CAEA,qBAAA,CACA,2BAAA,CACA,0BAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".user-card-avatar {\n  position: absolute;\n  display: flex;\n  flex: 0 0 auto;\n  height: 6rem;\n  width: 6rem;\n  top: calc(50% - 3rem);\n  left: calc(50% - 3rem);\n  \n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  border-radius: 100%;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-card-avatar": "src-UserCard-UserCardAvatar-styles-module__user-card-avatar--LDsnQ"
};
export default ___CSS_LOADER_EXPORT___;
