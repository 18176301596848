import React from 'react'
import '../assets/resume.css'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const Download = () => {

    const location = useLocation()
    const userInfo = location.state.user
    const link = useNavigate()
    useEffect(()=>{


(async()=>{

window.print();
link(-1)




})()

    
        
    },[])


  return (
    <div id='CV'>
        

        <div >
    <div id="doc2" class="yui-t7">
	<div id="inner">
	
		<div id="hd">
			<div class="yui-gc">
				<div class="yui-u first">
					<h1>{userInfo.firstName+" "+userInfo.middleName+" "+userInfo.lastName}</h1>
					<h4>{userInfo.profession}</h4>
				</div>

				<div class="yui-u">
					<div class="contact-info">
						<h3><a href="mailto:name@yourdomain.com">{userInfo.email}</a></h3>
						<h3>{userInfo.phoneNumber}</h3>
					</div> 
				</div>
			</div> 
		</div> 

		<div id="bd">
			<div id="yui-main">
				<div class="yui-b">

                <div class="yui-gf">
						<div class="yui-u first">
							<h4>አድራሻ</h4>
						</div>
						<div class="yui-u">
							<p class="enlarge">
                                {userInfo.country+'  '+userInfo.region+' '+userInfo.city+' '+userInfo.subCity+' '+userInfo.kebele}
							</p>
						</div>
					</div> 

						<div class="yui-u">

								<div class="talent">
									<h4>የሚችሉት ቋንቋ</h4>
									<p>{userInfo.language}</p>
								</div>

								<div class="talent">
									<h4>ህይማኖት</h4>
									<p>{userInfo.religion}</p>
								</div>

								<div class="talent">
									<h4>የጋብቻ ሁኔታ</h4>
									<p>{userInfo.maritalStatus}</p>
								</div>
						</div>
                        <div class="yui-gf">
						<div class="yui-u first">
							<h4>ደንበኛ ባንክ</h4>
						</div>
						<div class="yui-u">
							<p class="enlarge">
                                {userInfo.bank}<br/>{userInfo.bankAccountNumber}
							</p>
						</div>
					</div> 


					<div class="yui-gf">
	
						<div class="yui-u first">
							<h4>ጤና ተኮር መረጃ</h4>
						</div> 
						<div class="yui-u">

								<div class="talent">
									<h4>ክብደት  </h4>
									<p>{userInfo.weight}</p>
								</div>

								<div class="talent">
									<h4>የደም አይነት</h4>
									<p>{userInfo.bloodType}</p>
								</div>

								<div class="talent">
									<h4>ቁመት  </h4>
									<p>{userInfo.hight}</p>
								</div>
						</div>



                        <div class="yui-u first">
							<h4>ትምህርት</h4>
						</div>
						<div class="yui-u">
							<h3>{userInfo.education} </h3>
						</div>



					</div> 

					<div class="yui-gf">
	
						<div class="yui-u first">
							<h4>ችሎታ</h4>
						</div> 
					
                    	<div class="yui-u">
							<p class="enlarge">
                                {userInfo.skill}
							</p>
						</div>
                	</div> 
                    <div class="yui-gf">
	
    <div class="yui-u first">
        <h4>ስኬት</h4>
    </div> 

    <div class="yui-u">
        <p class="enlarge">
            {userInfo.achievement}
        </p>
    </div>
</div> 


<div class="yui-gf">
	
    <div class="yui-u first">
        <h4>የሥራ ልምድ</h4>
    </div> 

    <div class="yui-u">
        <p class="enlarge">
            {userInfo.workExperience}
        </p>
    </div>
</div> 

<div class="yui-gf">
	
    <div class="yui-u first">
        <h4>የትርፍ ጊዜ ማሳለፊያዎች</h4>
    </div> 

    <div class="yui-u">
        <p class="enlarge">
            {userInfo.hobbies}
        </p>
    </div>
</div> 



				</div> 
			</div>
      </div> 

		<div id="ft">
			<p>EplusApp S.C &mdash; <a href="mailto:name@yourdomain.com">info@eplusapp.et</a> &mdash; 2015 E.C</p>
		</div>

	</div>


</div>





    </div>


    </div>
  )
}

export default Download