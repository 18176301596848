import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@mui/material";
import { ArrowForwardIosRounded, Update, Upload } from "@mui/icons-material";
import {
  Grid,
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  CardActions,
} from "@mui/material";
import { FormControl, InputLabel, Input } from "@mui/material";
import camera from "../../assets/user.png";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setOrg, createOrg } from "../../data/Organization";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { Reset } from "../../data/Organization";
import axios from "axios";
import api from "../../data/api";
import SnackBar from "../../components/SnackBar";
import GoogleMapReact from "google-map-react";
import _ from "lodash";
import { motion, AnimatePresence } from "framer-motion";

const baseURL = api.address;
const GOOGLE_API_KEY = "AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo";
const Org1 = () => {
  const defaultProps = {
    center: {
      lat: 11.589712,
      lng: 37.388473,
    },
    zoom: 13,
  };
  const nav = useNavigate();
  const [load, setload] = useState(false);
  const disp = useDispatch();
  // the reference to the store data
  const file = useRef();
  const address_code = useRef();

  const Organ = useSelector((state) => state.org);
  const user = useSelector((state) => state.logedUser);
  const org = Organ.Org;
  const regions = [
    "አማራ",
    "አዲስ አበባ",
    "አፋር",
    "ኦሮሚያ",
    "ቤንሻንጉል-ጉማዝ",
    "ድሬ ዳዋ",
    "የጋምቤላ ህዝቦች",
    "ሃራሪ ህዝብ",
    "ኦሮሚያ",
    "ሲዳማ",
    "ሶማሌ",
  ];
  const [snack, setsnack] = useState({
    Type: "info",
    msg: "everything is fine!",
    show: false,
  });
  const [show, setshow] = useState(camera);
  const [pos, setPos] = useState({ top: 0, left: 0 });
  const [center, setCenter] = useState(null);

  const mapStyle = {
    height: "50%",
    border: "1.3px solid #FF9900",
  };

  useEffect(() => {
    disp(Reset());
    handleLocationClick();
  }, []);
  const UploadLogo = async (id)=>{
    let formData = new FormData();
    formData.append('logo',file.current.files[0]);
    formData.append('orgId',id);
    console.log(formData.getAll("orgId"));
    await axios.post(`${baseURL}/addressapi/files/uploadfile`,formData,{
        headers: {
            'Authorization': `Bearer ${user.user}`
          }                   
    })
    .then((result) => {
        console.info(result)
        nav("../view", { state: { id: id } });
    }).catch((err) => {
        console.info(err)         
    });
    
  }

  useEffect(() => {
    if (Organ.isloading) setload(true);
    else if (Organ.createStatus == "success") {
      setload(false);
      console.log("ID", Organ.resp.data?.id);
      let id = Organ.resp.data?.id;

      UploadLogo(id);






    } else if (Organ.createStatus == "failed") {
      setload(false);
      setsnack({
        Type: "warning",
        msg: "አስፈላጊ መረጃዎችን በትክክል ያስገቡ ፤ ከዚ በፊት ያልተጠቀሙትን የደርጅት ኢሜል ያስገቡ!",
        show: true,
      });
    } else {
      setload(false);
    }
  }, [Organ]);

  function handleLocationClick() {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      searchPlace(position.coords.latitude, position.coords.longitude);
      addressDetail(position.coords.latitude, position.coords.longitude);
      // setCenter({ lat: 11.594502, lng: 37.388324 });
    });
  }

  const Organization = useSelector((state) => state.org);

  // to search th eplace using latitude and longitude coordinates
  function searchPlace(lat, lng) {
    axios
      .post("http://localhost:11215/addressapi/subcities/search-address", {
        lat: lat,
        lng: lng,
      })
      .then((response) => {
        let data = response.data;

        if (!_.isEqual(data, [])) {
          let data = response.data;

          let keb = data.cities[0].kebele;
          keb = keb[0].toUpperCase() + keb.slice(1);
          let sub = data.cities[0].sub_city;
          sub = sub[0].toUpperCase() + sub.slice(1);
          // set the street name after checking if their is nearest street or not
          let street = "";
          if (data.street.length > 0) {
            street = data.street[0].road_name;
            street = street[0].toUpperCase() + street.slice(1);
            // set the street name after checking if their is nearest street or not
            disp(setOrg({ filed: "streetNumber", value: street }));
          }

          let w_grid = data.grid.w_grid;
          let h_grid = data.grid.h_grid;

          let pid =
            data.grid.pid +
            w_grid.slice(0, 1) +
            "-" +
            w_grid.slice(1, w_grid.length) +
            "-" +
            h_grid;

          // set the address, kebele and sub city values
          disp(setOrg({ filed: "address", value: pid }));
          disp(setOrg({ filed: "subCity", value: sub }));
          disp(setOrg({ filed: "kebele", value: keb }));

          lat = parseFloat(lat);
          lng = parseFloat(lng);

          setCenter({ lat, lng });
        } else {
          console.log(
            "You have to select your organization location on the map"
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const submit = (e) => {
    const token = user.user;

    disp(createOrg({ org: org, token: token },file.current?.files[0]));
  };

  const Update = (e) => {
    let value = e.target.value;
    if (value == "") disp(setOrg({ filed: e.target.name, value: "_" }));
    else disp(setOrg({ filed: e.target.name, value: value }));
  };

  const upload = async (e) => {

    let logoFile = file.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(logoFile);
    reader.addEventListener("load",()=>{
        setshow(reader.result)
    })

 };

  const handleMapClick = (event) => {
    const lat = event.lat.toFixed(6);
    const lng = event.lng.toFixed(6);

    searchPlace(lat, lng);
    addressDetail(lat, lng);
  };

  function addressDetail(lat, lng) {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
          lat + "," + lng
        }&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`
      )
      .then((result) => {
        const country = result.data.results[9].formatted_address;
        let region = result.data.results[1].address_components[3].long_name;
        let woreda = result.data.results[1].address_components[2].short_name;
        const zone = result.data.results[2].address_components[2].long_name;
        const city = result.data.results[0].address_components[2].long_name;

        switch (region) {
          case "Amhara":
            region = "አማራ";
            break;
          case "Afar":
            region = "አፋር";
            break;
          case "Addis Ababa":
            region = "አዲስ አባባ";
            break;
          case "Ormoia":
            region = "ኦሮሚያ";
            break;
          default:
            break;
        }

        disp(setOrg({ filed: "country", value: country }));
        disp(setOrg({ filed: "city", value: city }));
        disp(setOrg({ filed: "region", value: region }));
        disp(setOrg({ filed: "zone", value: zone }));
        disp(setOrg({ filed: "woreda", value: woreda }));
        // console.log(JSON.stringify(result.data.results[1]));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Card>
      <SnackBar snack={snack} />
      <Loading load={load} />
      <CardContent sx={{ width: "100%" }}>
        <Typography variant="h6" color="primary">
          የድርጅትዎ መረጃ በጥንቃቄ ይሙሉ!
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Typography variant="body1">የድርጅት ሙሉ ገለፃ:</Typography>
            <TextField
              fullWidth
              multiline
              rows={5}
              name="description"
              onChange={(e) => Update(e)}
              value={org.description}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Avatar
              alt="Logo"
              src={show}
              sx={{
                width: "5rem",
                height: "5rem",
                margin: "auto",
                marginBottom: "0",
                marginTop: "0px",
              }}
            />
            &nbsp;&nbsp;
            <Button
              onClick={(e) => file.current.click()}
              startIcon={<Upload />}
              variant="outlined"
            >
              ሎጎ አስገባ
            </Button>
            <input
              style={{ display: "none" }}
              onChange={(e) => upload(e)}
              ref={file}
              type="file"
            />
            <input type="text" name="logo" style={{ display: "none" }} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ padding: 2 }}>
            <br />
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">የድርጅት ስም</InputLabel>
              <Input
                name="name"
                onChange={(e) => Update(e)}
                value={org.name}
                id="component-simple"
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ብራንድ</InputLabel>
              <Input
                name="brand"
                onChange={(e) => Update(e)}
                value={org.brand}
                id="component-simple"
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">የግብር ቁጥር</InputLabel>
              <Input
                value={org.tinNumber}
                name="tinNumber"
                type="number"
                onChange={(e) => Update(e)}
                id="component-simple"
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">አድራሻ</InputLabel>
              <Input
                name="address"
                value={org.address}
                onChange={(e) => Update(e)}
                id="component-simple"
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ሀገር</InputLabel>
              <Input
                onChange={(e) => Update(e)}
                value={org.country}
                name="country"
                id="component-simple"
              />
            </FormControl>

            <FormControl
              style={{ width: "100%", margin: 5 }}
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                ክልል
              </InputLabel>
              <Select
                onChange={(e) => Update(e)}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="ክልል"
                value={org.region}
                name="region"
              >
                {regions.map((region) => (
                  <MenuItem key={Math.random()} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ዞን</InputLabel>
              <Input
                name="zone"
                onChange={(e) => Update(e)}
                value={org.zone}
                id="component-simple"
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ወረዳ</InputLabel>
              <Input
                name="woreda"
                onChange={(e) => Update(e)}
                value={org.woreda}
                id="component-simple"
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ከተማ</InputLabel>
              <Input
                name="city"
                onChange={(e) => Update(e)}
                value={org.city}
                id="component-simple"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ padding: 2 }}>
            <br />
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ክፍለ ከተማ</InputLabel>
              <Input
                onChange={(e) => Update(e)}
                name="subCity"
                value={org.subCity}
                id="component-simple"
              />
            </FormControl>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ቀበሌ</InputLabel>
              <Input
                onChange={(e) => Update(e)}
                value={org.kebele}
                name="kebele"
                id="component-simple"
              />
            </FormControl>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">የመንገድ ቁጥር</InputLabel>
              <Input
                onChange={(e) => Update(e)}
                value={org.streetNumber}
                name="streetNumber"
                id="component-simple"
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">
                የአክሲዮን መስራቾች ብዛት
              </InputLabel>
              <Input
                onChange={(e) => Update(e)}
                value={org.stockFounder}
                name="stockFounder"
                id="component-simple"
              />
            </FormControl>

            <FormControl
              style={{ width: "100%", margin: 5 }}
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                የስራ አይነት
              </InputLabel>
              <Select
                onChange={(e) => Update(e)}
                value={org.type}
                name="type"
                id="demo-simple-select-standard"
              >
                <MenuItem key={Math.random()} value=""></MenuItem>
                <MenuItem key={Math.random()} value="plc">
                  ፒ.ኤል.ሲ
                </MenuItem>
                <MenuItem key={Math.random()} value="sc">
                  ስቶክ
                </MenuItem>
                <MenuItem key={Math.random()} value="partners">
                  ሽርክና
                </MenuItem>
                <MenuItem key={Math.random()} value="school">
                  ትምህርት ቤት
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">የስራ መስክ</InputLabel>
              <Input
                onChange={(e) => Update(e)}
                value={org.businessSector}
                name="businessSector"
                id="component-simple"
              />
            </FormControl>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ባንክ</InputLabel>
              <Input
                name="bank"
                onChange={(e) => Update(e)}
                value={org.bank}
                id="component-simple"
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">የአክሲዮን ብዛት</InputLabel>
              <Input
                name="numberOfStocks"
                type="number"
                onChange={(e) => Update(e)}
                value={org.numberOfStocks}
                id="component-simple"
              />
            </FormControl>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">
                አሁን ያለው የአክሲዮን ብዛት
              </InputLabel>
              <Input
                name="currentStock"
                type="number"
                onChange={(e) => Update(e)}
                value={org.currentStock}
                id="component-simple"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ padding: 4 }}>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ኢሜል</InputLabel>
              <Input
                variant="outlined"
                name="email"
                id="component-simple"
                onChange={(e) => Update(e)}
                value={org.email}
              />
            </FormControl>

            <FormControl style={{ width: "100%" }} variant="standard">
              {/* <InputLabel htmlFor="component-simple">የተቋቋመበት ጊዜ</InputLabel> */}
              <InputLabel htmlFor="component-simple"></InputLabel>
              <Input
                name="establishment"
                id="component-simple"
                type="date"
                onChange={(e) => Update(e)}
                value={org.establishment}
              />
            </FormControl>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">ካፒታል</InputLabel>
              <Input
                name="capital"
                type="number"
                id="component-simple"
                onChange={(e) => Update(e)}
                value={org.capital}
              />
            </FormControl>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">አሁን ያለው ካፒታል</InputLabel>
              <Input
                name="currentCapital"
                type="number"
                id="component-simple"
                onChange={(e) => Update(e)}
                value={org.currentCapital}
              />
            </FormControl>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="component-simple">የተቀጣሪዎች ብዛት</InputLabel>
              <Input
                name="employees"
                type="number"
                id="component-simple"
                onChange={(e) => Update(e)}
                value={org.employees}
              />
            </FormControl>
            <br />
            <AnimatePresence>
              <motion.div whileHover={{ scale: 1.85 }} style={mapStyle}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: GOOGLE_API_KEY,
                    libraries: ["visualization"],
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  center={center}
                  onClick={handleMapClick}
                  // yesIWantToUseGoogleMapApiInternals
                  // onGoogleApiLoaded={({ map, maps }) =>
                  //   handleApiLoaded(map, maps)
                  // }
                  options={(maps) => ({
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                      position: maps.ControlPosition.TOP_RIGHT,
                    },
                  })}
                >
                  {/* <Marker lat={mapCenter.lat} lng={mapCenter.lng} /> */}
                  {/* <Polygons map={map} maps={maps} paths={polygons} /> */}
                </GoogleMapReact>
              </motion.div>
            </AnimatePresence>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ paddingLeft: 20 }}>
        <Link to="#">
          <Button
            size="large"
            endIcon={<ArrowForwardIosRounded />}
            onClick={submit}
            variant="contained"
          >
            አስገባ እና ቀጥል
          </Button>
        </Link>
      </CardActions>
      <br />
      <br />
    </Card>
  );
};

export default Org1;
