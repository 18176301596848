import { useEffect, useState } from 'react';
import { Box,AppBar,Button,Card,Container,Toolbar, Typography, CardActions, Dialog, DialogTitle, DialogContent, FormGroup, IconButton} from '@mui/material'
import logo from "../assets/circle.png"
import style from '../styles';
import define from '../strings';
import {Grid,FormControl,InputLabel,Input,Select,MenuItem} from '@mui/material';
import Break_point from '../breakpoint';
import UserAppbar from './userAppBar';
import {Avatar} from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UploadAvt from './UploadAvt';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import {change,fetchUser,getuser} from '../data/userSlice'
import { AddAPhoto, AddLocation, AddLocationAlt, Map, MapRounded, Upload } from '@mui/icons-material';
import Loading from '../components/Loading';
import { UpdateUserInfo,updateprivateInfo } from '../data/updateUser';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import api from '../data/api';
const steps = [
  "የመጀመርያ ደረጃ",
  'ሁለተኛ ደረጃ',
  'ሶስተኛ ደረጃ',
  'አራተኛ ደረጃ',
];

const Welcome = () => {


            // {ALL States}
        const user = useSelector((state)=>state.updateuser)
        const userId = useSelector((state)=>state.logedUser.id)
        const [userimg,setuserimg] = useState(false)
        const [step,setstep] = useState(1);
        const [lang,setlang] = useState(define.Am)
        const [load,setload] = useState()
        const [showAv,setAv] = useState("")
        const [center,setCenter] = useState({lat:11.594502,lng:37.388324})
        const run = useDispatch();
        const [zoomErr,setZoomErr] = useState(false)
        const [mapShow,setMapShow] = useState(false);
        const [msg,setMsg] = useState("")


        useEffect(()=>{

            if(user.isLoading)
            setload(true)
            else 
            setload(false)
            

        },[user])


useEffect(()=>{

  (async()=>{
    setMsg("Loading GPS.........")
    if(navigator.geolocation)
    {
     let data = await navigator.geolocation.getCurrentPosition(async (postion)=>{

      setTimeout(()=>{
        setCenter({lat:postion.coords.latitude,lng:postion.coords.longitude}); 
        //setCenter({lat: 37.36226248674476,lng: 11.598583213738523})
   
        setMsg("");
    },1000)

      return {lat:postion.coords.latitude,lng:postion.coords.longitude};

      })
      console.log(data)

    }
    else
    alert("አባክዎ Location Allow ይበሉት!")
  })()

},[])

useEffect(()=>{

(async ()=>{


  
  
  setMsg("Connect to geocoding server.....")
  run(UpdateUserInfo({name:"latitude",data:center.lat}));
  run(UpdateUserInfo({name:"longitude",data:center.lng}));
  axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat+","+center.lng}&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`)
  .then((result) => {
    setMsg("")
   setMapShow(false)
     run(UpdateUserInfo({name:"country",data:result.data.results[9].formatted_address}));
    run(UpdateUserInfo({name:"region",data:result.data.results[2].address_components[3].long_name}));
    run(UpdateUserInfo({name:"city",data:result.data.results[2].address_components[2].long_name}));
    run(UpdateUserInfo({name:"subCity",data:result.data.results[7].address_components[0].long_name}));

    

    axios.post(api.address+"/addressapi/subcities/get-location",{lat:37.36226248674476,lng:11.598583213738523})
    .then((result) => {
      console.log(result.data)
    }).catch((err) => {
      console.log(err)
      
    });
  


  }).catch((err) => {
    console.log(err)
    setMsg("Network error with connect to geocoding server")
  });


})()

},[center])







const InputEv = e =>{
       
  run(UpdateUserInfo({name:e.target.name,data:e.target.value}));

}
const InputEv1 = e =>{
       
  setMapShow(true)

  e.target.focus = false;

}


  const renderMarkers = (map, maps) => {
   let marker = new maps.Marker({
    position: center,
    map,
    title: 'Hello World!'
    });
    return marker;
  }

  return (
    <div style={{backgroundColor:'#edeef2',height:'100vh'}}>
      <UploadAvt av={[showAv,setAv]} value={[userimg,setuserimg]} />
        <UserAppbar  av={[showAv,setAv]} />
        <Loading load={load}/>
        <Dialog
            open={mapShow}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            onClose={e=>setMapShow(false)}
            fullWidth={true}
        >
          <DialogTitle><Typography variant='h5'>የ እርሶን ቦታ ይጠቁሙ?</Typography>
          <Typography>የ አረሶ ቦታ ላይ ሲደርሱ ሁለት ጊዜ ክሊክ ያድርጉ</Typography>
          {zoomErr?<Typography variant='body1' color="error">አባክዎ በደንብ ያቅርቡት/ Zoom ይጨምሩት</Typography>:""}
          </DialogTitle>
          <DialogContent sx={{height:"50vh"}}>
          <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo" }}
        defaultCenter={center}
        defaultZoom={16}
        onGoogleApiLoaded={({map,maps})=>{

            map.setMapTypeId(maps.MapTypeId.HYBRID);
            map.setOptions({disableDoubleClickZoom: true });

            maps.event.addListener(map, 'dblclick',function(e){

              let zoom = map.getZoom();

              console.log(zoom)

              if(zoom < 20)
              setZoomErr(true)
              else
              {
                setZoomErr(false)
                setCenter(JSON.parse(JSON.stringify(e.latLng)));
                
                var marker = new maps.Marker({
                  position: e.latLng, 
                  map: map
              });
              }    
              


            })

        }}

        >

        </GoogleMapReact>
          </DialogContent>


        </Dialog>
<Container sx={{paddingTop:"30px"}}> 
    <Card sx={{padding:"8vh"}}      initial={{opacity:0}}  animate={{opacity:1}} component={motion.div}>
    <Box sx={{ width: '100%',margin:"10px" }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>

      <Typography color='secondary' variant='h5'> {user.firstName} ወቅታዊ አቋም መለክያ </Typography> 
<Typography>{msg}</Typography>
      <hr />
    <br />
    <Grid container spacing={3} sx={{width:"auto"}}>

    <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
      <Typography variant='h6' color={style.colors.high}>የግል መረጃ</Typography>
        <br />
      <Avatar alt="" sx={{height:"5rem",width:"5rem",display: 'flex',flexDirection: 'inherit',justifyContent: 'center',alignItems: 'center',alignContent: 'center',position: 'relative',left: '25%'}} src={showAv} >
        </Avatar>
       <div onClick={()=>setuserimg(true)} style={{display: 'flex',flexDirection: 'row',justifyContent: 'start',paddingLeft: '25%'}}>
        <AddAPhoto size="large" style={{cursor:"pointer"}}/>
        <InputLabel htmlFor="component-simple">&nbsp;&nbsp;ፎቶ ያስገቡ</InputLabel>
        </div>

      <br />


      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የአያት ስም፡</InputLabel>
        <Input name='lastName' value={user.lastName} onChange={InputEv} id="component-simple"  />
      </FormControl> 




      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የእናት ስም፡</InputLabel>
        <Input name="motherName" value={user.motherName} id="component-simple" onChange={InputEv}  />
      </FormControl> 

      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የእናት የአባት ስም፡</InputLabel>
        <Input name="motherlastName" value={user.motherlastName} id="component-simple" onChange={InputEv}  />
      </FormControl> 


      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ስልክ ቁጥር 09፡</InputLabel>
        <Input name='phoneNumber' placeholder='09...' type='number' value={user.phoneNumber} id="component-simple" onChange={InputEv}  />
      </FormControl> 


    </Grid>

    <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
      <Typography variant='h6' color={style.colors.high}>አድራሻ</Typography>
      
      <FormControl style={{margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">አሀጉር፡</InputLabel>
        <Input name='continent' value={user.continent} id="component-simple" onChange={InputEv} />
      </FormControl> 

          <FormGroup row>
      <FormControl style={{margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ሃገር፡</InputLabel>
        <Input name='country' value={user.country} id="component-simple" onChange={InputEv} />
      </FormControl> 
      <IconButton onClick={e=>setMapShow(true)}><AddLocationAlt/></IconButton>
      </FormGroup>
      <FormGroup row>
      <FormControl style={{margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ክልል:</InputLabel>
        <Input  id="component-simple" onChange={InputEv} 
                  name='region'
                  value={user.region}
        />
      </FormControl> 
      <IconButton onClick={e=>setMapShow(true)}><AddLocationAlt/></IconButton>
      </FormGroup>

      <FormGroup row>
      <FormControl style={{margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ከተማ/ዞን፡</InputLabel>
        <Input name='city' value={user.city} id="component-simple" onFocus={InputEv1} />
      </FormControl> 
      <IconButton onClick={e=>setMapShow(true)}><AddLocationAlt/></IconButton>
      </FormGroup>



      <FormGroup row>
      <FormControl style={{margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ክ ከተማ/ወረዳ፡</InputLabel>
        <Input name='subCity' value={user.subCity} id="component-simple" onChange={InputEv} />
      </FormControl> 
      <IconButton onClick={e=>setMapShow(true)}><AddLocationAlt/></IconButton>
      </FormGroup>


      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ቀበሌ፡</InputLabel>
        <Input name='kebele' value={user.kebele} id="component-simple" onChange={InputEv} />
      </FormControl> 







    </Grid>

    <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
    <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የሚችሉት ቋንቋ፡</InputLabel>
        <Input name='language' value={user.language} id="component-simple" onChange={InputEv} />
      </FormControl> 

      <Typography variant='h6' color={style.colors.high}>ልዩ መረጃዎች</Typography>
      
      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የሚከተሉት ሃይማኖት፡</InputLabel>
        <Input name='religion' value={user.religion} id="component-simple" onChange={InputEv} />
      </FormControl> 


      <FormControl style={{width:"100%",margin:5}} variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">የጋብቻ ሁኔታ፡</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          label="ክልል"
          name='maritalStatus' 
          value={user.maritalStatus} 
          id="component-simple"
           onChange={InputEv} 
                >
              <MenuItem value="ያገባ">ያገባ</MenuItem>
              <MenuItem value="ያላገባ">ያላገባ</MenuItem>
              <MenuItem value="የተፋታ">የተፋታ</MenuItem>
        </Select>
      </FormControl>











      
      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የልደት ቀን፡</InputLabel>
        <Input name='dateOfBirth' autoFocus type='date' value={user.dateOfBirth} id="component-simple" onChange={InputEv} />
      </FormControl> 

      
      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">ደንበኛ ባንክ፡</InputLabel>
        <Input name='bank' value={user.bank} id="component-simple" onChange={InputEv} />
      </FormControl> 


      
      <FormControl style={{width:"100%",margin:5}} variant="standard">
        <InputLabel htmlFor="component-simple">የሂሳብ ቁጥር፡</InputLabel>
        <Input id="component-simple" value={user.bankAccountNumber} name='bankAccountNumber' onChange={InputEv} />
      </FormControl> 

    </Grid>





</Grid>
<CardActions sx={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
<Link></Link>
<Link to="/welcome/nextForm">
      <Button variant="contained" endIcon={<ArrowForwardIosIcon/>} size="large" >ቀጣይ</Button>
      </Link>
</CardActions>
    </Card>
</Container>


<Break_point/>

    </div>
    )
}

export default Welcome