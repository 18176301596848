import bg from "./assets/bg.png";
import bg2 from "./assets/web.png"
const colors =  {
    primary:"#0099ff",
    secondary:"#fe9900"
}


const style = 
{
   colors : {
        primary:"#0099ff",
        secondary:"#fe9900",
        high:"#6868683d",
        high1:"#686868d9",
        high2:"#646464"
    },
    sidebar : {
        height:"100%"

    },
    main:{
        backgroundImage:"url("+bg2+")",
        height: '100vh',
        backgroundPositionX: 'right',
        backgroundSize: '38%',
        backgroundRepeat: 'no-repeat'
    },
    logoText :{
            mr: 2,
            display: { xs: '', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 900,
            color: 'inherit',
            textDecoration: 'none',
            fontFamily:"Ny",
            flexGrow:1,

            },
            title:{
                display:{xs:'none',sm:'block',md:'block',lg:'block',xl:'block'},
                width: {xs:'13rem',sm:'13rem',md:'23rem',lg:'25rem',xl:'23rem'},
                textAlign: 'center',
                fontSize:{xs:"0.9rem",sm:"1.5rem",md:"2.5rem",lg:'2.5rem',xl:'3rem'},
                margin: '1rem',
                position:"absolute",
                zIndex:13,
                lineHeight: {xs:'0.9',sm:'1',md:'1',lg:'1',xl:'1'},
                top:{
                    xs:'12%',
                    lg:"20%",
                    xl:"32%"
                }
                },
                title1:{
                    display:{xs:'none',sm:'block',md:'block',lg:'block',xl:'block'},
                    width: {xs:'15rem',sm:'20rem',md:'23rem',lg:'23rem',xl:'23rem'},
                    textAlign: 'center',
                    lineHeight: {xs:'0.9',sm:'1',md:'1',lg:'1',xl:'1'},
                    fontSize:{xs:"0.9rem",sm:"1.5rem",md:"2rem",lg:'2rem',xl:'2rem'},
                    margin: '1rem',
                    position:"absolute",
                    zIndex:13,
                    top:{xs:"12%",
                        lg:"22%",
                        xl:"32%"
                    },
                    left:{xl:"4%",lg:"4%",md:0,sm:0,xs:0}
                    },
                subtitle:{
        textAlign: "center",width: "40rem"
             },
    loginCard:{
        padding:{xs:0,sm:0,md:10,lg:5,xl:10},
        width: {
            xs:"20rem",
            sm:"20rem",
            md:"28rem",
            lg:'28rem',
            xl:'28rem',
            },
        position: 'absolute',
        top:{
            xs:"20%",
            sm:"20%",
            md:"20%",
            lg:"20%",
            xl:"25%"
                },
        boxShadow: {            
        xs:'none',
        sm:'none',
        md:'1px 1px 8px 0px #0099ff',
        lg:'1px 1px 8px 0px #0099ff',
        xl:'1px 1px 8px 0px #0099ff'
}
        ,
        left: {
            xs:'5%',
            sm:'10%',
            md:'35%',
            lg:'42%',
            xl:'42%'
    },
        border: {
            xs:'none',
            sm:'none',
            md:'solid 1px #0099ff',
            lg:'solid 1px #0099ff',
            xl:'solid 1px #0099ff'
        },
        marginBottom:"15px"
},
regCard:{
    padding:{xs:0,sm:0,md:5,lg:5,xl:10},
    paddingBottom:0,
    width: {
        xs:"20rem",
        sm:"20rem",
        md:"28rem",
        lg:'48rem',
        xl:'48rem',
        },
    position: 'absolute',
    top:{
        xs:"20%",
        sm:"20%",
        md:"10%",
        lg:"5%",
        xl:"5%"
            },
    boxShadow: {            
    xs:'none',
    sm:'none',
    md:'1px 1px 8px 0px #0099ff',
    lg:'1px 1px 8px 0px #0099ff',
    xl:'1px 1px 8px 0px #0099ff'
}
    ,
    left: {
        xs:'5%',
        sm:'10%',
        md:'35%',
        lg:'36%',
        xl:'36%'
},
border: {
    xs:'none',
    sm:'none',
    md:'solid 1px #0099ff',
    lg:'solid 1px #0099ff',
    xl:'solid 1px #0099ff'
},


}



}
export default style;