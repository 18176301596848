import React, { useEffect } from 'react'
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Home from './userComponents/Main';
import Org1 from './userComponents/OrgForm/Org1';
import Org2 from './userComponents/OrgForm/Org2';
import Org3 from './userComponents/OrgForm/Org3';
import Org4 from './userComponents/OrgForm/Org4';
import Org5 from './userComponents/OrgForm/Org5';
import Org6 from './userComponents/OrgForm/Org6';
import Org7 from './userComponents/OrgForm/Org7';
import Org8 from './userComponents/OrgForm/Org8';
import Org9 from './userComponents/OrgForm/Org9';
import Org10 from './userComponents/OrgForm/Org10';
import App from './App';
import Reg from './reg';
import Welcome from './userComponents/WelcomeComp';
import PrivateInfo from './userComponents/PrivateInfo';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Org0 from './userComponents/OrgForm/Org0';
import View from './userComponents/OrgForm/View';
import Error from './Error';
import Test from './Test';
import Show from './Show';
import Detail from './Detail';
import Download from './userComponents/download';
import Result from './Result';
import UserDetail from './UserDetail';
import Lock from './Lock';
import Promromtion from './userComponents/Promromtion';
import PostMan from './postMan';
import RegPlace from './userComponents/regPlace';
import Techerst from './userComponents/School/routes/Techerst';
import Dashboard from './userComponents/School/routes/Dashboard';
import Employers from './userComponents/School/routes/Employers';
import SchoolDetail from './data/school/schoolDetail';
import EmpList from './userComponents/School/routes/EmpList';
import SchoolAdminstartion from './userComponents/School/routes/schoolAdminstartion';
import StudentList from './userComponents/School/routes/StudentList';
const StartUp = () => {

  const login = useSelector((state)=>state.logedUser.isLoged);
  
  
    
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/postman' element={<PostMan/>} />
    <Route path='*' element={<Error/>} />
    <Route index path='/' element={login?<Home/>:<App/>}/>
    <Route index path='/search' element={<Show/>}/>
    <Route index path='/search/result' element={<Result/>}/>
    <Route index path='/detail' element={<Detail/>}/>
    <Route index path='/userdetail' element={<UserDetail/>}/>
    <Route index path='/login' element={login?<Home/>:<App/>}/>
    <Route path='/reg' element={login?<Reg/>:<Reg/>}/>
    <Route path='/test' element={<Test/>}/>
    <Route path='/welcome' element={login?<Welcome/>:<App/>} />
    <Route path='/welcome/nextForm' element={login?<PrivateInfo/>:<App/>} />
    <Route path='/download' element={login?<Download/>:<App/>}/>
    <Route path='/school' element={<SchoolDetail/>} />
    <Route path='/main' element={login?<Home/>:<App/>} />
          <Route path='/main/org' element={login?<Home/>:<App/>}>   
          <Route path='/main/org/0' element={login?<Org0/>:<App/>} />
         
          <Route path='/main/org/view' element={login?<View/>:<App/>} >
            <Route path='' element={<Dashboard/>} />
            <Route path='/main/org/view/employers' element={<Employers/>}/>
            <Route path='/main/org/view/list' element={<EmpList/>} />
            <Route path='/main/org/view/administrations' element={<SchoolAdminstartion/>} />           
            <Route path='/main/org/view/studentlist' element={<StudentList/>} />
           
            </Route>
          
          <Route path='/main/org/1' element={login?<Org1/>:<App/>} />  
          <Route path='/main/org/2' element={login?<Org2/>:<App/>} />  
          <Route path='/main/org/3' element={login?<Org3/>:<App/>} />  
          <Route path='/main/org/4' element={login?<Org4/>:<App/>} />  
          <Route path='/main/org/5' element={login?<Org5/>:<App/>} />  
          <Route path='/main/org/6' element={login?<Org6/>:<App/>} />  
          <Route path='/main/org/7' element={login?<Org7/>:<App/>} />  
          <Route path='/main/org/8' element={login?<Org8/>:<App/>} />  
          <Route path='/main/org/9' element={login?<Org9/>:<App/>} />  
          <Route path='/main/org/10' element={login?<Org10/>:<App/>} />  
            </Route>
    </Routes>
  </BrowserRouter>
    )
}

export default StartUp