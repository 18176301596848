import React from 'react'
import {Button, ButtonGroup, TextField} from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createTef } from '../../data/OrgDetail'
import { useEffect } from 'react'
import Loading from '../../components/Loading'
import { useState } from 'react'
const Org2 = () => {

      const {register,handleSubmit,formState:{errors}} = useForm()
      const {state} = useLocation();
      const run = useDispatch();
      const user = useSelector((state)=>state.logedUser)
      const detail = useSelector((state)=>state.orgDetail)
      const [load,setload] = useState(false);
      const link = useNavigate()


      const submit = data =>{
            data['orgId'] = state.id;
          let token = user.user
            run(createTef({teftef:data,token:token}))
      }

useEffect(()=>{

      if(detail.isloading)
      setload(true) 
      else if(detail.createStatus == "success")
      {
            link('../view',{state:{id:state.id}});
            setload(false)

      }
      else 
      setload(false)


},[detail])


  return (

    <Card>
      <Loading load={load}/>
    <CardContent sx={{width:"100%"}}>
    <Grid container >
    <form onSubmit={handleSubmit(submit)}>       
        <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} sx={{padding:4}}>
        <Typography variant='h6' color="primary">የድርጅቱ ተዕፍ ተዕፍ መረጃዎች!</Typography>
        <Typography variant='body1' color='error'>{errors.hope?.message}</Typography>
        <TextField
          id="standard-multiline-static"
          label="ተስፋ"
          {...register("hope",{required:{
            value:true,
            message:"ተስፋ አስፈላጊ መረጃ ነው"
          }})}
          multiline
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
<Typography variant='body1' color='error'>{errors.belief?.message}</Typography>
<TextField
          id="standard-multiline-static"
          label="እምነት"
          multiline
          {...register("belief",{required:{
            value:true,
            message:"እምነት አስፈላጊ መረጃ ነው"
          }})}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
<Typography variant='body1' color='error'>{errors.love?.message}</Typography>
<TextField
          id="standard-multiline-static"
          label="ፍቅር"
          {...register("love",{required:{
            value:true,
            message:"እምነት አሰፈላጊ መረጃ ነው"
          }})}
          multiline
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

<Typography variant='h6' color="primary">ወፍ መረጃ!</Typography>
<TextField
          id="standard-multiline-static"
          label="ወጭ"
          multiline
          {...register("cost")}

          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
<TextField
          id="standard-multiline-static"
          label="ፍጆታና"
          multiline
          {...register("consumption")}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
<TextField
          id="standard-multiline-static"
          label="ዋና አላማ"
          multiline
          {...register("mainObjective")}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
<TextField
          id="standard-multiline-static"
          label="ዋና አላማ"
          multiline
          {...register("specificObjective")}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

     
    </Grid>

    <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} sx={{padding:4}}>
    <Typography variant='h6' color="primary">እሴቶች እና መርሆች!</Typography>
    <TextField
          id="standard-multiline-static"
          label="ዕሴቶች"
          {...register("value")}
          multiline
          rows={3}
          variant="standard"
          style={{width:"100%"}}
          />
          <TextField
                id="standard-multiline-static"
                label="ግብ"
                {...register("goal")}
                multiline
                rows={3}
                variant="standard"
                style={{width:"100%"}}
                />
          
    <TextField
          id="standard-multiline-static"
          label="ራዕይ"
          {...register("vision")}
          multiline
          rows={3}
          variant="standard"
          style={{width:"100%"}}
          />
                
    <TextField
          id="standard-multiline-static"
          label="ተልዕኮ"
          {...register("mission")}
          multiline
          rows={3}
          variant="standard"
          style={{width:"100%"}}
          />
                
    <TextField
          id="standard-multiline-static"
          label="መርሆች"
          {...register("principle")}
          multiline
          rows={3}
          variant="standard"
          style={{width:"100%"}}
          />
    <TextField
          id="standard-multiline-static"
          label="መሪ ድምፅ"
          multiline
          {...register("voice")}
          rows={3}
          variant="standard"
          style={{width:"100%"}}
          />
    <TextField
          id="standard-multiline-static"
          label="መሪ ቃላት"
          multiline
          {...register("motto")}
          rows={3}
          variant="standard"
          style={{width:"100%"}}
          />
          <br /><br />
    </Grid>
    <Button size='large' type='submit' endIcon={<ArrowForwardIosRounded/> } variant="contained">ቀጣይ</Button>
    </form>
    </Grid>
    </CardContent>
    <CardActions sx={{paddingLeft:20}}>
             &nbsp;
             

    </CardActions>
</Card>

    )
}

export default Org2