import React, { useEffect, useState } from 'react'
import { List, Button, Box, Grid, Paper, TextField, Typography, ButtonGroup, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from '@mui/material'
import lang from '../lang.json'
import { Add, DeleteForever, HomeSharp } from '@mui/icons-material'
import CourseTable from '../component/CourseTable'
import axios from 'axios'
import api from '../../../data/api'
import { useSelector } from 'react-redux'
const SchoolAdminstartion = () => {
    const text = lang[0]
    const [course,setCourse] = useState({})
    const userLoged = useSelector((state)=>state.logedUser)
    const [courseList,setCoursesList] = useState([])

    useEffect(()=>{
        (async ()=>{

            await axios.get(`${api.address}/addressapi/courses/all`)
            .then((result) => {
                setCoursesList(result?.data)
            }).catch((err) => {
                
            });

        })()

    },[])

  return (
    <Box sx={{padding:1}}>
            <Grid container>
            <Grid item md={3}>
                            <Paper sx={{padding:2}} elevation={2}>
                                <Typography variant='h6' color="GrayText">{text.rooms}</Typography>    
                                <ButtonGroup>
                                <TextField
                                placeholder='Rooms'
                                variant='filled'
                                />
                                <Button size='small' variant='contained'>Add</Button>
                                </ButtonGroup>
                                <List>
                                    
                                    
                                    {[1,2,3,4,5].map((number)=>(<ListItem>
                                        <ListItemIcon>
                                            <HomeSharp/>
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography> Room {number}</Typography>} />
                                        <ListItemText primary={<IconButton><DeleteForever/></IconButton>} />
                                    </ListItem>))
}

                                </List>
                            </Paper>
                </Grid>
                    <Grid item md={6}>
                            <Paper sx={{padding:2}} elevation={2}>
                            <Typography variant='h6' color="GrayText">{text.courses}</Typography>    
                            <TextField fullWidth value={course?.courseName} onChange={e=>setCourse({...course,courseName:e.target.value})} placeholder={text.courseName} sx={{margin:1}}/>
                            <TextField fullWidth sx={{margin:1}} value={course?.gradeLevel} onChange={e=>setCourse({...course,gradeLevel:e.target.value})} placeholder={text.gradeLevel}/>
                            <Button onClick={async e=>{

                                    console.log(course)
                                await axios.post(`${api.address}/addressapi/courses/create`,course,{
                                    headers:{
                                        Authorization:`Bearer ${userLoged.user}`
                                    }
                                })
                                .then((result) => {
                                    setCoursesList([...courseList,result?.data])
                                }).catch((err) => {
                                    
                                });
                            }} variant='contained' startIcon={<Add/>} sx={{marginLeft:30}}>{text.addCourse}</Button>
                            <br /><br />
                            <Divider/>
                            <br />
                            <CourseTable data={courseList}/>
                            </Paper>
                    </Grid>
                </Grid>
    </Box>
  )
}

export default SchoolAdminstartion