// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-UserCard-UserCardStats-styles-module__user-card-stats--bnwQi{display:flex;flex-flow:row;justify-content:center;font-size:.75rem}.src-UserCard-UserCardStats-styles-module__stat-container--aIY35{border-top:2px solid rgba(127,140,141,.2);border-right:2px solid rgba(127,140,141,.2);min-width:4rem;padding:.25rem}.src-UserCard-UserCardStats-styles-module__stat-container--aIY35:last-child{border-right:none}.src-UserCard-UserCardStats-styles-module__stat-name--GJoTD{display:flex;flex-flow:row;justify-content:center;color:#7f8c8d}.src-UserCard-UserCardStats-styles-module__stat-value--c0\\+kg{display:flex;flex-flow:row;justify-content:center;color:#2c3e50;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/UserCard/UserCardStats/styles.module.scss"],"names":[],"mappings":"AAAA,kEACE,YAAA,CACA,aAAA,CACA,sBAAA,CAEA,gBAAA,CAGF,iEACE,yCAAA,CACA,2CAAA,CACA,cAAA,CACA,cAAA,CAEA,4EACE,iBAAA,CAIJ,4DACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,aAAA,CAGF,8DACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,aAAA,CACA,gBAAA","sourcesContent":[".user-card-stats {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n\n  font-size: 0.75rem;\n}\n\n.stat-container {\n  border-top: 2px solid rgba(#7f8c8d, 0.2);\n  border-right: 2px solid rgba(#7f8c8d, 0.2);\n  min-width: 4rem;\n  padding: 0.25rem;\n\n  &:last-child {\n    border-right: none;\n  }\n}\n\n.stat-name {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  color: #7f8c8d;\n}\n\n.stat-value {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  color: #2c3e50;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-card-stats": "src-UserCard-UserCardStats-styles-module__user-card-stats--bnwQi",
	"stat-container": "src-UserCard-UserCardStats-styles-module__stat-container--aIY35",
	"stat-name": "src-UserCard-UserCardStats-styles-module__stat-name--GJoTD",
	"stat-value": "src-UserCard-UserCardStats-styles-module__stat-value--c0+kg"
};
export default ___CSS_LOADER_EXPORT___;
