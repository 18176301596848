// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-CryptoCard-TitleLine-styles-module__crypto-card-title-line--IIp9B{display:flex;flex-flow:row;justify-content:space-between;align-items:center;margin-bottom:.5rem;font-size:20px;color:#fff}", "",{"version":3,"sources":["webpack://./src/CryptoCard/TitleLine/styles.module.scss"],"names":[],"mappings":"AAAA,uEACE,YAAA,CACA,aAAA,CACA,6BAAA,CACA,kBAAA,CACA,mBAAA,CACA,cAAA,CAEA,UAAA","sourcesContent":[".crypto-card-title-line {\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 0.5rem;\n  font-size: 20px;\n\n  color: #FFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crypto-card-title-line": "src-CryptoCard-TitleLine-styles-module__crypto-card-title-line--IIp9B"
};
export default ___CSS_LOADER_EXPORT___;
