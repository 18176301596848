import React, { useEffect, useState } from 'react'
import { ButtonGroup, Card, Container, Paper, CardContent, Button, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, Toolbar, Modal, Dialog, DialogTitle, DialogContent, TextField, FormLabel, DialogActions } from '@mui/material'
import JoditEditor from 'jodit-react'
import { AddCardRounded, AddRounded, ArrowRightAltRounded, Cancel } from '@mui/icons-material'
import axios from 'axios'
import { useSelector } from 'react-redux'
import api from './data/api'


const Eplus_word = () => {
    const [modal,setModal] = useState(false)
    const userInfo = useSelector(state=>state.userInfo)
    const loggedUser = useSelector(state=>state.logedUser)
    const [card,setCard] = useState({title:"",
    description:"",
    bgColor:"#0099ff",
    titleColor:"#ffffff"
})
    const [cadrs,setCards] = useState([])

    useEffect(()=>{

        if(userInfo.status == "success")
        {
            userInfo.user?.userInformation != null?setCards(JSON.parse(userInfo.user?.userInformation)):setCards([])
        }
        console.log(userInfo)
    },[userInfo])
 
    
    return (
    <>
        <Dialog open={modal}>
            <DialogTitle><Typography>Create new</Typography></DialogTitle>
            <DialogContent>
            <FormLabel>Title</FormLabel>
            <br/> <TextField 
                sx={{marginBottom:4,width:"50px",height:"30px",padding:0,borderRadius:"100%"}}
                placeholder='Title'
                type='color'
                value={card.titleColor}
                onChange={e=>{
                setCard({...card,titleColor:e.target.value})
            }}
                variant='standard'
                label="Color"
                /><br/>
            <TextField 
                fullWidth
                sx={{marginBottom:4}}
                placeholder='Title'
                value={card.title}
                onChange={e=>setCard({...card,title:e.target.value})}
                label="Title"
                />
                <FormLabel>BG-Color</FormLabel><br/><br/>
            <TextField 
               sx={{marginBottom:4,width:"50px",height:"30px",padding:0,borderRadius:"100%"}}
                placeholder='Title'
                type='color'
                value={card.bgColor}
                onChange={e=>setCard({...card,bgColor:e.target.value})}
                label="BG-Color"
                variant='standard'
                /><br/>
                <TextField 
                label="Description"
                placeholder='Description'
                fullWidth
                multiline
                onChange={e=>{setCard({...card,description:e.target.value})}}
                rows={10}
                aria-expanded
                value={card.description}
                />
            </DialogContent>
            <DialogActions>
                <ButtonGroup>
                    <Button startIcon={<AddCardRounded/>} onBlur={async e=>{
                     
                     let token = loggedUser?.user

                     return await axios.put(`${api.address}/addressapi/users/${userInfo.user?.id}`,{
                        userInformation:JSON.stringify(cadrs)
                    },{
                        headers:{
                            'Authorization': `Bearer ${token}`
                        }
                    })
                    .then((result) => {
                        console.log(result.data)
                    }).catch((err) => {
                        console.log(err)
                    });

                    }} onClick={async e=>{setCards([...cadrs,card]); setModal(false) 
                     
                  
                    
                      }}>Create</Button>
                    <Button startIcon={<Cancel/>} color='warning' onClick={e=>{ setModal(false) }}>
                Cancel
            </Button>

                </ButtonGroup>
            </DialogActions>

        </Dialog>
    <Toolbar>
        <ButtonGroup>
        <Button onClick={e=>{ setModal(true) }} startIcon={<AddRounded />}>
                Create New
            </Button>
        </ButtonGroup>
    </Toolbar>
          <Container>
        <Grid container sx={{padding:2}}>
        {
        cadrs.map((card)=>(<Grid key={Math.random()} item md={5.5} sx={{margin:1}}>
          <Paper elevation={3} sx={{padding:4,bgcolor:card.bgColor}}>
              <Typography style={{textAlign:"center",color:card.titleColor}} variant='h3'>{card.title}</Typography>
              <Typography variant='body1' style={{textAlign:"center",color:"white"}}>{card.description}</Typography>
          </Paper>
          </Grid>))
          }
        </Grid>
      </Container>

    {/* <Grid container sx={{
        height:"100vh",
        position:"sticky"
       }}>
       <Grid item md={2}>
        <Card>
            <CardHeader title={<ButtonGroup><Button StartIcon={<AddRounded/>}>Create new</Button></ButtonGroup>} />
            <Divider/>
            <CardContent>
                <List>
                <ListItem style={{cursor:"pointer"}} onClick={e=>{
                    setDataText(`<p style="text-align: center;"><strong style="font-size: 48px;">ግብ</strong></p><p style="text-align: center;"><span>ለግለሰብ፣ ለተቋምና ለከነማ ዲጂታል ማስታወቂያ፣ ገበያ፣ አድራሻና የዲጂታል አድራሻ አውድ አልምቶ ማቅረብ</span></p><p style="text-align: center;"><span style="font-size: 48px;"><strong>ተልዕኮ</strong></span></p><p style="text-align: center;">    • በተቋማትና ከተሞዎች መካከል ዲጂታል ተግባቦትና ግብይት መፍጠር<br>    • የከተማ ዲዘይን፣ ረቂቅ ትይንት፣ ጉጉትና ልውውጥ መፍጠር<br>    • ቱሪዝምና ኢንቨስትመንቶችን ለመሳብ ዲጂታል የገጽታ ግንባታዎችን መስራት</p><p style="text-align: center;"><br></p><p style="text-align: center;"><br></p><p><br></p><p><br></p><p><br></p>`)
                }}>
                        <ListItemIcon>
                            <ArrowRightAltRounded style={{fontWeight:"bolder", fontWeight:"900"}}/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>ግብ/ተልዕኮ</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem style={{cursor:"pointer"}} onClick={e=>{
                        setDataText(`<p><br></p><p style="text-align: center;"><strong style="font-size: 30px;">ኢጵላሣጵ</strong></p><p>ኢጵላሣጵ
                        ማለት ዲጂታል ድምጸት
                        ወይም
                        የዲጂታል
                        ቋንቋና
                        የቃል
                        ወይ
                        ያነጋገር ሁሉ ምልክት፣
                        አምሳል ወይም
                        አድራሻ፣
                        መግለጫና
                        ማስታወቂያ ነው።
                        ኢጵላሣጵ ማለት ዲጂታል ፊደል፣
                        ጋብቻ፤
                        መልክ፣
                        ወሰን፣
                        አውድ ነው
                        –ኢጵላሣጵ
                        አውድ ማለት አእምሮ
                        ያለው ሁሉ የሚሰፍርበትና
                        የጥበብን
                        አድራሻ
                        የሚያሳውቅ ነው።
                        
                        
                        
                        ኢጵላሣጵ
                        ከቀደምት ሙሕራን
                        “ፊደል
                        የጥበብ
                        ምንጭ”
                        መሆኑን
                        በመማር በተለይም ከጥንታዊው
                        ዓለም የአንድ አገር የሥልጣኔ መጠን የሚለካው
                        በሥነ-ጽሑፍ፣
                        በፍልስፍና፣ በሥነ-ሕንፃ
                        እና በመሳሰሉት የአስተሳሰብን ጥልቀት እና
                        ምጥቀት በሚጠይቁ ድርጊቶች መሆኑን
                        በመማርና
                        ኢትዮጵያ ከዛሬ ሰባት ሺህ አመታት በፊት ጀምሮ
                        የጽሑፍ
                        ትምህርት በመስጠት
                        አሁን
                        የምትገኝበት
                        የሥልጣኔ ደረጃ ኢጵላሣጵ
                        ስንቅ ነው።</p><p><br></p><p><br></p><p><br></p><p><br></p>`)
                    }}>
                        <ListItemIcon>
                            <ArrowRightAltRounded style={{fontWeight:"bolder", fontWeight:"900"}}/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>ኢጵላሣጵ</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ArrowRightAltRounded style={{fontWeight:"bolder", fontWeight:"900"}}/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>Nav 3</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
          </Grid>
      <Grid item md={8} id="go_head">
      <JoditEditor
      value={dataText}
      onChange={e=>{
        setDataText(e)
      }}

      />
      </Grid>
      <Grid item md={2}>
          </Grid>
   
  
           </Grid> */}
    </>  
  )
}

export default Eplus_word