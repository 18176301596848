// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-PaymentCard-PaymentCardDate-styles-module__payment-card-date--G2nXd{position:relative;z-index:10;display:flex;flex-flow:column;color:#ecf0f1}.src-PaymentCard-PaymentCardDate-styles-module__payment-card-date--G2nXd .src-PaymentCard-PaymentCardDate-styles-module__valid-date--9JHYq{padding-left:6.5rem;text-transform:uppercase;font-size:10px;font-weight:bold}.src-PaymentCard-PaymentCardDate-styles-module__payment-card-date--G2nXd .src-PaymentCard-PaymentCardDate-styles-module__to-date--A-gRM{padding-left:6.5rem;text-shadow:0px 1px 3px rgba(44,62,80,.7);font-family:\"OCR A Std\";font-size:14px}", "",{"version":3,"sources":["webpack://./src/PaymentCard/PaymentCardDate/styles.module.scss"],"names":[],"mappings":"AAAA,yEACE,iBAAA,CACA,UAAA,CAEA,YAAA,CACA,gBAAA,CAEA,aAAA,CAEA,2IACE,mBAAA,CAEA,wBAAA,CAEA,cAAA,CACA,gBAAA,CAGF,wIACE,mBAAA,CAEA,yCAAA,CAEA,uBAAA,CACA,cAAA","sourcesContent":[".payment-card-date {\n  position: relative;\n  z-index: 10;\n\n  display: flex;\n  flex-flow: column;\n\n  color: #ecf0f1;\n\n  .valid-date {\n    padding-left: 6.5rem;\n\n    text-transform: uppercase;\n\n    font-size: 10px;\n    font-weight: bold;\n  }\n\n  .to-date {\n    padding-left: 6.5rem;\n\n    text-shadow: 0px 1px 3px rgba(#2c3e50, 0.7);\n\n    font-family: 'OCR A Std';\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-card-date": "src-PaymentCard-PaymentCardDate-styles-module__payment-card-date--G2nXd",
	"valid-date": "src-PaymentCard-PaymentCardDate-styles-module__valid-date--9JHYq",
	"to-date": "src-PaymentCard-PaymentCardDate-styles-module__to-date--A-gRM"
};
export default ___CSS_LOADER_EXPORT___;
