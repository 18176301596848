import { LockOpen, LockPerson } from '@mui/icons-material'
import { Button, Box, Card, CardContent, CardHeader, Modal, TextField, Typography, CardActionArea, CardActions, ButtonGroup, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from './data/UserLogin'
import { setLogin } from './data/Loged'
import { InfinitySpin } from 'react-loader-spinner'

const Lock = () => {

    const [pwd,setPwd] = useState("")
    const [lock,setLock] = useState(false)
    const loginInfo = useSelector(state=>state.logedUser)
    const server = useSelector((state)=>state.loginUser)
    const disp = useDispatch();
    const [load,setload] = useState(false);
    const [msg,setMsg] = useState({msg:'',show:false})


  let check =  setInterval(()=>{

        let now = new Date();
        let cH = now.getHours();
        let cM = now.getMinutes();
        let lH = loginInfo.time.h
        let lM = loginInfo.time.m 

        if(lH == cH)
        {
            let mints = Math.abs(cM-lM)
            if(mints>50 && cM != 0)
            {
                setLock(false)
                clearInterval(check)
            }
            
        }
        else if(lH < cH || lH > cH)
        {
                setLock(false)
                clearInterval(check)
        
        }

        
        
    },30000)
    





    useEffect(()=>{

        if(server.status == "loading")
        {
          setload(true)
        }
        else if(server.status == "failed")
        {
          console.log(server)
        setMsg({msg:"የተሳሳተ የይለፍ ቃል አስገብተዋል!",show:true}); 
        setload(false)
        }
        else if(server.status == "success")
        {
          disp(setLogin({email:server.user.email,user:server.user.token,id:server.user.id}))
            window.location.reload();
          
        }
        else 
        setload(false)
      
      },[server])
      
      












    const Login =  () =>
    {
        console.log(disp(userLogin({email: loginInfo.email,password:pwd})))

    }








  return (<>{
lock?<Box style={{width: '100%',
height: '100%',
position: 'fixed',
background: '#161616b0',
zIndex: '101321',
display: 'flex',
justifyContent: 'center'}}>
    
    <Card style={{
height: 'fit-content',
width: '40rem',
marginTop: '8%',
boxShadow: 'rgb(0, 153, 255) 1px 1px 6px 2px'}}>
        <CardHeader title={<Typography variant='h2' style={{textAlign:'center',fontSize:15}} ><LockPerson style={{fontSize:100,color:'#0099ff'}}/></Typography>} />

        <CardContent style={{alignContent:'center'}}>
        <div style={{margin: 'auto',
display: 'flex',
justifyContent: 'center'}}>

{load?<InfinitySpin color='#0099ff'/>:''}
{msg.show && !load?<Alert severity='error'>{msg.msg}</Alert>:''}
</div>

<div style={{margin: 'auto',
display: 'flex',
justifyContent: 'center'}}>
<TextField
style={{width:'70%'}}
value={pwd}
onChange={e=>setPwd(e.target.value)}
placeholder='የይለፍ ቃል ያስገቡ'
type='password'
disabled={load}
/>

<Button disabled={load} onClick={Login} startIcon={<LockOpen/> } variant='contained'>ግብት</Button>
</div>
            </CardContent> 
            <CardActionArea>
                <CardActions style={{justifyContent: 'center'}}>
                     <ButtonGroup variant='text'>
                     <Button>ማስታወቅያ</Button>
                     <Button>ገብያ</Button>
                     <Button>መግብያ</Button>
                     </ButtonGroup>
                </CardActions>
            </CardActionArea>
    </Card>

</Box>:''}
</>
    )
}

export default Lock