import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoaded:false,
    detail:{},
    isErr:false
}

const schoolDetail = createSlice({
    name:"schoolDetail",
    initialState,
    reducers:{
        setDetail:(state,action)=>{
            state.detail = action.payload;
            state.isLoaded = true
        }
    },
    extraReducers:(buider)=>{

    }
})



export const {setDetail} = schoolDetail.actions
export default schoolDetail.reducer