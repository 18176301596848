import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api"
import axios from "axios";
const baseURL = api.address;

const initialState = {
    status:"",
    err:"",
    user:{}
}

export const userLogin = createAsyncThunk('user/Login', async (user)=> {
    const resp = await axios.post(baseURL+'/addressapi/users/login',user).then((resp)=>resp)

    console.log("Login response",resp)

    return resp;
})

const loginSlice = createSlice({
    name:"login",
    initialState,
    reducers:{

    },
    extraReducers:(builder)=>{
        builder.addCase(userLogin.pending, (state,action)=>{
                state.status = "loading"
                console.log(state.status)
        });
        builder.addCase(userLogin.fulfilled, (state,action)=>{
            state.status = "success"
            state.user = action.payload.data 
            console.log(state.status)            
            console.log(action.payload.data)
        });
        builder.addCase(userLogin.rejected, (state,action)=>{
            state.status = "failed"
            console.log(action.payload)
            

        });
    }
})


export default loginSlice.reducer