import { Album, ArticleOutlined, ContactPageRounded, CreateNewFolder, Delete, DeleteForever, DocumentScannerRounded, Download, Edit, FileDownload, Folder, FolderCopyRounded, FolderShared, Home, Image, LocalPostOffice, Photo, PhotoAlbum, PhotoAlbumRounded, PictureAsPdf, PictureAsPdfRounded, Save, SearchOffRounded, SearchRounded, Share, StorageRounded, Upload, UploadFileRounded, VideoCameraBack, ViewAgendaRounded, VisibilityRounded } from '@mui/icons-material'
import { TextField, Typography, Box,List, Grid, Toolbar, LinearProgress, ButtonGroup, IconButton, Paper, Button, ListItem, ListItemIcon, ListItemText, ListItemButton, Collapse, CircularProgress, Dialog, DialogContent, DialogTitle, ListItemAvatar, Avatar } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import styled from '@emotion/styled';
import ProgressBar from "@ramonak/react-progress-bar";
import { useSelector } from 'react-redux';
import axios from 'axios';
import api from '../data/api'
import Loading from '../components/Loading';
const Storage = (props) => {
    const [active,setactive] = props.states
    const logedUser = useSelector((state)=>state.logedUser);
    const userInfo = useSelector((state)=>state.userInfo)
    const [files,setFiles] = useState([])
    const [userBio,setUserBio] = useState(null)
    const [dirName,setDirName] = useState('-----')
    const [folderSize,setFolderSize] = useState(0)
    const file = useRef()
    const [shareDialog,setShare] = useState({show:false,shareBody:{}})
    const [searchResult,setSearchResult] = useState([])
    const [loading,setLoading] = useState(false)






    useEffect(()=>{

      (async ()=>{

        console.log(shareDialog.shareBody)
        if(shareDialog.shareBody.receiver_user_id != null)
        {
          
          await axios.post(`${api.address}/addressapi/share/file`,shareDialog.shareBody,{
            headers:{
              'Authorization': `Bearer ${logedUser.user}`
            }
          })
          .then((result) => {
          alert("file shared")
          setShare({show:false,shareBody:{}})
          }).catch((err) => {
            console.log(err)
          });
          
        }


      })()



    },[shareDialog])



useEffect(()=>{if(userInfo.status == "success"){
  setUserBio(userInfo.user)

}},[userInfo])
useEffect(()=>{

  (async()=>{

    let dirName = userBio?.firstName.toLowerCase()+'-'+userBio?.email

    //addressapi/files/get-folder-size/1


    setDirName(dirName);
    await axios.get(`${api.address}/addressapi/fetch-files/all?userId=${logedUser.id}`,{
      headers:{
          'Authorization': `Bearer ${logedUser.user}`
        }
    })
    .then((result) => {

      setFolderSize(1)

    
      setFiles(result.data)

      console.log(result)
    }).catch((err) => {
    
      console.info(err)
    
    });
     
    
    
    
    
    
        




})()



},[active])




  return (
<Box style={{display: active == 5?'block':'none', transition: 'eas-in-out 0.5s'}}>
  <Loading  load={loading}/>
<Toolbar sx={{borderBottom: '0px solid #4c4c4c94',
boxShadow: '1px 1px 1px 0px #d9d9d9',
width:"95%"
}}>
  <Typography variant='h5'>የኔ ፋይሎች</Typography> 
  <Album color='primary' fontSize='large' style={{    marginLeft: '38px'
}}/>
  <span style={{
    width: '60%',
  }}>
    <ProgressBar 
     completed={(folderSize.toFixed(2)*100)/15<=1?4:(folderSize.toFixed(2)*100)/15}
     maxCompleted={100} 
     bgColor="#0099ff"
     height="30px"
     customLabel={<Typography>{ folderSize.toFixed(2)+'GB/15GB ማስቀመጫ'}</Typography>}
     />
    </span>
    <Button variant='outlined' onClick={e=>file.current.click()} startIcon={<UploadFileRounded/>} size="large" sx={{margin:"auto"}}>Upload</Button>
<input type="file" ref={file} style={{display:"none"}} onChange={ async e=>{

setLoading(true)

let file = e.target.files[0]
let fd = new FormData();

fd.append("file",file)
fd.append("userId",logedUser.id)
await axios.post(`${api.address}/addressapi/files/uploadfile`,fd,{
  headers:{
    'Authorization': `Bearer ${logedUser.user}`
  }
})
.then(async (result) => {
  
  setLoading(false)
  console.log(result)

  await axios.get(`${api.address}/addressapi/fetch-files/all?userId=${logedUser.id}`,{
    headers:{
        'Authorization': `Bearer ${logedUser.user}`
      }
  })
  .then((result) => {

    setFolderSize(1)

  
    setFiles(result.data)

    console.log(result)
  }).catch((err) => {
  
    console.info(err)
  
  });

}).catch((err) => {
  setLoading(false)

  console.info(err)
});

}} />
</Toolbar>

<Grid container sx={{margin:1}}>
<Grid item md={3} sx={{
  height: '100vh',
  borderRight: 'solid 1px #9f9f9f',
  background: '#f1f1f1'
}}>

<Typography variant='h5' sx={{padding:1}}>{ folderSize.toFixed(2)+'GB/15GB ማስቀመጫ'}</Typography>

<Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "95%" }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="የፍለጋ ፋይሎች"
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    </Paper>

    <List>
    <ListItemButton onClick={async e=>{
       await axios.get(`${api.address}/addressapi/fetch-files/all?userId=${logedUser.id}`,{
        headers:{
            'Authorization': `Bearer ${logedUser.user}`
          }
      })
      .then((result) => {
    
        setFolderSize(1)
    
      
        setFiles(result.data)
    
        console.log(result)
      }).catch((err) => {
      
        console.info(err)
      
      });
    
         

    }} sx={{    marginBlock: "-1px",boxShadow: '1px 1px 1px 0px #d9d9d9',marginBottom:1}}>
        <ListItemIcon>
          <FolderCopyRounded style={{fontsize:15}} color='primary' fontSize='large'  />
        </ListItemIcon>
        <ListItemText primary={dirName} />
        <Divider/>
      </ListItemButton>
      <ListItemButton onClick={ async e=>{

        setLoading(true)
        await axios.get(`${api.address}/addressapi/share/all`,{
          headers:{
            'Authorization': `Bearer ${logedUser.user}`
          }
        })
        .then((result) => {
          setLoading(false)
          console.log(result.data)
          setFiles(result?.data)
        }).catch((err) => {
          setLoading(false)
          console.log(err)
          setFiles([])
          
        });

      }} sx={{    marginBlock: "-1px",boxShadow: '1px 1px 1px 0px #d9d9d9',marginBottom:1}}>
        <ListItemIcon>
          <FolderShared style={{fontsize:15}} color='primary' fontSize='large'  />
        </ListItemIcon>
        <ListItemText primary={`የተጋሩ ፋይሎች`} />
        <Divider/>
              </ListItemButton>
      
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton onClick={ async e=>{

setLoading(true)
await axios.get(`${api.address}/addressapi/share/all`,{
  headers:{
    'Authorization': `Bearer ${logedUser.user}`
  }
})
.then((result) => {
  setLoading(false)
  console.log(result.data)
  setFiles(result?.data)
}).catch((err) => {
  setLoading(false)
  console.log(err)
  setFiles([])
  
});

}} sx={{    marginBlock: "-1px",boxShadow: '1px 1px 1px 0px #d9d9d9',marginBottom:1}}>
<ListItemIcon>
  <FolderShared style={{fontsize:15}} color='primary' fontSize='large'  />
</ListItemIcon>
<ListItemText primary={`Assets`} />
<Divider/>
      </ListItemButton>

        </List>
      </Collapse>
    </List>
    
</Grid>
<Grid item md={8}>
  <List>

  {/* <ListItemButton style={{background: "#dfdfdf"}}>
        <ListItemText primary={<Typography variant='body1'>ስም</Typography>}/>
    </ListItemButton> */}




    {
      files.map((file)=>(<><ListItemButton style={{padding: '0',margin: '6px'}}>
      <ListItemIcon>
<ContactPageRounded fontSize='large'/>
        </ListItemIcon>
        <ListItemText primary={<Typography variant='body1'>{file.fileName}</Typography>}/>
        <ListItemText primary={<ButtonGroup> <Button variant='outlined' onClick={e=>{
          window.open(`${api.address}/${file.filePath}`, '_blank').focus();
        }}><VisibilityRounded/></Button>
        <Button onClick={e=>{
          setShare({...shareDialog,show:true,shareBody:{shared_file_id:file.id}})
        }} variant='outlined' color='success' startIcon={<Share/>}></Button>
        <Button variant='outlined' color='error' onClick={async e=>{

          if(window.confirm("Are you sure?"))
          {
            await axios.delete(`${api.address}/addressapi/fetch-files/${file.id}`,{
              headers:{
                'Authorization': `Bearer ${logedUser.user}`
              }
            })
            .then(async (result) => {
              
              console.log(result)

              await axios.get(`${api.address}/addressapi/fetch-files/all?userId=${logedUser.id}`,{
                headers:{
                    'Authorization': `Bearer ${logedUser.user}`
                  }
              })
              .then((result) => {
            
                setFolderSize(1)
            
              
                setFiles(result.data)
            
                console.log(result)
              }).catch((err) => {
              
                console.info(err)
              
              });

            }).catch((err) => {
              
              console.log(err)
            });
          }


        }} startIcon={<DeleteForever/>}></Button></ButtonGroup>} />
    </ListItemButton>
    <Divider/>
    </>))
    }

  </List>
</Grid>
</Grid>
<Dialog open={shareDialog.show} onClose={e=>setShare({...shareDialog,show:false})}>
  <DialogTitle>
    <Typography sx={{margin:3}}>ፋይሉን ለማጋራት ተጠቃሚውን ይፈልጉ</Typography>
    <TextField fullWidth label="ንቁ ቁጥር ወይም ስም ያስገቡ"  onChange={ async e=>{
      let q = e.target.value;
      await axios.get(`${api.address}/addressapi/users/search?key=${q}`)
      .then((result) => {
        setSearchResult(result.data)
      }).catch((err) => {
        
      });
    }}/>
  </DialogTitle>
  <DialogContent sx={{width:"480px"}}>
    <List>

      {
        searchResult.map((user)=>
        <ListItem sx={{cursor:"pointer"}} onClick={e=>{
          
          setShare({...shareDialog,shareBody:{...shareDialog.shareBody,receiver_user_id:user.id}});
        
        }}>
        <ListItemAvatar>
          <Avatar/>
        </ListItemAvatar>
        <ListItemText primary={<Typography variant='body2' color="GrayText">{user.firstName+" "+user.middleName}</Typography>} secondary={<Typography>{user.userCode}</Typography>}/>
      </ListItem>)
      }
    </List>
  </DialogContent>
</Dialog>
</Box>
  )
}

export default Storage