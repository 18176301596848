import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import logReducer from './UserLogin';
import logedUser from "./Loged"
import updateUser from './updateUser';
import userInfo from './userInfo';
import firstReg from'./FirstReg';
import OrgRedu from './Organization'
import orgDetail from './OrgDetail'
import { MyProduct } from './ProductData';
import schoolDetail from './school/schoolDetail';
export const store = configureStore({
  reducer: {
    user:userReducer,
    loginUser:logReducer,
    logedUser:logedUser,
    updateuser:updateUser,
    userInfo:userInfo,
    creatfuser:firstReg,
    schoolDetail: schoolDetail,
    org:OrgRedu,
    orgDetail:orgDetail,
    [MyProduct.reducerPath]:MyProduct.reducer
  },
  middleware:(getDefaultMiddleware)=>getDefaultMiddleware({
    serializableCheck:false,
  }).concat(MyProduct.middleware)
})
