// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--CPaMH{position:absolute;display:flex;flex-flow:column;justify-content:center;align-items:center;height:100%;width:2rem;transition:.25s;color:rgba(236,240,241,.5);background-color:rgba(44,62,80,.5);opacity:0;cursor:pointer}.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--CPaMH.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__left--qCQRj{left:0;border-radius:.25rem 0 0 0}.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--CPaMH.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__right--4fcb2{right:0;border-radius:0 .25rem 0 0}.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--CPaMH:hover{opacity:1}", "",{"version":3,"sources":["webpack://./src/ProductCard/ProductCardGallery/GalleryToggle/styles.module.scss"],"names":[],"mappings":"AAAA,uFACE,iBAAA,CACA,YAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,eAAA,CACA,0BAAA,CACA,kCAAA,CACA,SAAA,CACA,cAAA,CAEA,mKACE,MAAA,CACA,0BAAA,CAGF,oKACE,OAAA,CACA,0BAAA,CAGF,6FACE,SAAA","sourcesContent":[".gallery-toggle {\n  position: absolute;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 2rem;\n  transition: 0.25s;\n  color: rgba(#ecf0f1, 0.5);\n  background-color: rgba(#2c3e50, 0.5);\n  opacity: 0;\n  cursor: pointer;\n\n  &.left {\n    left: 0;\n    border-radius: 0.25rem 0 0 0;\n  }\n\n  &.right {\n    right: 0;\n    border-radius: 0 0.25rem 0 0;\n  }\n\n  &:hover {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery-toggle": "src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--CPaMH",
	"left": "src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__left--qCQRj",
	"right": "src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__right--4fcb2"
};
export default ___CSS_LOADER_EXPORT___;
