import React from 'react'
import {Button, ButtonGroup, TextField} from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link } from 'react-router-dom'
import { UploadFile } from '@mui/icons-material'
const Org9 = () => {
  return (

    <Card>
    <CardContent sx={{width:"100%"}}>
       
    <Grid container >
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h6' color="primary">የጵሮጀክት ፅፈትቤት አደረጃጀት!</Typography>

        <TextField
          id="standard-multiline-static"
          label="መስርያ ቦታና ጽ/ቤት"
          multiline
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="የጽ/ቤት መርጃ መሳርያ"
          multiline
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="የመስክ መርጃ መሳርያ"
          multiline
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h6' color="primary">ተወዳዳሪነት!</Typography>

        <TextField
          id="standard-multiline-static"
          label="የጥሬ ገንዘብ መጠን"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="የበጀት መጠን"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="የከነማ አልሚነት"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />


<TextField
          id="standard-multiline-static"
          label="የስም ትይነት"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />



<InputLabel htmlFor="component-simple">የህንፃ ዲዛይን ወይም ህያው ግቢና መስህቦች</InputLabel>
            <Button startIcon={<UploadFile/>}>ለማስገባት</Button>
            <Input type='file' sx={{display:"none"}} id="component-simple"  />

    </Grid>

    <Grid  item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:4}}>
    
    <Typography variant='h6' color="primary">ያሳለፉት መረጃና ሃላፊነት!</Typography>

    <TextField
  id="standard-multiline-static"
  label="ቋሚ ሰራተኛ"
  multiline
  rows={4}
  variant="standard"
  style={{width:"100%"}}
  />


<TextField
  id="standard-multiline-static"
  label="የኩንትራት ሰራተኛ"
  multiline
  rows={4}
  variant="standard"
  style={{width:"100%"}}
  />



    </Grid>
    </Grid>
    </CardContent>
    <CardActions sx={{paddingLeft:20}}>        
    <Link to="/main/org/8"><Button size='large' startIcon={<ArrowBackIosNewRounded /> } variant="contained">ቀጣይ</Button></Link> 
             &nbsp;
                 <Link to="/main/org/10"><Button size='large' endIcon={<ArrowForwardIosRounded/> } variant="contained">ቀጣይ</Button></Link> 

    </CardActions>
    <br /><br />
</Card>

    )
}

export default Org9