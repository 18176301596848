import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "./api";
const baseURL = api.address;


const initialState = {
    lastName:"",
    avatar:"",
    dateOfBirth:"",
    phoneNumber:"",
    kebele:"",
    subCity:"",
    city:"",
    region:"",
    country:"",
    continent:"Africa",
    religion:"",
    position:"",
    education:"",
    profession:"",
    images:"user",
    maritalStatus:"",
    bank:"",
    bankAccountNumber:"",
    workExperience:"",
    achievement:"",
    skill:"",
    hobbies:"",
    weight:"",
    hight:"",
    language:"",
    bloodType:"",
    eyeColor:"",
    motherName:"",
    motherlastName:"",
    isloading:false,
    status:"",
    msg:"",
    latitude:null,
    longitude:null,
}



export const updateprivateInfo = createAsyncThunk('set/privateInfo', async (data)=>{


    console.log(data)
    const userInfo = data.user;
    const token = data.token;

    const response = await axios.put(`${baseURL}/addressapi/users/${data.userId}`,userInfo,{
        headers: {
            'Authorization': `Bearer ${token}`
          }   
    }).then((resp)=>resp)

    return response;


})




const updateuserSlice = createSlice({
    name:'updateUser',
    initialState,
    reducers:{
        UpdateUserInfo:(state,action) => {
            const name = action.payload.name;
            const data = action.payload.data;
            state[name] = data;
            var user = JSON.stringify(state);
            localStorage.setItem("userData",user)
            state.isloading =false
        }},
    extraReducers:(builders)=>{
        builders.addCase(updateprivateInfo.pending,(state,action)=>{
            state.status = "Pending"
            state.isloading = true
            console.log(state.status)

        });
        builders.addCase(updateprivateInfo.fulfilled,(state,action)=>{
            state.status = "success"
            state.isloading = false;
            console.log(action.payload.data)
            console.log(state.status)

        });
        builders.addCase(updateprivateInfo.rejected,(state,action)=>{
            state.status = "faild"
            state.isloading = false;
            console.log(state.status)
            console.log(action.payload)
            

        });

    }
})



export const {UpdateUserInfo} = updateuserSlice.actions;
export default updateuserSlice.reducer