import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Clear, Done, DoubleArrow } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ModalYN = (props)=> {
  const [modalYN,setYN] =   React.useState(false);
  const Org = (val) => {

    if(val == 0)
    {
        setYN({value:0,show:false})
        localStorage.setItem("orgYes","true")
        
    }
    else 
    {
      setYN({value:0,show:false})
      localStorage.setItem("org","true")
      window.location ="/main";  
    }
          }

  return (
      <Dialog
        open={modalYN.show}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle>ድርጅት</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          የእርስዎ የሆነ ወይም እርስዎ የሚያስተዳድሩት ድርጅት አለ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button endIcon={<Done/>} onClick={e=>Org(0)} color="primary" variant='contained'>አዎ</Button>
          <Button endIcon={<Clear/>} onClick={e=>Org(1)} color="secondary" variant='outlined'>የለኝም</Button>
        </DialogActions>
      </Dialog>
  );
}



// Ths is how it works const [modal,setmodal] = useState({title:"Title",body:"Body",show:false,btn:"Button",loc:""});



export default ModalYN