import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";
import axios from "axios";
const baseURL = api.address;



var initialState = {
    isloading:false,
    status:"",
    msg:"",
    user:{}
}




export const fetchUserInfo = createAsyncThunk('fetch/user', async (user)=>{

    const call = await axios.get(`${baseURL}/addressapi/users/${user.id}`,{
        headers: {
            'Authorization': `Bearer ${user.user}`
          }   
    }).then((resp)=>resp);
    return call;
})


export const setTef = createAsyncThunk('createTeftef/user', async (data)=>{


            let token = data.token;
            let update = data.update;
    const set = await axios.post(`${baseURL}/addressapi/tef_wof/create`,update,{
        headers: {
            'Authorization': `Bearer ${token}`
          }   
    }).then((resp)=>{

        console.log(resp);
        return resp;
    }).catch((err)=>{

        console.log(err)

        return err;
    });


        return set;

})






const fetchUserSlice = createSlice({
    name:'fetchUser',
    initialState,
    extraReducers:(builders)=>{
        builders.addCase(fetchUserInfo.fulfilled,(state,action)=>{
            state.isloading = false
            state.status = "success"
            state.msg = "fetch Success"
            console.log(state.status)
            state.user = action.payload.data;
        });
        builders.addCase(fetchUserInfo.pending,(state,action)=>{
            state.isloading = true
            console.log(state.status)

            state.status = "pending"

        });
        builders.addCase(fetchUserInfo.rejected,(state,action)=>{
            state.isloading = false
            state.status = "failed"
            console.log(state.status)

            state.msg = action.payload
        });

        builders.addCase(setTef.fulfilled, (state,action)=>{
            state.isloading = false;
            state.status = "success"
            console.log(action.payload)
        });
        builders.addCase(setTef.pending, (state,action)=>{
            state.isloading = true;
            state.status = "pending"
            console.log(action.payload)

        });
        builders.addCase(setTef.rejected, (state,action)=>{
            state.isloading = false;
            state.status = "failed"
            console.log(action.payload)

        });
        
    }

}) 



export default fetchUserSlice.reducer