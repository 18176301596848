import React from 'react'
import {Button, ButtonGroup, TextField} from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import api from '../../data/api'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import { useState } from 'react'
const baseURL = api.address;
const Org7 = () => {

  const {state} = useLocation();
  const orgId = state.id;
  const link = useNavigate();
  const [load,setload] = useState(false);
  const {register,handleSubmit} = useForm();
  const token = useSelector((state)=>state.logedUser.user)
  const submit =async data => {
    setload(true);
    data["orgId"] = orgId;
    const creat = await axios.post(`${baseURL}/addressapi/promotions/create`,data,{
      headers: {
        'Authorization': `Bearer ${token}`
      }   
    }).then((resp)=>{


      console.log(resp);
      setload(false);
      if(resp.data?.id)
      link('../view',{state:{id:orgId}});        

      return resp;


    }).catch((err)=>{
      console.log(err.response);
      setload(false);
      alert(err.response.data.message)

    })
    return creat;

  }
  
  return (
<form onSubmit={handleSubmit(submit)}>
  <Loading load={load} />
    <Card>
    <CardContent sx={{width:"100%"}}>
       
    <Grid container >
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h6' color="primary">በማስታወቂያ ስራዎች መሠረት የውልና ስምምነት!</Typography>

        <TextField
          id="standard-multiline-static"
          label="የጋራ ፍላጎትና ግብ መፍጠር"
          multiline
          rows={4}
          {...register('commonGoal',{
            required:true
          })}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="የአዋጭነት ጥናት ማቅረብ"
          multiline
          {...register('feasibilityStudy',{
            required:true
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="ውልና ስምምነት መፍጠር"
          multiline
          {...register('agreement',{
            required:true
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />


<TextField
          id="standard-multiline-static"
          label="ማስተዋወቅ"
          multiline
          {...register('promoting',{
            required:true
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />



    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h6' color="primary">ተወዳዳሪነት!</Typography>

        <TextField
          id="standard-multiline-static"
          label="ውልና ስምምነትን መከታታል"
          multiline
          rows={4}
          {...register('agreementFollowUp',{
            required:true
          })}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="ሽልማትና እውቅና መፍጠር"
          multiline
          {...register('recognitionAward',{
            required:true
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />
<TextField
          id="standard-multiline-static"
          label="ፍላጎትን ማሳደግ"
          multiline
          {...register('demand',{
            required:true
          })}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

    </Grid>

    <Grid  item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:4}}>
    
    <Typography variant='h6' color="primary">ያሳለፉት መረጃና ሃላፊነት!</Typography>

    <TextField
  id="standard-multiline-static"
  label="የጋራ አላማና ግብ መቅረፅ"
  multiline
  {...register('developCommonGoal',{
    required:true
  })}
  rows={4}
  variant="standard"
  style={{width:"100%"}}
  />


<TextField
  id="standard-multiline-static"
  label="ውልና ስምምነትን ማሳደግ"
  multiline
  {...register('renewContract',{
    required:true
  })}
  rows={4}
  variant="standard"
  style={{width:"100%"}}
  />
  



    </Grid>
    </Grid>
    </CardContent>
    <CardActions sx={{paddingLeft:20}}>        
    <Link to="../view" state={{id:orgId}}><Button size='large' startIcon={<ArrowBackIosNewRounded /> } variant="contained">መመለስ</Button></Link> 
             &nbsp;
<Button size='large' type='submit' endIcon={<ArrowForwardIosRounded/> } variant="contained">መዝግብ</Button>


    </CardActions>
    <br /><br />
</Card>
</form>
    )
}

export default Org7