import { Grid, Container,Box, Typography, Toolbar, Button, AppBar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ass from './assets/bannerbg.jpg'
import api from './data/api';
import { useEffect } from 'react';
import axios from 'axios';
import { ArrowBack, CurrencyBitcoin, Email, EmailOutlined, Healing, Info, LocalActivity, LocationCity, LocationCityOutlined, LocationCityRounded, Phone } from '@mui/icons-material';



const UserDetail = () => {

    const state = useLocation();
    const link = useNavigate();
    const data = state.state?.item;
    const query = state.state?.query;
useEffect(()=>{

  console.log(data)

},[])
    
  return (
    <>
      <Toolbar>
    <Button size='large' onClick={()=>{ link(-1,{state:{query:query}})  }} style={{color:'black',fontSize:180}} variant='text' startIcon={<ArrowBack/>} />
  </Toolbar>

<Container>
  <Container>
  <Grid container>
  <Grid item sm={2}>
      <Box style={{width:'156px'}} component='img' src={`${api.imgAddress}/${data.avatar}`} />
     </Grid>
     <Grid item sm={6}>
     <Typography variant='h4'>{data.firstName+' '+data.middleName}</Typography>
     <Typography variant='h6'>{data.email}</Typography>
     <Typography variant='h6'> {data.phoneNumber}</Typography>
     </Grid>
     <Grid item sm={4}>
     <br /> 
     <Typography variant='h6'>ንቁ ቁጥር {data.userCode}</Typography>
     </Grid>
  </Grid>
  <hr />
  <Grid container>
    <Grid item sm={10}>
    <br /><br /><br />
    <Typography variant='body1'>
      <Typography variant='h6'>ስኬት</Typography>
    {data.achievement}
  </Typography>
  <Typography variant='body1'>
      <Typography variant='h6'>የስራ ልምድ</Typography>
    {data.workExperience}
  </Typography>
  <br /><br /><br />
<span style={{display:'flex',}}> <Typography variant='h5'> አድራሻ</Typography><LocationCityRounded style={{marginLeft:20,paddingTop:5}}/> </span>  
<hr />  
  <Grid container>
    <Grid item sm={4}>
    <Typography variant='h5'>ሃገር፡</Typography>
    <Typography variant='body1'>{data.country}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ክልል፡</Typography>
    <Typography variant='body1'>{data.region}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ወረዳ/ክፍለ ከተማ፡</Typography>
    <Typography variant='body1'>{data.subCity}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ቀበሌ፡</Typography>
    <Typography variant='body1'>{data.kebele}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የጎዳና ቁጥር፡</Typography>
    <Typography variant='body1'>{data.streetNumber}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የቤት ቁጥር፡</Typography>
    <Typography variant='body1'>{data.houseNumber}</Typography>
    </Grid>

  </Grid>
  <Box component='img' src={require('./assets/map.png')} sx={{marginTop:'65px'}} />
  <br /><br /><br />
<span style={{display:'flex',}}> <Typography variant='h5'> መረጃ</Typography><Healing style={{marginLeft:20,paddingTop:5}}/> </span>  
<hr />  
  <Grid container>
    <Grid item sm={4}>
    <Typography variant='h5'>የትምህርት ደረጃ፡</Typography>
    <Typography variant='body1'>{data.education} </Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የሚችሉት ቋንቋ፡</Typography>
    <Typography variant='body1'>{data.language} አክሲዮን</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የጋብቻ ሁኔታ፡</Typography>
    <Typography variant='body1'>{data.maritalStatus} </Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የሚከተሉት ሃይማኖት፡</Typography>
    <Typography variant='body1'>{data.religion}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ያላቸው ክህሎት፡</Typography>
    <Typography variant='body1'>{data.skill}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የስራ ድርሻቸው፡</Typography>
    <Typography variant='body1'>{data.position}</Typography>
    </Grid>

  </Grid>
  <br /><br /><br />
<span style={{display:'flex',}}> <Typography variant='h5'>መረጃዎች</Typography><Info style={{marginLeft:20,paddingTop:5}}/> </span>  
<hr />  
  <Grid container>
  <Grid item sm={4}>
    <Typography variant='h5'>ቁመት፡</Typography>
    <Typography variant='body1'>{data.hight} M</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ክብደት፡</Typography>
    <Typography variant='body1'>{data.weight} KG</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የደም አይነት፡</Typography>
    <Typography variant='body1'>{data.bloodType}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የ አይን ቀለም፡</Typography>
    <Typography variant='body1'>{data.eyeColor}</Typography>
    </Grid>

  </Grid>
  <br /><br /><br />
<span style={{display:'flex',}}> <Typography variant='h5'>ሌሎች መረጃዎች</Typography><Info style={{marginLeft:20,paddingTop:5}}/> </span>  
<hr />  
  <Grid container>
  <Grid item sm={4}>
    <Typography variant='h5'>በትርፍ ጊዜ የሚያደርጉት፡</Typography>
    <Typography variant='body1'>{data.hobbies}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>ሙያ፡</Typography>
    <Typography variant='body1'>{data.profession}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የደም አይነት፡</Typography>
    <Typography variant='body1'>{data.bloodType}</Typography>
    </Grid>
    <Grid item sm={4}>
    <Typography variant='h5'>የትውልድ ዘመን፡</Typography>
    <Typography variant='body1'>{data.dateOfBirth}</Typography>
    </Grid>

  </Grid>
  <br /><br /><br /><br /><br /><br />  <br /><br /><br /><br /><br /><br />

    </Grid>
    <Grid item sm={2}>
      <List>

      <ListItem>
          <ListItemIcon>
            <LocationCityOutlined/>
            </ListItemIcon>
            <ListItemText primary={data.city} secondary={'ቀበሌ '+data.kebele}/>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailOutlined/>
            </ListItemIcon>
            <ListItemText primary={data.email} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Phone/>
          </ListItemIcon>
          <ListItemText primary={data.phoneNumber} />

        </ListItem>
      </List>
    </Grid>
  </Grid>
  </Container>
  
</Container>
<footer style={{textAlign:'center',margin:15}}>
 <p>2023 EplusApp S.C</p> 
</footer>
</>
)
}
export default UserDetail