import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "./api";
const baseURL = api.address;

const initialState = {
    status:"none",
    error:"",
    neku:""
}

export const createfUser = createAsyncThunk('user/fetchUsers', async (user) => {
   
    const resp = await axios.post(baseURL+"/addressapi/users/create",user).then((response) => response)
    console.log(resp.data)
    return resp;
})



const createfuserSlice = createSlice({
    name:'users',
    initialState,
    reducers:{
        setdone:(state,action)=>{
            state.status = "";
            state.error = "";
        }

    },
    extraReducers:(builder)=>{
        builder.addCase(createfUser.fulfilled,(state,action)=>{
                    state.status = "success"
                    console.log(state.status)
                    console.log(action.message)
                    console.log(action.payload.data.user.userCode)
                   state.neku = action.payload.data.user.userCode

        });
        builder.addCase(createfUser.pending,(state,action)=>{
             state.status = "loading"
             console.log(state.status)
             console.log(action.message)


        });
        builder.addCase(createfUser.rejected,(state,action)=>{
            state.status ="failed"
            console.log(state.status,action)
            console.log(action.payload)


        });
    }
})



export const {setdone} = createfuserSlice.actions

export default createfuserSlice.reducer;