// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-UserCard-UserCardBody-styles-module__user-card-body--xMttR{padding:1rem;padding-top:4rem}.src-UserCard-UserCardBody-styles-module__user-card-body--xMttR .src-UserCard-UserCardBody-styles-module__user-card-name--lMiwl{display:flex;justify-content:center;font-size:1.25rem;margin-bottom:.25rem;color:#2c3e50}.src-UserCard-UserCardBody-styles-module__user-card-body--xMttR .src-UserCard-UserCardBody-styles-module__user-card-position-name--slXtz{display:flex;justify-content:center;font-size:.75rem;color:#7f8c8d}", "",{"version":3,"sources":["webpack://./src/UserCard/UserCardBody/styles.module.scss"],"names":[],"mappings":"AAAA,gEACE,YAAA,CACA,gBAAA,CAEA,gIACE,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,oBAAA,CACA,aAAA,CAGF,yIACE,YAAA,CACA,sBAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":[".user-card-body {\n  padding: 1rem;\n  padding-top: 4rem;\n\n  .user-card-name {\n    display: flex;\n    justify-content: center;\n    font-size: 1.25rem;\n    margin-bottom: 0.25rem;\n    color: #2c3e50;\n  }\n\n  .user-card-position-name {\n    display: flex;\n    justify-content: center;\n    font-size: 0.75rem;\n    color: #7f8c8d;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-card-body": "src-UserCard-UserCardBody-styles-module__user-card-body--xMttR",
	"user-card-name": "src-UserCard-UserCardBody-styles-module__user-card-name--lMiwl",
	"user-card-position-name": "src-UserCard-UserCardBody-styles-module__user-card-position-name--slXtz"
};
export default ___CSS_LOADER_EXPORT___;
