import React, { useEffect, useRef, useState } from "react";
import searchIconImg from '../assets/map/search.png' 
import {
  Button,
  Box,
  ButtonGroup,
  Grid,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  InputBase,
  Input,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Icon,
  Avatar,
  makeStyles,
} from "@mui/material";
import {
  ArrowBack,
  Cancel,
  Directions,
  DirectionsBike,
  DirectionsCar,
  DirectionsTransit,
  DirectionsWalk,
  ForkRight,
  GpsFixed,
  PointOfSaleRounded,
  RuleRounded,
  Share,
  Start,
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import GpsFixedTwoToneIcon from "@mui/icons-material/GpsFixedTwoTone";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import PublicIcon from "@mui/icons-material/Public";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  Polygon,
} from "@react-google-maps/api";
import { InfinitySpin, ProgressBar } from "react-loader-spinner";
import Loading from "../components/Loading";
import axios from "axios";
import "../assets/maps.css";
import api from "../data/api";

const MapsNew = (props) => {
  const [active, setactive] = props.states;
  const [center, setCenter] = useState({
    lat: 11.594502,
    lng: 37.388324,
    zoom: 17,
  });
  const [Msg, setMsg] = useState(null);
  const [travleMode, setTmode] = useState(1);
  const [dInfo, setDinfo] = useState({ show: false, distance: 0, time: 0 });
  const [detail, setDetail] = useState({});
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo",
    libraries: ["places"],
  });
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [maps, setMaps] = useState(/** @type google.maps */ (null));
  const [diResp, setDiResp] = useState(null);
  const [markMy, setMy] = useState(false);
  const [pointresp, setPointresp] = useState({});
  /**
       @type React.MutableObject<HTMLInputElement>
       */
  const searchText = useRef();
  const start = useRef();
  const end = useRef();
  const [localRoute, setLocalRoute] = useState({ start: null, end: null });
  const [current, setCurrent] = useState(null);
  const [address, setAddress] = useState({
    place_code: "...",
    region: "...",
    city: "...",
    sub_city: "...",
    kebele: "...",
    street_name: "...",
    postal_code: "6000",
    lat: 0,
    lng: 0,
  });
  const [path, setPaths] = useState([]);

  const infoStyle = {
    verticalAlign: "middle",
    marginRight: "0.7em",
    marignLeft: "0.4em",
    color: "#FF9900",
    fontweight: "bold",
  };

  const textStyle = {
    color: "#00a152",
    textTransform: "capitalize",
    variant: "body1",
    textAlign: "justify",
    paddingLeft: "2.5em",
  };

  const polygonOptions = {
    strokeColor: "#3D85C6",
    strokeOpacity: 0.9,
    strokeWeight: 1.5,
    fillOpacity: 0,
  };

  useEffect(() => {
    (async () => {
      setMsg("Loading GPS.........");
      if (navigator.geolocation) {
        let data = await navigator.geolocation.getCurrentPosition(
          async (postion) => {
            setTimeout(() => {
              setCenter({
                lat: postion.coords.latitude,
                lng: postion.coords.longitude,
                zoom: 18,
              });
              setMsg("");
            }, 1000);

            return {
              lat: postion.coords.latitude,
              lng: postion.coords.longitude,
            };
          }
        );
      } else alert("አባክዎ Location Allow ይበሉት!");
    })();
  }, []);

  const handleInputChange = (event) => {
    const inputValue = searchText.current.value;
    const regMatch = /^[a-zA-Z]*$/.test(inputValue);
    // the coordinates value to overlay the polygon
    let coords = [];

    if (inputValue.length === 2 && regMatch) {
      // fetch("http://localhost:11215/sub_cities", {
      axios
        .post(`${api.address}/addressapi/cities/sub-city-polygon`, {
          code: inputValue,
        })
        .then((response) => {
          let data = response.data;

          // computing the polygon coordinates
          if (data.results[0]) {
            data.results[0].geo.coordinates.forEach((value) => {
              coords = value.map((pair) => {
                const [lng, lat] = [pair[0], pair[1]].map(parseFloat);

                return { lat, lng };
              });
            });
            console.log("the user data is" + JSON.stringify(coords));

            // calling to adjust the center of the map
            if (coords.length > 0) {
              // set the polygon data
              setPaths(coords);

              let { lat, lng } = findPolygonCenter(coords);

              lat = lat.toFixed(6);
              lng = lng.toFixed(6);

              const data = { lat, lng };
              setData(data);
            }
          }
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          setPaths([]);
          console.error("Error:", error);
        });
    } else {
      setPaths([]);
    } // Remove non-numeric characters
  };

  const findPolygonCenter = (vertices) => {
    const n = vertices.length;

    let lat = 0;
    let lng = 0;
    let area = 0;

    for (let i = 0; i < n; i++) {
      const j = (i + 1) % n;

      const crossProduct =
        vertices[i].lat * vertices[j].lng - vertices[j].lat * vertices[i].lng;
      area += crossProduct;
      lat += (vertices[i].lat + vertices[j].lat) * crossProduct;
      lng += (vertices[i].lng + vertices[j].lng) * crossProduct;
    }

    area /= 2;
    lat /= 6 * area;
    lng /= 6 * area;

    return { lat, lng };
  };

  function setData(data) {
    let lat = parseFloat(data.lat);
    let lng = parseFloat(data.lng);

    setCenter((prevCenter) => ({ ...prevCenter, lat: lat, lng: lng }));
    setMy(true);
  }

  const handleMapClick = async (e) => {
    let { lat, lng } = e.latLng.toJSON();

    lat = lat.toFixed(6);
    lng = lng.toFixed(6);

    searchPlace(lat, lng);
    detailInfo(lat, lng);
  };

  // region and city information
  async function detailInfo(lat, lng) {
    let point = { lat, lng };
    setCurrent(point);

    await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
          lat + "," + lng
        }&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`
      )
      .then((result) => {
        const data = result.data.results[2];

        const region =
          data.address_components.length === 4
            ? data.address_components[2].long_name
            : data.address_components[3].long_name;
        const city =
          data.address_components.length === 4
            ? data.address_components[0].long_name
            : data.address_components[1].long_name;

        setAddress((prevAddress) => ({
          ...prevAddress,
          region: region,
          city: city,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // to search th eplace using latitude and longitude coordinates
  function searchPlace(lat, lng) {
    axios
      // .post("http://localhost:11215/polygons", { lat: lat, lng: lng })
      .post(`${api.address}/addressapi/cities/search-address`, {
        lat: lat,
        lng: lng,
      })
      .then((response) => {
        let data = response.data;
        if (response.status === 200) {
          let keb = data.cities[0].kebele;
          let sub = data.cities[0].sub_city;

          // set the street name after checking if their is nearest street or not
          let street = "";
          if (data.street.length > 0) {
            street = data.street[0].road_name;
          }

          let w_grid = data.grid.w_grid;
          let h_grid = data.grid.h_grid;

          let pid =
            data.grid.pid +
            w_grid.slice(0, 1) +
            "-" +
            w_grid.slice(1, w_grid.length) +
            "-" +
            h_grid;
          // set address
          setAddress((prevAddress) => ({
            ...prevAddress,
            place_code: pid,
            sub_city: sub,
            kebele: keb,
            street_name: street,
            lat: lat,
            lng: lng,
          }));

          lat = parseFloat(lat);
          lng = parseFloat(lng);

          setData({ lat, lng });
        } else {
          let address = [{ kebele: "", sub_city: data, coded: false }];

          setAddress((prevAddress) => ({
            ...prevAddress,
            place_code: "...",
            sub_city: "...",
            kebele: "...",
            street: "",
            lat: lat,
            lng: lng,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // search for a place using place code or latitude and longitude
  const handleSubmit = (event) => {
    // check whether the user wants to search with latitude and longitude or not
    const check_number = /^[\d\s,.-]+$/;
    let place_id;
    const placeId = searchText.current.value;

    if (check_number.test(placeId)) {
      place_id = placeId.replace(/(?<=\d.)\s+(?=\d.)/g, "");

      let [lat, lng] = place_id.split(",");
      lat = parseFloat(lat);
      lng = parseFloat(lng);

      searchPlace(lat, lng);
    } else {
      place_id = placeId.replace(/[^a-zA-Z0-9 ]/g, "");

      let pid = place_id.slice(0, 2);
      let w_grid = place_id.slice(2, 6);
      let h_grid = place_id.slice(6, 10);

      if (place_id.length === 10) {
        // Send the placeId to the backend
        // axios("http://localhost:11215/search", {
        axios
          .post(
            `${api.address}/addressapi/cities/search-coordinates`,
            {
              pid: pid,
              w_grid: w_grid,
              h_grid: h_grid,
            }
          )
          .then((response) => {
            let data = response.data;

            console.log(data);
            let keb = data.cities[0].kebele;
            let sub = data.cities[0].sub_city;

            // set the street name after checking if their is nearest street or not
            let street = "";
            if (data.street.length > 0) {
              street = data.street[0].road_name;
            }

            let w_grid = data.grid.w_grid + "";
            let h_grid = data.grid.h_grid + "";

            let pid =
              data.grid.pid +
              w_grid.slice(0, 1) +
              "-" +
              w_grid.slice(1, w_grid.length) +
              "-" +
              h_grid;
            // set address
            setAddress((prevAddress) => ({
              ...prevAddress,
              place_code: pid,
              sub_city: sub,
              kebele: keb,
              street_name: street,
              lat: data.coordinates.lat,
              lng: data.coordinates.lng,
            }));

            detailInfo(data.coordinates.lat, data.coordinates.lng);
            setData(data.coordinates);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.log("Please enter a valid address code like AT1-411-6452");
      }
    }
  };

  return (
    <Box
      style={{
        display: active == 7 ? "block" : "none",
        transition: "eas-in-out 0.5s",
      }}
    >
      <Toolbar style={{ borderBottom: "solid 1px #0099ff" }}>
        <GpsFixed />
        &nbsp; <Typography variant="h5">አድራሻ</Typography>
        <Button
          color="info"
          startIcon={
            <img style={{ width: "30px" }} src={require("../assets/man.png")} />
          }
          size="large"
          sx={{
            marginLeft: "20vw",
          }}
          onClick={async (e) => {
            await navigator.geolocation.getCurrentPosition((pos) => {
              setCenter({
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
                zoom: 20,
              });
            });
            map.panTo(center);
            setMy(true);
          }}
        >
          የኔ መገኛ
        </Button>
        <div
          style={{
            marginLeft: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "50%",
          }}
        >
          <select
            style={{
              padding: "12px 20px",
              margin: "8px 0",
              display: "inline-block",
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxSizing: "border-box",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
            value={travleMode}
            onChange={(e) => setTmode(e.target.value)}
          >
            <option value={1}>የግር ጉዞ</option>
            <option value={2}>መኪና</option>
            <option value={3}>ብስክሌት</option>
            <option value={4}>ባቡር</option>
          </select>
          {isLoaded ? (
            <Autocomplete style={{ marginLeft: 5 }}>
              <input
                ref={start}
                placeholder="መነሻ"
                style={{
                  width: "100%",
                  padding: "12px 20px",
                  margin: "8px 0",
                  display: "inline-block",
                  border: "1px solid #ccc",
                  boxSizing: "border-box",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                onBlur={async (e) => {
                  await axios
                    .get(
                      `https://maps.googleapis.com/maps/api/geocode/json?address=${start.current.value}&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`
                    )
                    .then((result) => {
                      let newCent = result.data.results[0].geometry.location;
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
            </Autocomplete>
          ) : (
            "Loading..."
          )}

          {isLoaded ? (
            <Autocomplete>
              <input
                ref={end}
                placeholder="መዳረሻ"
                style={{
                  width: "1@0%",
                  padding: "12px 20px",
                  margin: "8px 0",
                  display: "inline-block",
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  boxSizing: "border-box",
                }}
                onBlur={async (e) => {
                  await axios
                    .get(
                      `https://maps.googleapis.com/maps/api/geocode/json?address=${end.current.value}&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`
                    )
                    .then((result) => {
                      let newCent = result.data.results[0].geometry.location;
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
            </Autocomplete>
          ) : (
            "Loading..."
          )}
          <Button
            variant="contained"
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              padding: "8px 20px",
            }}
            onClick={async (e) => {
              if (start.current.value === "" || end.current.value === "")
                start.current.value === ""
                  ? start.current.focus()
                  : end.current.focus();
              else {
                const google = window.google;
                const directionServ = new google.maps.DirectionsService();
                const result = await directionServ.route({
                  origin: start.current.value,
                  destination: end.current.value,
                  travelMode: google.maps.TravelMode.DRIVING,
                });
                setDiResp(result);
                setDinfo({
                  show: true,
                  distance: result.routes[0].legs[0].distance.text,
                  time: result.routes[0].legs[0].duration.text,
                });
              }
            }}
            startIcon={<Directions />}
          >
            መንገድ ጠቁም
          </Button>
        </div>
        <Paper
          elevation={4}
          style={{
            display: dInfo.show ? "flex" : "none",
            position: "absolute",
            right: "18%",
            padding: "12px",
            top: "120%",
            zIndex: "3",
            width: "50%",
            transition: "ease-in-out 0.5se",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Typography variant="h5"> እርቅት</Typography>
          <Typography variant="h6" color="#0099fe">
            &nbsp;{dInfo.distance}
          </Typography>
          <Typography style={{ marginLeft: 25 }} variant="h5">
            የሚወስደው ስኣት
          </Typography>
          <Typography variant="h6" color="#0099fe">
            &nbsp; {dInfo.time}
          </Typography>
          <Typography variant="h6" color="#0099fe">
            &nbsp;{" "}
            {travleMode == 1
              ? "በግር"
              : travleMode == 2
              ? "በመኪና"
              : travleMode == 3
              ? "በብስክሌት"
              : "በባቡር"}
          </Typography>
          <Cancel
            color="warning"
            onClick={(e) => {
              start.current.value = null;
              end.current.value = null;
              setDinfo({ show: false, time: 0, distance: 0 });
              setLocalRoute({ start: null, end: null });
            }}
          />
        </Paper>
      </Toolbar>
      <Grid container>
        <Grid
          item
          md={3}
          sx={{ padding: 2, height: "80vh", borderRight: "solid 1px #0099ff" }}
        >
          {isLoaded ? (
            <Autocomplete style={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={11}>
                  <input
                    placeholder="ቦታን ለመፈለግ"
                    ref={searchText}
                    style={{
                      width: "100%",
                      padding: "12px 20px",
                      margin: "4px 2px",
                      display: "inline-block",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                    }}
                    onChange={handleInputChange}
                    onBlur={async (e) => {
                      await axios
                        .get(
                          `https://maps.googleapis.com/maps/api/geocode/json?address=${searchText.current.value}&key=AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo`
                        )
                        .then((result) => {
                          let newCent =
                            result.data.results[0].geometry.location;

                          setCenter(newCent);
                          setMy(false);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    size="small"
                    color="#ffea00"
                    onClick={handleSubmit}
                  >
                    <Avatar
                      color="primary"
                      style={{ backgroundColor: "#8bc34a" }}
                      sr
                    >
                      <img
                        src={searchIconImg}
                        alt="Logo"
                        style={{ width: "60px" }}
                      />
                      {/* <TravelExploreIcon color="primary" /> */}
                    </Avatar>
                  </IconButton>
                </Grid>
              </Grid>
            </Autocomplete>
          ) : (
            "Loading..."
          )}
          <br />
          <Typography variant="h5" style={infoStyle} color="warning">
            <b>Address Information </b>
          </Typography>

          <hr style={{ borderColor: "warning" }} />

          <Typography variant="h7" color="primary">
            <GpsFixedTwoToneIcon style={infoStyle} color="primary" />
            Digital Address Code
          </Typography>
          <Typography style={textStyle}>
            <b>{address.place_code}</b>
          </Typography>
          <hr />
          <Typography variant="h7" color="primary">
            <PublicIcon style={infoStyle} color="primary" />
            City
          </Typography>
          <Typography style={textStyle}>{address.city}</Typography>
          <hr />
          <Typography variant="h7" color="primary" component="span">
            <AssistantDirectionIcon style={infoStyle} color="primary" />
            Street Name
          </Typography>
          <Typography style={textStyle}>{address.street_name}</Typography>
          <hr />
          <Typography variant="h7" color="primary">
            <ShareLocationIcon style={infoStyle} color="primary" />
            Sub-City
          </Typography>
          <Typography style={textStyle}>{address.sub_city}</Typography>
          <hr />
          <Typography variant="h7" color="primary" component="span">
            <HolidayVillageIcon style={infoStyle} color="primary" />
            Kebele
          </Typography>
          <Typography style={textStyle}>{address.kebele}</Typography>
          <hr />
          <Typography variant="h7" color="primary" component="span">
            <LocationOnIcon style={infoStyle} color="primary" />
            Latitude, Longitude
          </Typography>
          <Typography style={textStyle}>
            {address.lat}, {address.lng}
          </Typography>
          <hr />
          <br />
          <ButtonGroup
            style={{
              width: "100%",
              margin: 4,
            }}
          >
            <select
              style={{
                padding: "12px 20px",
                display: "inline-block",
                border: "1px solid #FF9900",
                borderRadius: "8px",
                boxSizing: "border-box",
                borderTopRightRadius: "0px",
                height: "100%",
                borderBottomRightRadius: "0px",
              }}
              value={travleMode}
              onChange={(e) => setTmode(e.target.value)}
            >
              <option value={1}>የግር ጉዞ</option>
              <option value={2}>መኪና</option>
              <option value={3}>ብስክሌት</option>
              <option value={4}>ባቡር</option>
            </select>

            <Button
              disabled={localRoute.start == null ? false : true}
              onClick={(e) => {
                setLocalRoute({ ...localRoute, start: center });
                e.target.disabled = true;
              }}
              style={{
                width: "100%",
                color: "white",
              }}
              color="info"
              variant="contained"
              startIcon={<Directions />}
            >
              መነሻየ
            </Button>
            <Button
              onClick={async (e) => {
                setLocalRoute({ ...localRoute, end: center });
                console.log(travleMode)
               // e.target.disabled = true;
                if (localRoute.start == null) {
                  alert("Set መነሻየ first!");
                } else {
                  const google = window.google;
                  const directionServ = new google.maps.DirectionsService();
                  const result = await directionServ.route({
                    origin: localRoute.start,
                    destination: center,
                    travelMode:
                      travleMode == 1
                        ? google.maps.TravelMode.WALKING
                        : travleMode == 2
                        ? google.maps.TravelMode.DRIVING
                        : travleMode == 3
                        ? google.maps.TravelMode.BICYCLING
                        : google.maps.TravelMode.TRANSIT,
                  })
                  .then((result) => {
                    
                    return result;
                  }).catch((err) => {
                    console.log(err)
                    alert("No rout found")
                  });
                  console.log(result)
                  
                  setDiResp(result);
                  setDinfo({
                    show: true,
                    distance: result?.routes[0].legs[0].distance.text,
                    time: result?.routes[0].legs[0].duration.text,
                  });
                }
              }}
              style={{
                width: "100%",
                color: "white",
              }}
              startIcon={<Directions />}
              color="warning"
              variant="contained"
            >
              መዳረሻየ
            </Button>
          </ButtonGroup>
          <br />
          <Button
            style={{
              width: "100%",
              color: "white",
            }}
            startIcon={<Share />}
            variant="contained"
            color="secondary"
          >
            Share
          </Button>
        </Grid>

        <Grid item md={9} sx={{ padding: 0 }}>
          {isLoaded ? (
            <GoogleMap
              center={center}
              zoom={center.zoom}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              options={{
                streetViewControl: false,
                mapTypeControl: true,
                fullscreenControl: false,
                mapTypeControlOptions: {
                  mapTypeIds: ["roadmap", "satellite", "hybrid"],
                },
              }}
              onLoad={(map) => setMap(map)}
              onClick={handleMapClick}
            >
              {path.length > 0 ? (
                <Polygon
                  path={path}
                  options={polygonOptions}
                  onClick={handleMapClick}
                />
              ) : (
                <></>
              )}

              {markMy ? (
                <Marker
                  position={center}
                  onClick={(e) => {
                    console.log(e);
                  }}
                  icon={require("../assets/man.png")}
                  children={<GpsFixed />}
                />
              ) : (
                <></>
              )}
              {diResp !=null?<DirectionsRenderer directions={diResp} />:<></>}

              {/* {current ? (
                <Marker
                  icon={require("../assets/flag.png")}
                  position={center}
                />
              ) : (
                ""
              )} */}
            </GoogleMap>
          ) : (
            <Typography color="purple" variant="h5" style={{ margin: 10 }}>
              Loading map...
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};


export default MapsNew;