import { Avatar, Box, Button, ButtonGroup, Card, CardContent, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import lang from "../lang.json"
import axios from 'axios'
import api from '../../../data/api'
import { useSelector } from 'react-redux'

const StudentList = () => {

    const text = lang[0]
    const [searchResult, setSearchResult] = useState([])
    const [userDetail,setUserDetail] = useState({})
    const [studentDetail,setStudentDetail] = useState({})
    const logedUser = useSelector((state)=>state.logedUser)




    useEffect(()=>{
            (async()=>{
                if(userDetail?.id != undefined)
                await axios.get(`${api.address}/addressapi/students/${userDetail.id}`,{
            headers:{
                'Authorization': `Bearer ${logedUser.user}`
            }})
            .then((result) => {
                setStudentDetail(result.data)
                console.log(result)

            }).catch((err) => {
                console.error(err)   
            });

            })()
    },[userDetail])

  return (

    <Box>
    <Grid container >
        <Grid item md={3} sx={{padding:1}}> 
            <TextField
            fullWidth
            variant='outlined'
            placeholder={text.textSearchUser}
            onChange={async e=>{
                let value = e.target.value;
                await axios.get(`${api.address}/addressapi/users/search?key=${value}`)
                .then((result) => {
                    setSearchResult(result.data)
                }).catch((err) => {
                    
                });

            }}
            />
        <List>
            {
                
                searchResult.map((user)=>(
                <ListItem onClick={e=>setUserDetail(user)} sx={{cursor:"pointer"}}>
                <ListItemAvatar>
                    <Avatar/>
                </ListItemAvatar>
                <ListItemText primary={<Typography>{user.firstName+` `+user.lastName}</Typography>} secondary={<Typography>{user.userCode}</Typography>} />
                </ListItem>))
            }
        </List>


        </Grid>
        <Grid item md={9}>
            <Card>
                <CardContent>
                    <Typography variant='h6' color="GrayText">{text.detailInfo}</Typography>
                    <Box>
                        <Grid container>
                            <Grid item md={3} sx={{padding:1}}>
                                <Avatar sx={{width:"100px",height:"100px"}} />
                            </Grid>
                            <Grid item md={4}>
                                <Divider/>
                                <br />
                                <Typography variant='h6' color="GrayText">{userDetail.firstName+' '+userDetail.middleName+" "+userDetail.lastName}</Typography>
                                <Typography variant='h6' color="GrayText">{text.userCode} {userDetail.userCode}</Typography>

                            </Grid>
                            <Grid item md={4}>
                            <Divider/>
                            <br />
                            <Typography variant='h6' color="GrayText">Email:</Typography>
                            <Typography variant='h6' color="GrayText">Phone:</Typography>
                            <Typography variant='h6' color="GrayText">Address:</Typography>
                            </Grid>
                            <br />
                            <Grid item md={12} sx={{marginTop:4}}>
                                <Divider/>
                            </Grid>
                            <Grid item md={12} sx={{marginTop:4}}>
                            <Typography variant='h6' color="GrayText">{text.posDesc}</Typography>
                            <Typography variant='body1' color="GrayText">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eligendi velit consequatur quia doloribus a autem, voluptatum sequi! Nobis sunt magni quod facilis sequi mollitia illum tempore molestiae dolorem esse!
                                </Typography>                                
                            </Grid>
                            <Grid item md={12} sx={{marginTop:4}}>
                                <Divider/>
                            </Grid>
                            <Grid item md={12} sx={{padding:2}}>
                                <ButtonGroup variant='outlined'>
                                <Button color='error'>
                                        {text.remove}
                                    </Button>
                                    <Button color='warning'>
                                        {text.reassign}
                                    </Button>
                                </ButtonGroup>
                            </Grid>

                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Grid>    
    </Grid>
   </Box>


    )
}

export default StudentList