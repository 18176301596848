import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { DoubleArrow } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ModalC = (props)=> {
  const md = props.modal;
  return (
      <Dialog
        open={md.show}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle>{md.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <Box sx={{width: '134px',display: 'block',margin: 'auto'}} src={md.img} component="img"/>
          <span dangerouslySetInnerHTML={{ __html:md.body}}></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to={md.loc}>
          <Button endIcon={<DoubleArrow/>} color="success" onClick={()=>md.show = false} variant='contained'>{md.btn}</Button>
          </Link>
        </DialogActions>
      </Dialog>
  );
}



// Ths is how it works const [modal,setmodal] = useState({title:"Title",body:"Body",show:false,btn:"Button",loc:""});



export default ModalC