import { Avatar, Box, Button, ButtonGroup, Card, CardContent, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material'
import React from 'react'
import lang from "../lang.json"
const EmpList = () => {
    const text = lang[0]
  return (
   <Box>
    <Grid container >
        <Grid item md={3} sx={{padding:1}}> 
            <TextField
            fullWidth
            variant='outlined'
            placeholder={text.textSearchUser}
            />
        <List>
            {
                
                [1,2,3,4,5,6,7,8,9].map((userNumber)=>(<ListItem>
                <ListItemAvatar>
                    <Avatar/>
                </ListItemAvatar>
                <ListItemText primary={<Typography>User {userNumber}</Typography>} secondary={<Typography>User neku Number</Typography>} />
                </ListItem>))
            }
        </List>


        </Grid>
        <Grid item md={9}>
            <Card>
                <CardContent>
                    <Typography variant='h6' color="GrayText">{text.detailInfo}</Typography>
                    <Box>
                        <Grid container>
                            <Grid item md={4} sx={{padding:1}}>
                                <Avatar sx={{width:"100px",height:"100px"}} />
                            </Grid>
                            <Grid item md={4}>
                                <Divider/>
                                <br />
                            <Typography variant='h6' color="GrayText">Full Name:</Typography>
                            <Typography variant='h6' color="GrayText">Father Name:</Typography>
                            <Typography variant='h6' color="GrayText">Last Name:</Typography>
                            </Grid>
                            <Grid item md={4}>
                            <Divider/>
                            <br />
                            <Typography variant='h6' color="GrayText">Email:</Typography>
                            <Typography variant='h6' color="GrayText">Phone:</Typography>
                            <Typography variant='h6' color="GrayText">Address:</Typography>
                            </Grid>
                            <br />
                            <Grid item md={12} sx={{marginTop:4}}>
                                <Divider/>
                            </Grid>
                            <Grid item md={12} sx={{marginTop:4}}>
                            <Typography variant='h6' color="GrayText">{text.posDesc}</Typography>
                            <Typography variant='body1' color="GrayText">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eligendi velit consequatur quia doloribus a autem, voluptatum sequi! Nobis sunt magni quod facilis sequi mollitia illum tempore molestiae dolorem esse!
                                </Typography>                                
                            </Grid>
                            <Grid item md={12} sx={{marginTop:4}}>
                                <Divider/>
                            </Grid>
                            <Grid item md={12} sx={{padding:2}}>
                                <ButtonGroup variant='outlined'>
                                <Button color='error'>
                                        {text.remove}
                                    </Button>
                                    <Button color='warning'>
                                        {text.reassign}
                                    </Button>
                                </ButtonGroup>
                            </Grid>

                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Grid>    
    </Grid>
   </Box>
  )
}

export default EmpList