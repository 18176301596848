import { Send, UploadFile } from '@mui/icons-material'
import { Button, Box, CardContent, Grid, Container, TextField, Card, CardHeader, Typography, Divider, Select, MenuItem, Paper, CardActions, Alert } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import api from '../data/api'

const Stock = (props) => {


    const logedUser = useSelector(state=>state.logedUser)
    const [active,setactive] = props.states
    const fileUpload = useRef();
    const user = useSelector((state)=>state.logedUser)
    const [stockForm,setStockForm] = useState({
        name: null,
        description: null,
        type: null,
        unitPrice: null,
        logo: null,
        quantity: null,
        currency: null,
        orgId: null,
        startingDate:null,
        closingDate: null
    })
    const [alertInfo,setAlert] = useState({show:false,msg:"",type:"error"});
    const [org,setOrg] = useState([]);
    useEffect(()=>{

        (async ()=>{
            await axios.get(`${api.address}/addressapi/organizations/fetchownorgs/`,{
                headers: {
                    'Authorization': `Bearer ${logedUser.user}`
                  }   
            })
            .then((result) => {
                setOrg(result.data)
            }).catch((err) => {
                console.log(err)
                setAlert({show:true,msg:"የሰርቨር ችግር ኣል",type:'warning'})
            });

        })()

    },[])

    const submit = async ()=>{

        if(stockForm.name == null || stockForm.description == null || stockForm.unitPrice == null || stockForm.type == null || stockForm.quantity == null || stockForm.startingDate == null)
        {
            setAlert({show:true,msg:"ኣስፈላጊ መጠይቆችን ያምዋሉ!", type:"error"});

        }
        else 
        {
            if(stockForm.type == "bid")
            {
                axios.post(`${api.stock}stocks/create`,stockForm,{
                    headers:{
                        Authorization:`Bearer ${logedUser.user}`
                    }
                })
                .then((result) => {
                    setAlert({show:true,msg:"ኣክሲዮኑ በተሳካ ሁኔታ ገብትዋል",type:"success"});
                }).catch((err) => {
                    setAlert({show:true,msg:"ኣልተሳካም",type:"error"});
                });
            }
            else 
            {
                axios.post(`${api.stock}bids/create`,stockForm,{
                    headers:{
                        Authorization:`Bearer ${logedUser.user}`
                    }
                })
                .then((result) => {
                    setAlert({show:true,msg:"ጨረታው በተሳካ ሁኔታ ገብትዋል",type:"success"});
                }).catch((err) => {
                    setAlert({show:true,msg:"ኣልተሳካም",type:"error"});
                });
                
            }
        }

    }
    


  return (
    <Box style={{display: active == 6?'block':'none', transition: 'eas-in-out 0.5s'}}>
      <Grid container>
        <Grid item md={8}>
        <Container>
            <Card>
                <CardContent>
                <CardHeader title={
                <>
                <Typography variant='h4'>የአክሲዮን ገባያ!</Typography>
                <Typography variant='body1'>የ አክሲዮን ገባያ ማስተዳደርያ</Typography> 
                </>} />
                <Divider /><br />
                <Typography variant='body2'>ኣዲስ የኣክሲዮን ሽያጭ</Typography>
                   {alertInfo.show?<Alert severity={alertInfo.type} onClose={e=>{setAlert({...alertInfo,show:false})}} >
                    <Typography>{alertInfo.msg}</Typography>
                   </Alert>:""}
                    <Grid container>
                    <Grid item md={4}sx={{padding:1}}>
                        <Typography variant='body1'>የኣክሲዮን ስም</Typography>
                        <TextField value={stockForm.name} onChange={e=>setStockForm({...stockForm,name:e.target.value})} placeholder='የኣክሲዮን ስም' variant='filled' fullWidth />
                    </Grid>
                    <Grid item md={4} sx={{padding:1}}>
                    <Typography variant='body1'>ዋጋ</Typography>
                    <TextField type='number' placeholder='ዋጋ' value={stockForm.unitPrice} onChange={e=>setStockForm({...stockForm,unitPrice:e.target.value})} variant='filled' fullWidth />
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant='body1'>ገለፃ</Typography>
                        <TextField
                        multiline 
                        rows={5}
                        fullWidth
                        placeholder='ገለፃ'
                        value={stockForm.description}
                        onChange={e=>setStockForm({...stockForm,description:e.target.value})}
                        />
                    </Grid>
                    <Grid item md={3} sx={{padding:1}}>
                        <Typography variant='body1'>ድርጅት</Typography>
                    <Select fullWidth placeholder='ኣይነት' value={stockForm.orgId} onChange={e=>setStockForm({...stockForm,orgId:e.target.value})} label="ኣይነት">
                        {
                            org.map(org=>(
                                <MenuItem value={org.id}>
                                    {org.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    </Grid>

                    <Grid item md={3} sx={{padding:1}}>
                        <Typography variant='body1'>የኣክሲዮን ኣይነት</Typography>
                    <Select fullWidth placeholder='ኣይነት' value={stockForm.type} onChange={e=>setStockForm({...stockForm,type:e.target.value})} label="ኣይነት">
                        <MenuItem value="bid">
                        ጨረታ
                        </MenuItem>
                        <MenuItem value="stock">
                        አክሲዮን
                        </MenuItem>
                    </Select>
                    </Grid>


                    <Grid item md={4} sx={{padding:1}}>
                        <Typography variant='body1'>ሎጎ</Typography>
                        <Button onClick={e=>fileUpload.current.click()} startIcon={<UploadFile/>} variant='contained' size='large' fullWidth>ሎጎ</Button>
                        <input ref={fileUpload} type="file"  style={{
                            display:"none"
                        }} onChange={e=>{

                        }} />
                    </Grid>
                    <Grid item md={4}sx={{padding:1}}>
                    <Typography>ብዛት</Typography> 
                    <TextField type='number' placeholder='ብዛት'onChange={e=>setStockForm({...stockForm,quantity:e.target.value})} value={stockForm.quantity} variant='filled' fullWidth />
                    </Grid>
                    <Grid item md={4} sx={{padding:1}}>
                     <Typography>መገበያያው</Typography> 
                    <Select fullWidth placeholder='መገበያያው' value={stockForm.currency} onChange={e=>setStockForm({...stockForm,currency:e.target.value})}  label="መገበያያው">
                        <MenuItem value="dollar">
                        ዶላር
                        </MenuItem>
                        <MenuItem value="Birr">
                        ብር
                        </MenuItem>
                    </Select>
                    </Grid>
                    <Grid item md={4} sx={{padding:1}}>
                    <Typography>የሚጀመርበት ቀን</Typography> 
                    <TextField placeholder="የሚጀመርበት ቀን" value={stockForm.startingDate} onChange={e=>setStockForm({...stockForm,startingDate:e.target.value})} type='date' variant='filled' fullWidth />
                    </Grid>
                    <Grid item md={4} sx={{padding:1}}>
                    <Typography>የሚዘጋበት ቀን</Typography> 
                    <TextField placeholder="የሚዘጋበት ቀን" value={stockForm.closingDate} onChange={e=>setStockForm({...stockForm,closingDate:e.target.value})} type='date' variant='filled' fullWidth />
                    </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant='contained' onClick={submit} startIcon={<Send/>}>ላክ</Button>
                </CardActions>
            </Card>
            </Container>

            </Grid>
            <Grid item md={4}>

                        <br />
                        <Typography>List of stocks on market</Typography>
            </Grid>
            </Grid>
    </Box>
  )
}

export default Stock