// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-FlippingCard-FlippingCardBack-styles-module__back--CEs3J{position:absolute;width:100%;height:100%;backface-visibility:hidden;top:0;left:0;transform:rotateY(180deg);border-radius:.25rem;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/FlippingCard/FlippingCardBack/styles.module.scss"],"names":[],"mappings":"AAAA,8DACE,iBAAA,CACA,UAAA,CACA,WAAA,CAEA,0BAAA,CACA,KAAA,CACA,MAAA,CACA,yBAAA,CACA,oBAAA,CACA,eAAA","sourcesContent":[".back {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n\n  backface-visibility: hidden;\n  top: 0;\n  left: 0;\n  transform: rotateY(180deg);\n  border-radius: 0.25rem;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back": "src-FlippingCard-FlippingCardBack-styles-module__back--CEs3J"
};
export default ___CSS_LOADER_EXPORT___;
