import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import api from './api'
import { queries } from '@testing-library/react'
const baseURL = api.address

export const MyProduct = createApi({
    reducerPath:"MyProduct",
    baseQuery:fetchBaseQuery({baseUrl:baseURL}),
    tagTypes:["post"],
    endpoints:(builder)=>({
        fetchCatag:builder.query({
            query:()=>({ 
          url:"/addressapi/category/all",
           method:"GET"
            })
        })

    })
})


export const {useFetchCatagQuery} = MyProduct




// queriy:()=>{{
//     url:"/addressapi/category/all",
//     method:"Get"
// }}