import React from 'react'
import {Button, ButtonGroup, TextField} from '@mui/material'
import { Add, ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded, Done, UploadFile } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Loading from '../../components/Loading'
import { useState } from 'react'
import axios from 'axios'
import api from '../../data/api'
import { useSelector } from 'react-redux'
import SnackBar from '../../components/SnackBar'
const baseURL = api.address;
const Org8 = () => {

      const {state} = useLocation();
      const orgId = state.id;
      const link = useNavigate();    
      const {register,handleSubmit} = useForm();
      const [load,setload] = useState(false);
      const [form1,setform1] = useState(state.plan);
      const [form2,setform2] = useState(state.train);
      const [form3,setform3] = useState(state.job);
      const token  = useSelector((state)=>state.logedUser.user)
      const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});

      const submit = async data => {
            data['orgId'] = orgId;
            console.log(data,token);
            setload(true);
            const create = await axios.post(`${baseURL}/addressapi/plans/create`,data,{
                  headers: {
                        'Authorization': `Bearer ${token}`
                      }   
            }).then((resp)=>{
                  console.log("REsponse",resp)

                  if(resp?.data != null)
                  {
                        setform1(false);
                        setsnack({Type:"info",msg:"መረጃው ተመዝግብዋል!",show:true})

                  }
                  setload(false);
                  return resp;

            }).catch((err)=>{
                  setload(false);
                  console.log("ERROR",err);
                  return err;
            });

            return create;


      }


      const submit1 = async data => {
            data['orgId'] = orgId;
            console.log(data);
                  setload(true);
            const create = await axios.post(`${baseURL}/addressapi/trainings/create`,data,{
                  headers: {
                        'Authorization': `Bearer ${token}`
                      }
            }).then((resp)=>{
                  console.log(resp)
                  setload(false);
                  
                  if(resp?.data != null)
                  {
                        setform2(false);

                        setsnack({Type:"info",msg:"መረጃው ተመዝግብዋል!",show:true})
                  }


                  return resp;
            }).catch((err)=>{
                  console.log(err);
                  setload(false);

                  return err;
            });
            return create;
      }




      const submit2 = async data => {

            data['orgId'] = orgId;
            console.log(data);
            setload(true);
            const jobskill = await axios.post(`${baseURL}/addressapi/jobskills/create`,data,{
                  headers:{
                        'Authorization':`Bearer ${token}`
                  }
            }).then((resp)=>{
                  setload(false);
                  console.log(resp)
                  if(resp.data?.updatedAt)
                  {
                        link('../view',{state:{id:orgId}});        

                  }
                  else 
                  {
                        alert("ስህተት አለ!")
                  }
                  return resp;
            }).catch((resp)=>{

                  if(resp.response?.data)
                  alert(resp.response?.data.message)
                  setload(false);
                  console.log(resp);
                  return resp;
            })
            
            return jobskill;
      }


      
  return (

    <Card>
      <Loading load={load} />
      <SnackBar snack={snack} />
    <CardContent sx={{width:"100%"}}>
       
      {form1? <form onSubmit={handleSubmit(submit)}>
    <Grid container >

    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h5' color="primary">እቅድ እና ጉጉት!</Typography>


        <TextField
          id="standard-multiline-static"
          label="ፍኖተ ካርታ መስጠት"
          multiline
          {...register('roadMap',{
            required:{
                  value:true,
            }
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
        
        <TextField
          id="standard-multiline-static"
          label="የማድረግ አቅምና ትኩረትን ማግኘት"
          multiline
          {...register('potential',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
        <TextField
          id="standard-multiline-static"
          label="የበጀት ምንጭን ማሳየት"
          multiline
          {...register('budgetSource',
{          required:true}          )}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="አማራጭ አቅጣጫን መጠቆም"
          multiline
          {...register('alternativeDirection',{required:true})}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />


       </Grid>
       <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>

       <TextField
          id="standard-multiline-static"
          label="የተግባቦት አውታር መፍጠር"
          multiline
          rows={2}
          {...register('communicationNetwork',{required:true})}
          variant="standard"
          style={{width:"100%"}}
          />



<TextField
          id="standard-multiline-static"
          label="የስኬት እድልን ማሳየት"
          multiline
          {...register('success')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />


<TextField
          id="standard-multiline-static"
          label="የገበያ እድልን ማመላከት"
          multiline
          {...register('marketing')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>

    <TextField
          id="standard-multiline-static"
          label="ተግባርና ውጤትን ማጋራት"
          multiline
          {...register('resultShare')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
    <TextField
          id="standard-multiline-static"
          label="ተግባርና ውጤትን ማስፋፋት"
          multiline
          {...register('actionResult')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
<TextField
          id="standard-multiline-static"
          label="ፉክክርና ትብብርን ማሳየት"
          {...register('competitionCoopration')}
          multiline
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <Button type='submit' variant='contained' startIcon={<Add/>}>መዝግብ</Button>
<hr />
    </Grid>
    </Grid> 
    </form>:""}

{form2?<form onSubmit={handleSubmit(submit1)}>
    <Grid container>

    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
    <Typography variant='h5' color="primary">ትምህርትና ስልጠና!</Typography>



    <TextField
          id="standard-multiline-static"
          label="3500 ዘመናትን ከፊት ከሃላ ማማተር"
          multiline
          rows={2}
          {...register('beforeAfter3500',{
            required:true
          })}
          variant="standard"
          style={{width:"100%"}}
          />




<TextField
          id="standard-multiline-static"
          label="መረጃ ማቀነባበር"
          multiline
          {...register('process',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />


<TextField
          id="standard-multiline-static"
          label="መረጃ መፍጠር"
          multiline
          {...register('create',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />


<TextField
          id="standard-multiline-static"
          label="መረጃ መከዘን"
          multiline
          {...register('store',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />


    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>



<TextField
          id="standard-multiline-static"
          label="አንድን ቀን እንደ ሶስት ቀን አስልቶ መስራት"
          multiline
          {...register('timeUtilization',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>



    <TextField
          id="standard-multiline-static"
          label="የፍክክርና ትብብር ሚዛናዊነት"
          multiline
          {...register('competitionCooperation',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />




<TextField
          id="standard-multiline-static"
          label="አንፃራዊ የማድረግ ዐቅም"
          multiline
          {...register('alternative')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />


<TextField
          id="standard-multiline-static"
          label="የተግባቦት ዘዴዎች አጠቃቀም"
          multiline
          {...register('communicationWay')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

    </Grid>
    <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Button type='submit' variant='contained' startIcon={<Add/>}>መዝግብ</Button>
      </Grid>

</Grid>
</form>:""}
{
      form3?
      <form onSubmit={handleSubmit(submit2)}>
      <Grid container>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
    <Typography variant='h5' color="primary">ሥራና ክሕሎት!</Typography>

    <TextField
          id="standard-multiline-static"
          label="በስራና ክፍያ ማህበራዊ ዋስትና መፍጠር"
          type='number'
          {...register('jobSalary',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
<TextField
          id="standard-multiline-static"
          label="በክህሎትና ደመዎዝ አስተዳደራዊና ምጣያዊ ዋስትና መፍጠር"
          multiline
          {...register('studyAndInvestigate',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="ፍላጎትን መፍጠር መደሰት"
          multiline
          {...register('need',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="ለብልፅግና መስራት"
          multiline
          {...register('prospherity',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
</Grid>
<Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>

<TextField
          id="standard-multiline-static"
          label="ለምሉዕነት ተመሳሳይ የፍላጎት መዳረሻ መፍጠር"
          multiline
          {...register('commonNeed',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="ለዕቅድ ጊዜን መመዝገብና መጠቀም"
          multiline
          {...register('schedule',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          id="standard-multiline-static"
          label="ለፊልም መተዋንና ለግብር መጠቀም"
          multiline
          {...register('acting',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />
          </Grid>
          <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>


<TextField
          id="standard-multiline-static"
          label="ለማነብ ማጥናትና መንደፍ"
          multiline
          {...register('studyAndInvestigate',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />


<TextField
          id="standard-multiline-static"
          label="ለፈጠራ ማጥናትና መመራመር"
          multiline
          {...register('innovation',{
            required:true
          })}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          />

    </Grid>
    <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
      <Button type='submit' variant='contained' color='primary' startIcon={<Add/>}>መዝግብ</Button>
    </Grid>
    </Grid></form>:""}
    </CardContent>
    <CardActions sx={{paddingLeft:20}}>        
    <Link to="/main/org/view" state={{id:orgId}}><Button color='secondary' style={{color:"white"}} size='large' startIcon={<ArrowBackIosNewRounded /> } variant="contained">መመለስ</Button></Link> 
             &nbsp;
    </CardActions>
    <br /><br />

</Card>

    )
}

export default Org8