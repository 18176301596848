import React from 'react'
import {Button, ButtonGroup, TextField} from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ResetHasab, createHasab } from '../../data/OrgDetail'
import { useEffect } from 'react'
import Loading from '../../components/Loading'
import { useState } from 'react'
const Org3 = () => {


  const {register,handleSubmit,formState:{errors}} = useForm();
  const {state} = useLocation();
  const orgId = state.id;
  const run = useDispatch();
  const OrgDetail = useSelector((state)=>state.orgDetail)
  const user = useSelector((state)=>state.logedUser)
  const [load,setload] = useState(false);
  const link = useNavigate()
  const [onPage,setOnpage] = useState(false)


  const submit = data =>{

    data['userId'] = user.id;
    data['orgId'] = orgId;
    let hasab = data;
    let token = user.user;
    run(createHasab({hasab:hasab,token:token}));
    setOnpage(true)
  
    console.log(data)
  
  }

  useEffect(()=>{

if(OrgDetail.isloading)
setload(true);
else if(OrgDetail.hasabStatus == "success" && onPage)
{
  setload(false);
  setOnpage(false)
  link('../view',{state:{id:state.id}});

}
else if(OrgDetail.hasabStatus == "failed")
{

}
else
setload(false)







  },[OrgDetail])


  return (

    <Card>
      <Loading load={load}/>
      <form onSubmit={handleSubmit(submit)}>
    <CardContent sx={{width:"100%"}}>
       
    <Grid container >
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
    <Typography variant='h6' color="primary">ሃሳብና ተቋማዊነት!</Typography>
    <Typography variant='body1' color="error">{errors.idea?.message}</Typography>
        <TextField
          id="standard-multiline-static"
          label="ዕሳቤ"
          multiline
          {...register("idea",{required:{
            value:true,
            message:"እሳቤ አስፈላጊ መረጃ ነው!"
          }})}
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          {...register("criticalEvent",{
            required:true
          })}
          id="standard-multiline-static"
          label="ወሳኝ ኩነቶች"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          {...register("challenge",{
            required:true
          })}
          id="standard-multiline-static"
          label="ተግዳሮቶች"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />
    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h6' color="primary">ተወዳዳሪነት!</Typography>

        <TextField
                    {...register("largeCompitative",{
                      required:true
                    })}
          id="standard-multiline-static"
          label="በሰፊው አድማስ ተወዳዳሪና ደንበኞች"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          {...register("smallCompitative",{
            required:true
          })}
          id="standard-multiline-static"
          label="በቅርብ አድማስ ተወዳዳሪና ደንበኞች"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />

<TextField
          {...register("attention",{
            required:true
          })}
          id="standard-multiline-static"
          label="ዘርፍና ትኩረት"
          multiline
          rows={4}
          variant="standard"
          style={{width:"100%"}}
          />
            {/* {...register("  idea:  criticalEvent:challenge: smallCompitative: largeCompitative:  attention:  responsibility:earlyPosition:  relatedScene")} */}

    </Grid>

    <Grid  item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:4}}>
    
    <Typography variant='h6' color="primary">ያሳለፉት መረጃና ሃላፊነት!</Typography>

    <TextField
  id="standard-multiline-static"
  label="ተግባርና የተሰላ ኃላፊነት"
  multiline
  rows={4}
  variant="standard"
  {...register("responsibility")}
  style={{width:"100%"}}
  />


<TextField
  id="standard-multiline-static"
  label="ቀደምት አቋም"
  multiline
  {...register("earlyPosition")}
  rows={4}
  variant="standard"
  style={{width:"100%"}}
  />


<TextField
  id="standard-multiline-static"
  label="የአውድ ውደት ተዛምዶ ትይንት"
  multiline
  {...register("relatedScene")}
  rows={4}
  variant="standard"
  style={{width:"100%"}}
  />



    </Grid>
    </Grid>
    </CardContent>
    <CardActions sx={{paddingLeft:20}}> 
    <Button size='large' type='submit' endIcon={<ArrowForwardIosRounded/> } variant="contained">ቀጣይ</Button>
    </CardActions>
    <br /><br />
    </form>
</Card>

    )
}

export default Org3