import React, { createContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Reg from './reg';
import Welcome from './userComponents/WelcomeComp';
import PrivateInfo from './userComponents/PrivateInfo';
import {store} from './data/store'
import {Provider} from 'react-redux'
import StartUp from './startUp';
const theme = createTheme({
  palette:{
    primary:{
      main:"#0099ff"
    },
    secondary:{
      main:"#fe9900"
    },
    white:{
      main:"#ffffff"
    },
    black1:{
      main:"#333"
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme} >
      <Provider store={store}>
    <StartUp/>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
