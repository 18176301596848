import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import user from "../assets/user.png"
import axios from "axios";
import api from "./api";
import { useNavigate } from "react-router-dom";
const baseURL = api.address

var initialState = {
    firstName:"",
    middleName:"",
    lastName:"",
    avatar:"",
    email:"",
    avatar:"",
    password:"",
    sex:"",
    role:"",
    dateOfBirth:"",
    phoneNumber:"",
    kebele:"",
    subCity:"",
    city:"",
    region:"",
    country:"",
    continent:"",
    religion:"",
    field:"",
    position:"",
    education:"",
    profession:"",
    images:user,
    maritalStatus:"",
    bank:"",
    bankAccountNumber:"",
    workExperience:"",
    achievement:"",
    skill:"",
    hobbies:"",
    weight:"",
    hight:"",
    language:"",
    bloodType:"",
    eyeColor:"",
    motherName:"",
    motherlastName:"",
    isloading:false,
    status:"",
    msg:""
}




if(localStorage.getItem("userData") != null)
{
    const data = JSON.parse(localStorage.getItem("userData"));
    initialState = data;
}
else 
{
//     if(JSON.parse(localStorage.getItem('logedUser')) != null)
// { 
    
//        const userId  =   JSON.parse(localStorage.getItem('logedUser')).id
//        initialState.isloading = true;
//     const response = async ()=> await axios.get(baseURL+'/addressapi/users/'+userId).then((resp)=>{
    
//         initialState = resp.data;
        
//             });
            
//             response().then(()=>{
//               initialState.isloading = false;

//             }).catch((error)=>{
//                 if(error)
//                 initialState.isloading = false;


//             })
// }
// else 
// {
//     initialState.isloading = false
// }

}



export const updateUser = createAsyncThunk('updateUser/user', async (data)=>{

  

})



export const fetchUser = createAsyncThunk('fetchUser/user', async (id)=>{
})

export const submitUser = createAsyncThunk('submitUser/user', async (user)=>{




})


export const userSlice = createSlice({
    name:"counter",
    initialState,
    reducers:{
        change:(state,action) => {
        },
        getuser:(state)=> {
        
        },
        incBy: (state,action)=>{
        },
    },
    extraReducers:(builders)=>{
        builders.addCase(fetchUser.pending,(state,action)=>{
            state.isloading=true
        })
        builders.addCase(fetchUser.fulfilled,(state,action)=>{
            state.isloading=false
            state.firstName = action.payload.data.firstName 
            state.middleName = action.payload.data.middleName 
            state.email = action.payload.data.email 
            state.sex = action.payload.sex 
        });
        builders.addCase(fetchUser.rejected,(state,action)=>{
            state.isloading=false
        });
        builders.addCase(submitUser.pending,(state,action)=>{
            state.isloading = true;
        });
        builders.addCase(submitUser.fulfilled,(state,action)=>{
            state.isloading = false;
            state.status ="success"
            console.log(action.payload.data)

        });
        builders.addCase(submitUser.rejected,(state,action)=>{
            state.isloading = false;
            state.status ="failed"
            console.log(action.payload)

        });
        builders.addCase(updateUser.pending,(state,action)=>{
            state.isloading = true;
        });
        builders.addCase(updateUser.fulfilled,(state,action)=>{
            state.isloading = false;
            state.status ="success"
            console.log(action.payload.data)
            state.msg = action.payload.data.message

        });
        builders.addCase(updateUser.rejected,(state,action)=>{
            state.isloading = false;
            state.status ="failed"
        });
    }
})



export const {change,getuser,incBy} = userSlice.actions

export default userSlice.reducer