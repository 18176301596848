import React from 'react'
import {Button, ButtonGroup, TextField} from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import {Grid, Box, Container, Typography, Card, CardContent, Avatar, CardActions } from '@mui/material'
import {FormControl,InputLabel,Input} from '@mui/material'
import camera from "../../assets/user.png"
import { Link } from 'react-router-dom'
import { UploadFile } from '@mui/icons-material'
const Org6 = () => {
  return (

    <Card>
    <CardContent sx={{width:"100%"}}>
       
    <Grid container >
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>
        <Typography variant='h6' color="primary">የካፒታል ምንጭና ዐቅም!</Typography>
        
        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ዝግ አካውንት</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ከባላ አክሲዮኖች የስራ ማስኬጃ ልዩ መዋጮ</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ከትምህርትና ስልጠና</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 

        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ከማበረታቻ ሽልማቶች</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 

    </Grid>
    <Grid  item xs={12} sm={12} md={4} lg={4} xl={4} sx={{padding:4}}>

    <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ከትምርት እና አገልግሎት ሽያጭ</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 


        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ከብድር</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 


        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ከአስተዳደራዊ መቀጮ</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 

    </Grid>

    <Grid  item xs={12} sm={12} md={3} lg={3} xl={3} sx={{padding:4}}>
    


    <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ከተጨማሪ እጣ ሽያጭ</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 


        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ባለ አክሲዮኖች ሲሽሻጡ ክሚቆረጥ</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 



        <FormControl style={{width:"100%"}} variant="standard">
            <InputLabel htmlFor="component-simple">ክጵሮጅክት ሽያጭ</InputLabel>
            <Input type='number' id="component-simple"  />
        </FormControl> 





    </Grid>
    </Grid>
    </CardContent>
    <CardActions sx={{paddingLeft:20}}>        
    <Link to="/main/org/5"><Button size='large' startIcon={<ArrowBackIosNewRounded /> } variant="contained">ቀጣይ</Button></Link> 
             &nbsp;
                 <Link to="/main/org/7"><Button size='large' endIcon={<ArrowForwardIosRounded/> } variant="contained">ቀጣይ</Button></Link> 

    </CardActions>
    <br /><br />
</Card>

    )
}

export default Org6