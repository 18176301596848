import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Input, Typography } from '@mui/material';
import userpng from '../assets/user.png';
import { Upload } from '@mui/icons-material';
import { useRef } from 'react';
import Loading from '../components/Loading';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import api from '../data/api';
const baseURL = api.address
 const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  
const UploadAvt = (props) => {
  const logedUser = useSelector((state)=>state.logedUser)
  const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
  const userImage = useSelector((state)=>state.user.images)
  const user = useSelector((state)=>state.user);
  const [option,setoption] = props.value;
    const [show,setshow] = useState(true);
    const [load,setload] = useState(false);
    const run = useDispatch();
    const file = useRef();
    const form = useRef();
    const main = useRef();
    const [showAv,setAv] = props.av;
    useEffect(()=>{

      if(userImage == "/static/media/user.1d39e68be5f17048df87.png")
      setshow(true)
      else 
      setshow(false) 
     },[])
     
    const handleSubmit = (e) => {

   
        const call = async ()=>{
        
            let formData = new FormData();
            formData.append('profilePic',e.target.files[0])
            formData.append('userId',logedUser.id)
            setload(true);
            const response = await axios.post(`${baseURL}/addressapi/files/uploadfile`,formData,{
              headers:{
                Authorization:`Bearer ${logedUser.user}`
              }
            }).then((resp)=>{
                    
              
                setload(false)
                const reader = new FileReader();
                reader.readAsDataURL(file.current.files[0]);
                reader.addEventListener("load", () => {
                
                    setAv(reader.result);
                    setshow(false);
                    setoption(false);

            });
            }).catch((err)=>{
                console.log(err)
                if(err)
                {
                  alert("Error in connection")
                  setload(false)

                  //window.location.reload();

                }
        
            })
        
        return response;
        }
            call();
            
      
          }
    
  return (
<>
<Loading load={load}/>
    <Dialog
    open={show || option}
    TransitionComponent={Transition}
    keepMounted
    aria-describedby="alert-dialog-slide-description"
    >
       <div style={{background: '#0099ff',color: 'white',padding: '12px',display:"flex",justifyContent:"space-between"}}>
        የግል ምስል
<ClearIcon onClick={e=>{setshow(false);setoption(false)}}/>
        </div> 
        <div id='main' ref={main}>
    <DialogTitle style={{padding:"1rem",paddingBottom:10}}>
        <Box component="img" sx={{width: '160px',height: '160px',margin: 'auto',display: 'block'}} src={userpng}/>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        የ እርሶን ምስል እሚያሳይ ምስል ዎይም  ከእርስ መረጃ ጋር የሚያያዝ ምስል ያስገቡ?
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{justifyContent:"center"}}>
            <Button onClick={e=>{file.current.click()}} color='primary'  startIcon={<Upload/>} variant='outlined'>ማስገባት</Button>
      <br />
    </DialogActions>
    </div>
    <form ref={form} encType='multipart/form-data'>
    <input onChange={handleSubmit} style={{display:"none"}} ref={file} type='file' />
    </form>
  </Dialog>
  </>
    )
}

export default UploadAvt