import { Button,Badge, Box, Card, Grid, Typography, ButtonGroup, CardActions, CardContent, Divider, Container, CardHeader, Input, TextField, LinearProgress, CircularProgress, Switch, FormControl, FormControlLabel, FormLabel, InputLabel, Select, MenuItem, Skeleton, IconButton, Snackbar, Paper } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import img from '../assets/usr.png'
import { Add, AlternateEmail, CameraAltSharp, CameraRear, Create, Download, Edit, LocalActivity, LocationOn, Lock, Person, Subject, Upcoming, Update, Upload, UploadFile } from '@mui/icons-material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {motion} from 'framer-motion'
import style from '../styles';
import { useSelector, useDispatch } from 'react-redux';
import Snack from '../components/SnackBar';
import { fetchUserInfo, setJobskill, setTef } from '../data/userInfo';
import Loading from '../components/Loading';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import api from '../data/api';
import { useForm } from 'react-hook-form';
import GoogleMapReact from 'google-map-react';
import { UserInfo } from './UserInfo';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import Eplus_word from '../Eplus_word';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Profile = (props) => {

  const [userAv,setUserAv] = useState(null)
  const baseURL = api.address;
  const [tab, settab] = React.useState(6);
  const [active,setactive] = props.states
  const lol = useSelector((state)=>state.userInfo.user)
  const [userInfo,setuserInfo] = useState(lol)
  const userS = useSelector((state)=>state.userInfo)
  const [editView,setEv]  = useState(true)
  const [snack,setsnack]= useState({Type:"info",msg:"everything is fine!",show:false});
  const run = useDispatch()
  const [load,setload] = useState(true)
  const user = useSelector((state)=>state.logedUser)
  const [open, setOpen] = React.useState(false);
  const avater = useRef();
  const { handleSubmit, formState:{ errors} } = useForm()
  const [showform,setswic] = useState(true);
  const [teftef,setteftef] = useState()
  const link = useNavigate();
  const [center,setCenter] = useState({lat:11.594502,lng:37.388324})
  const [trig,setTrig] = useState(false)
  const [avaterId,setAvaterId] = useState(null)
  useEffect(()=>{

    setuserInfo(lol)
    // (async()=>{

    //   await axios.get(`${api.imgAddress}/${userInfo/avater}`)
    //   .then(resp=>{
    //     console.log(resp.data)
    //   })
    // })()

    
  },[lol])
  useEffect(()=>{

    (async()=>{

      let token = user.user
      console.log(token)
      await axios.get(`${baseURL}/addressapi/images/fetch-image?userId=${user.id}`,{ 
        headers: {
        'Authorization': `Bearer ${token}`
      }}
  )
      .then((result) => {
        console.info("ImageResult",result.data)
        setAvaterId(result?.data.id)
        setUserAv(`${api.imgAddress}/${result.data.imagePath}`)
      }).catch((err) => {
        console.debug(err)
      });
    })()

  },[trig])

  const SubmitJobSkill= data => {

    const token = user; 
    const update = data;
    update['orgId'] = user.id;

    
    
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = (tab) => {
    settab(tab);
  };


  const SubmitTef = data =>{


    let token = user.user;
    let update = data;
    run(setTef({update:update,token:token}));
    window.location.reload();

  }



const UploadAvt =async (e)=>{
  let token = user.user
  let formData = new FormData();
  let imageFile = e.target.files[0];
  formData.append('profilePic',imageFile)
  formData.append('userId',userInfo.id)
  const tokenHeader = {headers:{ 'Authorization': `Bearer ${token}`}}
  setload(true)
  let action =  await axios.put(`${baseURL}/addressapi/files/${avaterId}`,formData,tokenHeader)
    .then(async (resp)=>{
              
                    console.log(resp)
                    setTrig(!trig)
                    setload(false)
                    setOpen(false)
                    return resp;
                    
                      })
    .catch((err)=>{
            
                    console.log(err)
                    setload(false)
                    return err;
        
              })


  console.log(action)

  

  
}

  useEffect(()=>{


    console.log(userInfo)
      if(userS.isloading)
      setload(true)
      else 
      {
        setload(false)
        
      }


  },[])


  useEffect(()=>{


    (async()=>{
      await axios.get(`${baseURL}/addressapi/images/${userInfo.id}`)
      .then((result) => {
        setUserAv(`${api.imgAddress}/${result.data.imagePath}`)
      }).catch((err) => {
        console.debug(err)
      });
    })()


  },[userInfo])


  useEffect(()=>{

    if(userS.user.Tef_Wofs != undefined && userS.user.Tef_Wofs.length != 0)
    {
      setswic(false)
      setteftef(userS.user.Tef_Wofs[0])

    }

  },[userS])


  const update = e => {

    setuserInfo({...userInfo,[e.target.name]:e.target.value})
//    userInfo[e.target.name] = e.target.value


}


  const SendUpdate = async (e)=>{

    
setload(true)
let token = user.user;
await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{[e.target.name]:e.target.value},{
  headers: {
      'Authorization': `Bearer ${token}`
    }   
})
.then((resp)=>{

  setload(false)
  setsnack({...snack,msg:resp.data.message,show:true,pos:'bottom',por:'left'})

})
.catch((err)=>{
  
  setsnack({Type:"error",msg:"የኮኔክሽን ችግር አለ እንደገና ይሞከሩ",show:true})
  
  setload(false)

})
    
  }




  const DownloadInfo =  async ()=> {
   

    link('/download',{state:{user:userInfo}})




  }

  return (
    <Box sx={{display:active ==1?"block":"none"}}>
      <Loading load={load}/>
      <Snack snack={snack}/>
    <Grid container>



    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"ምስልዎን ለማስተካከል"}</DialogTitle>
        <DialogContent sx={{padding:12,display: 'flex',flexDirection: 'column'}}>
          <input ref={avater} style={{display:"none"}} onChange={e=>UploadAvt(e)} type="file" name="" id="" />
        <Box sx={{width: '90px',height:'90px',margin: '17px'}} component="img" src={userAv}/>
          <Button variant='outlined' onClick={e=>avater.current.click()} startIcon={<UploadFile/>}>አስግባ</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>አቋርጥ</Button>
        </DialogActions>
      </Dialog>



      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CardContent sx={{display:"flex",boxShadow:"none",width:"98%",paddingRight:0}}>
          {userInfo.avatar != undefined? <Skeleton variant="circular" width={90} height={90} /> : <Box sx={{width: '90px',height:'90px',margin: '17px',width: '90px',border: 'solid 2px',borderRadius: '50%'}} component="img" src={userAv}/>}
       
          <Button onClick={e=>setOpen(true)} style={{position: 'relative',right: '60px',top: '30px',color: 'black'}} color='secondary'><CameraAltSharp /></Button>
          <Box sx={{width:'15rem'}} component="div">
          <Typography color="#646464" sx={{paddingTop:"22px"}} variant='h5'>{userInfo.firstName == undefined? <Skeleton  variant="rectangular" width={210} height={20} /> : userInfo.firstName+" "+userInfo.middleName+" "+userInfo.lastName}</Typography>
          <Typography color="secondary" variant='h6'>ንቁ ቁጥር፡ {userInfo.userCode == undefined? <Skeleton animation="wave" />: userInfo.userCode}</Typography>
          </Box>
          <Box sx={{paddingTop: '22px',display:{xs:"none",sm:"none",md:"block",lg:"block",xl:"block"}}}>
          <Typography color="#646464"  variant='body1'><AlternateEmail sx={{position: 'relative',top: '8px',paddingRight: '9px'}}/>ኢሜል፡ {userInfo.email == undefined ?<Skeleton animation="wave" />:userInfo.email}</Typography>
          <Typography color="#646464"  variant='body1'><LocationOn sx={{position: 'relative',top: '8px',paddingRight: '9px'}}/>አድራሻ፡ {userInfo.city == undefined?<Skeleton animation="wave" />: userInfo.city}</Typography>          
          </Box>
          <Box sx={{paddingLeft: '7rem',paddingTop: '32px',display:{xs:"none",sm:"none",md:"block",lg:"block",xl:"block"}}}>
            <ButtonGroup variant='contained'>
            <Button startIcon={<Edit/>}  sx={{paddingLeft:"12px"}} onClick={e=>setEv(!editView)}>ማደስ</Button>          
            <Button  startIcon={<Download/>} onClick={e=>DownloadInfo()} size='large' style={{color:"white"}} color='secondary' sx={{paddingLeft:"12px"}}>ማስቀመጥ</Button>          
  </ButtonGroup>
          </Box>

          </CardContent>
          <Box sx={{paddingLeft: '5rem',paddingTop: '12px',display:{xs:"block",sm:"block",md:"none",lg:"none",xl:"none"}}}>
            <ButtonGroup variant='contained'>
            <Button startIcon={<Edit/>}  sx={{paddingLeft:"12px"}} onClick={e=>setEv(!editView)} >ማስቀመጥ</Button>          
            <Button  startIcon={<Download/>} onClick={e=>DownloadInfo()} size='large' style={{color:"white"}} color='secondary' sx={{paddingLeft:"12px"}}>ለማጠናቀቅ</Button>          
  </ButtonGroup>
          </Box>

          <Container>
            <br />
            <Typography>የማጠናቀቅ ደረጃ፡ {Object.keys(userInfo).length}%</Typography> 

            <FormControlLabel control={<Switch   defaultChecked onChange={e=>setEv(!editView)} checked={!editView}  />}  label="ማየት/ ማስተካከል" />

 
          </Container>
          <Divider/>
          <CardActions sx={{bgcolor:"#f3f3f3"}}>
<Box sx={{ width: '100%' }}>
      <Tabs
        value={tab}
        aria-label="Tabs where each tab needs to be selected manually"
        sx={{display:{
          xs:"none",
          sm:"none",
          md:"block",
          lg:"block",
          xl:"block",
          
        }}}
        >
        <Tab onClick={()=>settab(0)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>የግል መረጃ</Typography>} />
        <Tab onClick={()=>settab(1)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>አድራሻ</Typography>}  />
        <Tab onClick={()=>settab(2)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>ልዩ መረጃ</Typography>}/>
        <Tab onClick={()=>settab(3)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>ጤና ተኮር መረጃ</Typography>}/>
        <Tab onClick={()=>settab(5)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>ተጨማሪ መረጃ</Typography>}/>
        <Tab onClick={()=>settab(6)} label={<Typography sx={{fontSize: '14px',fontWeight: '900'}}>ኣዲስ መረጃ</Typography>}/>


      </Tabs>
      <FormControl sx={{ m: 1, minWidth: 120 ,display:{
          xs:"block",
          sm:"block",
          md:"none",
          lg:"none",
          xl:"none",
          
        } }} size="small">
      <InputLabel id="demo-select-small-label">ይምረጡ</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={tab}
        label="ይምረጡ"
        onChange={e=>settab(e.target.value)}
      >
        <MenuItem value={6}>ኣዲስ መረጃ</MenuItem>        
        <MenuItem value={0}>የግል መረጃ</MenuItem>
        <MenuItem value={1}>አድራሻ</MenuItem>
        <MenuItem value={2}>ልዩ መረጃ</MenuItem>
        <MenuItem value={3}>ጤና ተኮር መረጃ</MenuItem>
        <MenuItem value={4}>ተእፍ ተእፍ / ወፍፍ ወፍፍ</MenuItem>
        <MenuItem value={5}>ተጨማሪ መረጃ</MenuItem>
      </Select>
    </FormControl>


    </Box>
          </CardActions>
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
      </Grid>
    </Grid>
    <Box sx={{display:tab == 6?"block":"none"}}>
    <Eplus_word/> 
    </Box>
    <Container sx={{display:tab == 0?"block":"none"}}>
                                                                                                                                  {/* የግል መረጃ */}
      <Box component={motion.div} initial={{opacity:0}} animate={{opacity:1}} >
       <br />
        <Typography variant='h4' color='secondary' >የግል መረጃ</Typography>
        <br /> 
        <Grid container>
        
        
        
        <Grid  item xl={3} lg={3} md={3} sm={3}  xs={3}>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ስም</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.firstName}</Typography>
        :<>
        <Input onBlur={SendUpdate} onChange={e=>update(e)} name='firstName' value={userInfo.firstName} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch name='firstName'
          checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['firstName']}
          onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            console.log({...JSON.parse(userInfo.privateData),[name]:val})
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>}
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የአባት ስም</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.middleName}</Typography>
        :<><Input onBlur={SendUpdate} onChange={e=>update(e)} value={userInfo.middleName}  name='middleName' />
                <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['middleName']} name='middleName' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>

        </>
        }
        
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የአያት ስም</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.lastName}</Typography>
        :<><Input onBlur={SendUpdate} onChange={e=>update(e)} value={userInfo.lastName}  name='lastName' />
                <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch name='lastName'
          checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['lastName']}
          onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            console.log({...JSON.parse(userInfo.privateData),[name]:val})
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
        }
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የእናት ስም</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.motherName}</Typography>
        :<>
        <Input onBlur={SendUpdate} onChange={e=>update(e)} name='motherName'  value={userInfo.motherName} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['motherName']} name='motherName' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>

        </>}
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የእናት አባት ስም</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.motherlastName}</Typography>
        :<><Input onBlur={SendUpdate} onChange={e=>update(e)}  name='motherlastName'  value={userInfo.motherlastName} />
         <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['motherlastName']} name='motherlastName' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>

        </>
  }
  </Box>
        
        </Grid>
        
        
        <Grid  item xl={3} lg={3} md={3} sm={3}  xs={3}>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ስልክ ቁጥር</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.phoneNumber}</Typography>
        :<><Input onBlur={SendUpdate} onChange={e=>update(e)} name='phoneNumber'  value={userInfo.phoneNumber} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['phoneNumber']} name='phoneNumber' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
}</Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ኢሜል</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.email}</Typography>
        :<><Input name='email' onBlur={SendUpdate} onChange={e=>update(e)}  value={userInfo.email} />
                <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['email']} name='email' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>

        </>
        }
        </Box>        
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12}  xs={12}>

        </Grid>
        </Grid>
      </Box>
  </Container>
  <Container sx={{display:tab == 1?"block":"none"}}>
                                                                                                                                      {/* አድራሻ */}
  <Box component={motion.div} initial={{opacity:0}} animate={{opacity:1}} >
       <br />
        <Typography variant='h4' color='secondary' >አድራሻ</Typography>
        <br /> 
        <Grid container style={{height:"70vh"}}>
        
        
        
        <Grid  item xl={4} lg={4} md={4} sm={4}  xs={4}>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ሀገር</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.country}</Typography>
        :<><Input  onBlur={SendUpdate}  name='country' value={userInfo.country} onChange={e=>update(e)}  />
          <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['country']} name='country' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>}
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ክልል</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.region}</Typography>
        :<>
        <Input  onBlur={SendUpdate}  name='region'  value={userInfo.region}  onChange={e=>update(e)} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['region']} name='region' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>}
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ከተማ/ዞን</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.city}</Typography>
        :<><Input  onBlur={SendUpdate}  name='city'  value={userInfo.city} onChange={e=>update(e)}  />
         <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['city']} name='city' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>}
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ክፍለ ከተማ ወረዳ</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.subCity}</Typography>
        :<><Input  onBlur={SendUpdate}  name='subCity'  value={userInfo.subCity}  onChange={e=>update(e)} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['subCity']} name='subCity' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
        }
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ቀበሌ</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.kebele}</Typography>
        :<><Input  onBlur={SendUpdate}  name='kebele' value={userInfo.kebele}  onChange={e=>update(e)} />
         <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['kebele']} name='kebele' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
        }
        </Box>
        
        </Grid>
        
        
        <Grid  item xl={8} lg={8} md={8} sm={8}  xs={8}>

        <Box style={{width:'89%',height:"100%"}}>
          
        <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo" }}
        center={center}
        defaultZoom={16}
        onGoogleApiLoaded={({map,maps})=>{

          map.setMapTypeId(maps.MapTypeId.HYBRID);
        }}
        >

          </GoogleMapReact>
        

          </Box>

        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>
          
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>

          

        </Grid>
        </Grid>
      </Box>
  </Container>
  <Container sx={{display:tab == 2?"block":"none"}}>
                                                                                                                                      {/* ልዩ መረጃ */}
  <Box component={motion.div} initial={{opacity:0}} animate={{opacity:1}} >
       <br />
        <Typography variant='h4' color='secondary' >ልዩ መረጃ</Typography>
        <br /> 
        <Grid container>
        
        
        
        <Grid  item xl={4} lg={4} md={4} sm={4}  xs={4}>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ህይማኖት</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.religion}</Typography>
        :<>
        <Input  onBlur={SendUpdate}   name='religion' value={userInfo.religion}  onChange={e=>update(e)} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['religion']} name='religion' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
}</Box>
<Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የሚችሉት ቋንቋ</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.language}</Typography>
        :<><Input  onBlur={SendUpdate}  name='language'  value={userInfo.language}  onChange={e=>update(e)} />
          <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['language']} name='language' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
        }
        </Box>

<Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የጋብቻ ሁኔታ</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.maritalStatus}</Typography>
        :<><Input  onBlur={SendUpdate}  name='maritalStatus'  value={userInfo.maritalStatus}  onChange={e=>update(e)} />
                  <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['maritalStatus']} name='maritalStatus' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
}
        
          
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ትምህርት</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.education}</Typography>
        :<><Input  onBlur={SendUpdate}  name='education'  value={userInfo.education}  onChange={e=>update(e)} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['education']} name='education' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
}
        
          
        </Box>
        
        </Grid>
        
        
        <Grid  item xl={6} lg={6} md={6} sm={6}  xs={6}>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የልደት ቀን</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.dateOfBirth}</Typography>
        :<><Input  onBlur={SendUpdate} type='date'  name='dateOfBirth' value={userInfo.dateOfBirth}  onChange={e=>update(e)} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['dateOfBirth']} name='dateOfBirth' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
        }
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ደንበኛ ባንክ</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.bank}</Typography>
        :<><Input  name='bank'  onBlur={SendUpdate} value={userInfo.bank} onChange={e=>update(e)}  />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['bank']} name='bank' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
        }
        </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>

        
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>
        </Grid>
        </Grid>
      </Box>
  
  </Container>
  <Container sx={{display:tab == 3?"block":"none"}}>
  <Box component={motion.div} initial={{opacity:0}} animate={{opacity:1}} >
       <br />
        <Typography variant='h4' color='secondary' >ጤና ተኮር መረጃ</Typography>
        <br /> 
        <Grid container>
        <Grid  item xl={4} lg={4} md={4} sm={4}  xs={4}>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ክብደት</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.weight}</Typography>
        :<><Input  onBlur={SendUpdate} name='weight'  value={userInfo.weight} onChange={e=>update(e)}  />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['weight']} name='weight' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
        }
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ቁመት</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.hight}</Typography>
        :<><Input  onBlur={SendUpdate} name='hight'  value={userInfo.hight}  onChange={e=>update(e)}  />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['hight']} name='hight' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>}
        </Box>
        </Grid>
        
        
        <Grid  item xl={6} lg={6} md={6} sm={6}  xs={6}>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የደም አይነት</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.bloodType}</Typography>
        :<><Input  onBlur={SendUpdate} name='bloodType' value={userInfo.bloodType}  onChange={e=>update(e)} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['bloodType']} name='bloodType' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>
        }
        </Box>
        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የአይን ቀለም</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.eyeColor}</Typography>
        :<><Input  onBlur={SendUpdate} name='eyeColor' value={userInfo.eyeColor}  onChange={e=>update(e)} />
        <div style={{
          borderRadius: '7px',
          border: '1px solid rgb(0, 153, 255)',
          padding: '3px',
          margin: '5px'
        }}>
        <FormControlLabel
          value="start"
          control={<Switch checked={userInfo.privateData == undefined ?false:JSON.parse(userInfo?.privateData)['eyeColor']} name='eyeColor' onChange={async e=>{
            
            let val = e.target.checked;
            let name = e.target.name;
            setuserInfo({...userInfo,privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})})
            setload(true)
            let token = user.user;
            await axios.put(`${baseURL}/addressapi/users/${userInfo.id}`,{privateData:JSON.stringify({...JSON.parse(userInfo.privateData),[name]:val})},{
              headers: {
                  'Authorization': `Bearer ${token}`
                }   
            })
            .then((result) => {
              setload(false)
              console.log(result)
            }).catch((err) => {
              console.log(err)
              setload(false)
            });
          }} color="primary" />}
          label={<Lock/>}
          labelPlacement="start"
        /><span>&nbsp; መረጃ መቆለፍ </span>
        </div>
        </>}
        </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>

        
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>
        </Grid>
        </Grid>
      </Box>
  
  </Container>
  
  {/* <Container sx={{display:tab == 4?"block":"none"}}>
    <form onSubmit={handleSubmit(SubmitJobSkill)}>
  <Box component={motion.div} initial={{opacity:0}} animate={{opacity:1}} >
       <br />
       <Typography variant='h4' color='secondary' >የስራ ልምድ</Typography> 
        <br /> 
        <Typography variant='body1' color='error' >{ errors.skill?.message}</Typography>
       
        <Grid container>
        <Grid  sx={{padding:3}} item xl={4} lg={4} md={4} sm={4}  xs={4}>
        <TextField
          id="standard-multiline-static"
          label="ልምድ"
          multiline
          {...register("skill",{required:{
            value:true,
            message:"ልምድ አስፈላጊ ነው!"
          }})}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 
          <TextField
            id="standard-multiline-static"
            label="ተፈጥሮ"
            multiline
            {...register('nature')}
            rows={2}
            variant="standard"
            style={{width:"100%"}}
            /> 
  
           
        <TextField
          id="standard-multiline-static"
          label="ፍላጎት"
          {...register('need')}
          multiline
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 

<TextField
          id="standard-multiline-static"
          label="ብልፅና"
          multiline
          {...register('phrospherity')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 
<TextField
          id="standard-multiline-static"
          label="ለፈጠራ ማጥናትና መመርመር"
          multiline
          {...register('studyDesign')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 
<TextField
          id="standard-multiline-static"
          label="ደመዎዝ"
          multiline
          {...register('jobSalary')}
          type='number'
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 

            
        </Grid>
        
                <Grid sx={{padding:3}} item xl={4} lg={4} md={4} sm={4}  xs={4}>
                <TextField
          id="standard-multiline-static"
          label="የጋራ ፍላጎት"
          multiline
          rows={2}
          {...register('commonNeed')}
          variant="standard"
          style={{width:"100%"}}
          /> 
                <TextField
          id="standard-multiline-static"
          label="ለቅድ ጊዜን መመዝገብና መጠቀም"
          multiline
          {...register('schedule')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 
          <TextField
          id="standard-multiline-static"
          label="ፈጠራ"
          multiline
          {...register('innovation')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 
          <TextField
          id="standard-multiline-static"
          label="ለፊልም መተዎን እና ለግብር መጠቀም"
          multiline
          {...register('acting')}
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 
          <TextField
          id="standard-multiline-static"
          label="ለማነብ ማጥናትና መንደፍ"
          multiline
          rows={2}
          variant="standard"
          style={{width:"100%"}}
          /> 

        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>
        </Grid>
        </Grid>


<Button variant='contained' type='submit' startIcon={<Add/>}>አስገባ</Button>




      </Box>
      </form>
  
  </Container>
           */}











                      <form  onSubmit={handleSubmit(SubmitTef)}>
                      <div sx={{display:tab == 1010?"block":"none"}}>
</div>

                      {/* <Container sx={{display:tab == 4?"block":"none"}}>
                      {showform?
                      <Box component={motion.div} initial={{opacity:0}} animate={{opacity:1}} >
                          <br />
                          <Typography variant='h4' color='secondary' >ተእፍ ተእፍ / ወፍፍ ወፍፍ  </Typography>
                            <br /> 
                            <Typography variant='body1' color='error' >{errors.vision?.message}</Typography>
                            <Typography variant='body1' color='error' >{errors.mission?.message}</Typography>
                            <br /> 
                            <Grid container>
                            <Grid  item xl={8} lg={8} md={8} sm={8}  xs={8}>

                            <TextField
                              id="standard-multiline-static"
                              label="ተስፋ"
                              multiline
                              {...register("hope",{
                                required:true
                              })}
                              placeholder='ተስፋ'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              /> 
                            <TextField
                              id="standard-multiline-static"
                              label="እምነት"
                              multiline
                              {...register("belief",{
                                required:true
                              })}
                              placeholder='እምነት'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              /> 
                            <TextField
                              id="standard-multiline-static"
                              label="ፍቅር"
                              multiline
                              {...register("love",{
                                required:true
                              })}
                              placeholder='ፍቅር'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              /> 
                            <TextField
                              id="standard-multiline-static"
                              label="ራዕይ"
                              multiline
                              {...register("vision",{required:{
                                value:true,
                                message:"ራዕይ አስፈላጊ ነው!"
                              }})}
                              placeholder='ራዕይ'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              /> 
                            <TextField
                              id="standard-multiline-static"
                              label="ተልዕኮ"
                              multiline
                              {...register("mission",{
                                required:{
                                  value:true,
                                  message:"ተልዕኮ አስፈላጊ ነው!"
                                }
                              })}
                              placeholder='ተልዕኮ'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              /> 
                            <TextField
                              id="standard-multiline-static"
                              label="መሪ ድምፅ"
                              multiline
                              {...register("motto",{
                                required:true
                              })}
                              placeholder='መሪ ድምፅ'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              />         
                            <TextField
                              id="standard-multiline-static"
                              label="ዋና አላማ"
                              multiline
                              {...register("specificObjective",{
                                required:true
                              })}
                              placeholder='ዋና አላማ'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              />         
                            <TextField
                              id="standard-multiline-static"
                              label="ዝርዝር አላማ"
                              multiline
                              {...register("mainObjective",{
                                required:true
                              })}
                              placeholder='ዝርዝር አላማ'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              />         
                              <TextField
                              id="standard-multiline-static"
                              label="ወጪ"
                              multiline
                              {...register("cost",{
                                required:true
                              })}
                              placeholder='ወጪ'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              />         
                              <TextField
                              id="standard-multiline-static"
                              label="ፍጆታ"
                              multiline
                              {...register("consumption",{
                                required:true
                              })}
                              placeholder='ፍጆታ'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              /> 
                              <TextField
                              id="standard-multiline-static"
                              label="እሴት"
                              multiline
                              {...register("value",{
                                required:true
                              })}
                              placeholder='እሴት'
                              rows={3}
                              variant="standard"
                              style={{width:"100%"}}
                              /> 
                              <br /><br /><br /><br />
                            <Button type='submit' variant='contained' startIcon={<Create/>}>አስገባ</Button>
                            <br /><br /><br /><br />
                            <br /><br /><br /><br />
                            <br /><br /><br /><br />

                            </Grid>
               
             
                                    <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>
                
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>
                            </Grid>
                            </Grid>
                          </Box>:
                          <Box>


             
<Typography gutterBottom variant="h5" component="div">
         ተስፋ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.hope}
        </Typography>
             

<Typography gutterBottom variant="h5" component="div">
         እምነት
        </Typography>
        <Typography variant="body2" color="text.secondary">
                              {teftef.belief}
        </Typography>
             
             
             
                       
        <Typography gutterBottom variant="h5" component="div">
         ፍቅር
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.love}
        </Typography>
             
             
             
        <Typography gutterBottom variant="h5" component="div">
         ርዕይ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.vision}
        </Typography>
             
             
             
             
             
        <Typography gutterBottom variant="h5" component="div">
         ተልዕኮ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.mission}
        </Typography>
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        ዋና አላማ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.mainObjective}
        </Typography>
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        ዝርዝር አላማ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.specificObjective}
        </Typography>
             
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        ግብ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.goal}
        </Typography>
             
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        ወጪ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.cost}
        </Typography>
             
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        ፍጆታ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.consumption}
        </Typography>
             
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        እሴቶች
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.value}
        </Typography>
             
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        መርሆች
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.principle}
        </Typography>
             
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        መሪ ድምፅ
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.voice}
        </Typography>
             
             
             
             
             
             
             
        <Typography gutterBottom variant="h5" component="div">
        መሪ ቃል
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {teftef.motto}

        </Typography>
             
             
             
             
             
             
             
             
             
             
             
             
                          </Box>
                          }
                      
                      </Container> */}
                      </form>


  <Container sx={{display:tab == 5?"block":"none"}}>
  <Box component={motion.div} initial={{opacity:0}} animate={{opacity:1}} >
       <br />
        <Typography variant='h4' color='secondary' >ተጨማሪ መረጃ</Typography>
        <br /> 
        <Grid container>
        <Grid  item xl={8} lg={8} md={8} sm={8}  xs={8}>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ችሎታ</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.skill}</Typography>
        :<TextField
        style={{width:'100%'}}
        onChange={e=>update(e)} 
          id="outlined-multiline-flexible"
          multiline
          onBlur={SendUpdate}
          value={userInfo.skill}
          name='skill' 
          placeholder=''

        />}
        </Box>

        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የትርፍ ጊዜ ማሳለፊያዎች</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.hobbies}</Typography>
        :<TextField
        style={{width:'100%'}}
        onChange={e=>update(e)} 
          id="outlined-multiline-flexible"
          multiline
          onBlur={SendUpdate}
          value={userInfo.hobbies}
          name='hobbies' 
          placeholder=''

        />}
        </Box>


        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>ስኬት</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.achievement}</Typography>
        :<TextField
        style={{width:'100%'}}
        onChange={e=>update(e)} 
          id="outlined-multiline-flexible"
          multiline
          onBlur={SendUpdate}
          value={userInfo.achievement}
          name='achievement' 
          placeholder=''

        />}
        </Box>


        <Box sx={{padding:1}}>
        <Typography variant='h6' color={style.colors.high1}>የሥራ ልምድ</Typography>
        {editView?<Typography color={style.colors.high2} variant='subtitle2'>{userInfo.workExperience}</Typography>
        :<TextField
        style={{width:'100%'}}
        onChange={e=>update(e)} 
          id="outlined-multiline-flexible"
          multiline
          onBlur={SendUpdate}
          value={userInfo.workExperience}
          name='workExperience' 
          placeholder=''

        />}
        </Box>

        

        </Grid>
        
                <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>
                <br /><br /><br /><br />
        
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4}  xs={4}>
        </Grid>
        </Grid>
      </Box>
  
  </Container>
  <br/><br/><br/><br/><br/><br/><br/>
    </Box>
  )
}





const TabPanel = (props) =>{



  return(<>
{props.tab}  
  
  </>)
}

export default Profile