// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-Card-styles-module__card--9xEbS{position:relative;max-width:100%;width:250px;min-width:250px;min-height:350px;display:flex;flex-flow:column;background-color:#ecf0f1;border-radius:.25rem;margin:1rem;user-select:none;transition:.25s}.src-Card-styles-module__card--9xEbS.src-Card-styles-module__float--uXoM\\+:hover{transform:translateY(-8px);box-shadow:0 10px 10px -10px #7f8c8d}", "",{"version":3,"sources":["webpack://./src/Card/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,iBAAA,CACA,cAAA,CACA,WAAA,CACA,eAAA,CACA,gBAAA,CACA,YAAA,CACA,gBAAA,CACA,wBAAA,CACA,oBAAA,CACA,WAAA,CACA,gBAAA,CACA,eAAA,CAEA,iFACE,0BAAA,CACA,oCAAA","sourcesContent":[".card {\n  position: relative;\n  max-width: 100%;\n  width: 250px;\n  min-width: 250px;\n  min-height: 350px;\n  display: flex;\n  flex-flow: column;\n  background-color: #ecf0f1;\n  border-radius: 0.25rem;\n  margin: 1rem;\n  user-select: none;\n  transition: 0.25s;\n  \n  &.float:hover {\n    transform: translateY(-8px);\n    box-shadow: 0 10px 10px -10px rgba(#7f8c8d, 1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "src-Card-styles-module__card--9xEbS",
	"float": "src-Card-styles-module__float--uXoM+"
};
export default ___CSS_LOADER_EXPORT___;
